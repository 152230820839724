import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { IBoardDto, IUpdateBoardDto } from './models/IBoardDto';
import { useBoardQuery, useUpdateBoardMutation } from './queries/boardQueries';
import { BoardEditForm, useBoardEditForm } from './BoardEditForm';

interface IProps {
  projectId: string;
  boardId: string;
  onOpenChange: (open: boolean) => void;
  onSuccess?: (projectId: string) => void;
}

export default function BoardEdit({ projectId, boardId, onOpenChange, onSuccess }: IProps) {
  const board = useBoardQuery(projectId, boardId);

  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = useBoardEditForm({ values: board.data as IBoardDto });
  const updateBoard = useUpdateBoardMutation();
  const {
    reset,
    formState: { isDirty },
  } = form;

  if (!board.isSuccess) {
    return <></>;
  }

  const handleSubmit: SubmitHandler<IUpdateBoardDto> = (boardToSubmit) =>
    updateBoard.mutate(
      { projectId: board.data.projectId, boardId: board.data.id, board: boardToSubmit },
      {
        onSuccess: () => {
          if (onSuccess) {
            onSuccess(board.data.id);
          }
          onOpenChange(false);
        },
      },
    );

  const handleClose = () => {
    reset();
    onOpenChange(false);
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <BoardEditForm
        form={form}
        board={board.data}
        isDisabled={updateBoard.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </>
  );
}
