import React, { forwardRef } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { cn } from '../../../lib/utils';
import { IActionItem } from '../../model/IActionItem';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../drop-down-menu/DropdownMenu';
import { renderDropdownMenuItems } from '../drop-down-menu/utils';

interface IHoverMenuWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  menuItems: IActionItem[];
  icon?: React.ReactNode;
  iconClassName?: string;
  children: React.ReactNode;
}

const HoverMenu = forwardRef<HTMLDivElement, IHoverMenuWrapperProps>(
  (
    {
      children,
      menuItems,
      icon = <EllipsisHorizontalIcon className="w-6 h-6 text-black" />,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const { className, ...rest } = props;

    return (
      <div ref={ref} className={cn('inline-flex group', className)} {...rest}>
        {children}
        {menuItems && menuItems.length > 0 && (
          <div className={cn('cursor-pointer ml-auto', iconClassName)}>
            <DropdownMenu>
              <DropdownMenuTrigger>{icon}</DropdownMenuTrigger>
              <DropdownMenuContent>{renderDropdownMenuItems(menuItems)}</DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    );
  },
);

HoverMenu.displayName = 'HoverMenu';

export default HoverMenu;
