/* eslint-disable no-restricted-syntax */
import { IPipelineBudgetDto } from '../models/IPipelineDto';

/**
 * Validates if a given budget period is within an accepted range, considering existing budgets.
 * This function checks if the new or updated budget period overlaps with any of the existing ones,
 * except when updating the current budget's period within its original range.
 *
 * @param startDate - The start date of the new or updated budget period.
 * @param endDate - The end date of the new or updated budget period.
 * @param budgets - An optional array of existing budgets to compare against.
 * @param currentBudget - An optional budget object representing the current budget being edited.
 * @returns {string | boolean} - Returns true if the budget period is valid,
 * or a string with an error message if invalid.
 */

export const validateIfBudgetIsWithinAcceptedRange = (
  startDate: string,
  endDate: string,
  budgets?: IPipelineBudgetDto[],
  currentBudget?: IPipelineBudgetDto,
): string | boolean => {
  // Early return if there are no budgets to compare against
  if (!budgets || budgets.length === 0) {
    return true;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  for (const budget of budgets) {
    const isCurrentBudget = currentBudget && currentBudget.id === budget.id;

    const budgetStart = new Date(budget.startDate);
    const budgetEnd = new Date(budget.endDate);

    const overlaps = start <= budgetEnd && end >= budgetStart;
    const isWithin = start >= budgetStart && end <= budgetEnd;

    // If editing current budget and dates are within the same budget range, it's valid
    if (isCurrentBudget && isWithin) {
      return true;
    }
    // If not editing, or editing a different budget and dates overlap, it's invalid
    if (!isCurrentBudget && overlaps) {
      return 'The new budget must not overlap with any existing budgets';
    }
  }

  // If no overlaps are found, the budget is valid
  return true;
};
