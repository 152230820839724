import { BellIcon, CalendarIcon, UserIcon } from '@heroicons/react/24/outline';
import React from 'react';
import DateOnly from '../../shared/components/dates/DateOnly';
import DateTime from '../../shared/components/dates/DateTime';
import { IActivityDto } from '../../shared/model/IActitityDto';
import Text from '../../shared/components/text/Text';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';

interface IProps {
  activity: IActivityDto;
  showAssignedTo: boolean;
}

function ActivityProperty({
  icon,
  children,
}: {
  icon: React.ReactElement;
  children: React.ReactNode;
}) {
  return (
    <div className="flex-none flex items-center space-x-1 px-1">
      <Text as="span" size="xSmall" brightness="light">
        {icon}
      </Text>
      <Text as="span" size="xSmall" brightness="light">
        {children}
      </Text>
    </div>
  );
}

export function ActivityProperties({ activity, showAssignedTo }: IProps) {
  if (
    !(activity.expireTime || activity.reminderTime || (activity.assignedUser && showAssignedTo))
  ) {
    return null;
  }

  return (
    <div className="flex flex-wrap text-xs text-light mt-2 pl-1 pr-2">
      {activity.expireTime && (
        <ActivityProperty icon={<CalendarIcon className="w-3 h-3" />}>
          <DateOnly date={activity.expireTime} />
        </ActivityProperty>
      )}
      {activity.reminderTime && (
        <ActivityProperty icon={<BellIcon className="w-3 h-3" />}>
          <DateTime date={activity.reminderTime} />
        </ActivityProperty>
      )}
      {activity.assignedUser && showAssignedTo && (
        <ActivityProperty icon={<UserIcon className="w-3 h-3" />}>
          <ContactUserProfileLink userId={activity.assignedUser.id}>
            {activity.assignedUser.displayName}
          </ContactUserProfileLink>
        </ActivityProperty>
      )}
    </div>
  );
}
