import { ArrowRightIcon, PencilIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { IActionItem } from '../../../shared/model/IActionItem';
import { useNetworkPrivilegesQuery } from '../queries/networkPriviligesQueries';
import useModal from '../../../shared/components/modal/hooks/useModal';
import InviteMember from '../../Members/MemberInvite';
import { ResourceType } from '../../../shared/model/ResourceType';
import NetworkCreateEditModal from '../NetworkCreateEditModal';
import { useDeleteNetworkMutation, useNetworkQuery } from '../queries/networkQueries';
import useConfirm from '../../../shared/components/alert-dialog/hooks/useConfirm';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import { useRemoveMemberMutation } from '../../Members/queries/MemberQueries';
import { useMyUserId } from '../../../shared/auth/accountHooks';
import { resolveDeleteConfirm } from '../../../shared/components/alert-dialog/resolvers/confirmResolvers';

export default function useNetworkActions(networkId?: string) {
  const myUserId = useMyUserId();

  const networkPrivilegesQuery = useNetworkPrivilegesQuery(networkId);
  const networkPrivileges = networkPrivilegesQuery.data;
  const networkQuery = useNetworkQuery(networkId);
  const network = networkQuery.data;
  const deleteNetworkMutation = useDeleteNetworkMutation();
  const removeNetworkMemberMutation = useRemoveMemberMutation(ResourceType.Network);

  const { openModal, closeModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const menuItems: IActionItem[] = [];

  if (!network) return { items: menuItems, isPending: networkPrivilegesQuery.isPending };

  if (networkPrivileges?.canInviteMember) {
    menuItems.push({
      id: 'Invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () =>
        openModal({
          title: 'Invite member',
          content: (
            <InviteMember
              type={ResourceType.Network}
              resourceIds={[network.id]}
              onComplete={closeModal}
            />
          ),
        }),
    });
  }
  if (networkPrivileges?.canEdit) {
    menuItems.push({
      id: 'Edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () =>
        openModal({
          title: 'Edit network',
          content: (
            <NetworkCreateEditModal
              network={network}
              onSuccess={closeModal}
              onCancel={closeModal}
            />
          ),
        }),
    });
  }
  if (networkPrivileges?.canDelete) {
    menuItems.push({
      id: 'Delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () =>
        openConfirm({
          ...resolveDeleteConfirm('network'),
          onAction: () => {
            deleteNetworkMutation.mutate({
              networkId: network.id,
              networkName: network.name as string,
            });
            closeConfirm();
          },
        }),
    });
  } else {
    menuItems.push({
      id: 'Leave',
      title: 'Leave',
      icon: ArrowRightIcon,
      onClick: () =>
        openConfirm({
          title: 'Leave network',
          description: 'Are you sure you want to leave this network?',
          actionText: 'Leave',
          actionColor: ButtonColors.Red,
          onAction: () => {
            removeNetworkMemberMutation.mutate(
              { member: { name: '', resourceId: network.id, userId: myUserId || '' } },
              {
                onSuccess: () => {
                  closeConfirm();
                },
              },
            );
          },
        }),
    });
  }

  return { items: menuItems, isPending: networkPrivilegesQuery.isPending };
}
