import { cn } from '../../../lib/utils';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../dialog/Dialog';
import useModal from './hooks/useModal';

export default function Modal() {
  const { isOpen, closeModal, modalContentProps } = useModal();
  const { title, content, modalSize, hideCloseButton } = modalContentProps;

  // override the default widths
  let contentClassName = '';
  switch (modalSize) {
    case 'small':
      contentClassName = 'md:max-w-xl lg:max-w-xl';
      break;
    case 'medium':
      contentClassName = 'lg:max-w-2xl';
      break;
    case 'fullscreen':
      contentClassName = 'h-[calc(100%-2rem)]';
      break;
    default:
      break;
  }

  return (
    <Dialog open={isOpen}>
      <DialogContent
        className={cn(contentClassName)}
        hideCloseButton={hideCloseButton}
        onEscapeKeyDown={closeModal}
        onInteractOutside={closeModal}
        onClose={closeModal}
      >
        {title && (
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
        )}
        {content}
      </DialogContent>
    </Dialog>
  );
}
