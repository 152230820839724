import { ArrowRightIcon, PencilIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { IActionItem } from '../../../shared/model/IActionItem';
import { useProjectPrivilegesQuery } from '../queries/projectPriviligesQueries';
import { IProjectDto } from '../models/IProjectDto';
import InviteMember from '../../Members/MemberInvite';
import { ResourceType } from '../../../shared/model/ResourceType';
import ProjectCreateEditModal from '../ProjectCreateEditModal';
import useModal from '../../../shared/components/modal/hooks/useModal';
import useConfirm from '../../../shared/components/alert-dialog/hooks/useConfirm';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import { useDeleteProjectMutation } from '../queries/projectQueries';
import { useRemoveMemberMutation } from '../../Members/queries/MemberQueries';
import { useMyUserId } from '../../../shared/auth/accountHooks';
import { resolveDeleteConfirm } from '../../../shared/components/alert-dialog/resolvers/confirmResolvers';

export default function useProjectActions(
  project: IProjectDto,
  onDeleteCallback?: () => void,
  onLeaveCallback?: () => void,
) {
  const privilegesQuery = useProjectPrivilegesQuery(project?.id);
  const privileges = privilegesQuery.data;
  const { openModal, closeModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();
  const myUserId = useMyUserId();

  const deleteMutation = useDeleteProjectMutation();
  const removeMemberMutation = useRemoveMemberMutation(ResourceType.Project);

  const menuItems: IActionItem[] = [];

  if (privileges?.canEdit) {
    menuItems.push({
      id: 'Edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () => {
        openModal({
          title: project ? 'Update project' : 'Create new project',
          content: <ProjectCreateEditModal project={project} />,
        });
      },
    });
  }
  if (privileges?.canInviteMember) {
    menuItems.push({
      id: 'Invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () => {
        openModal({
          title: 'Invite member',
          content: (
            <InviteMember
              resourceIds={project.id ? [project.id] : []}
              type={ResourceType.Project}
              onComplete={closeModal}
            />
          ),
        });
      },
    });
  }
  if (privileges?.canDelete) {
    menuItems.push({
      id: 'Delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => {
        openConfirm({
          ...resolveDeleteConfirm('project'),
          onAction: () => {
            deleteMutation.mutate({ projectId: project.id });

            closeConfirm();
            if (onDeleteCallback) onDeleteCallback();
          },
        });
      },
    });
  } else {
    menuItems.push({
      id: 'Leave',
      title: 'Leave',
      icon: ArrowRightIcon,
      onClick: () => {
        openConfirm({
          title: 'Leave project',
          description: 'Are you sure you want to leave this project?',
          actionText: 'Leave',
          actionColor: ButtonColors.Red,
          onAction: () => {
            if (!myUserId) return;
            if (!project.id) return;

            removeMemberMutation.mutate(
              {
                member: {
                  name: '',
                  resourceId: project.id,
                  userId: myUserId,
                },
              },
              {
                onSuccess: () => {
                  closeConfirm();
                  if (onLeaveCallback) onLeaveCallback();
                },
              },
            );
          },
        });
      },
    });
  }

  return { items: menuItems, isPending: privilegesQuery.isPending };
}
