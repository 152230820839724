import { useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import SearchBar from '../search/SearchBar';
import AttachmentList from './AttachmentLists';

interface IProps {
  conversationId: string;
}

export default function Attachments({ conversationId }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  return (
    <div className="flex flex-col h-full">
      <SearchBar searchTerm={searchTerm} onSearchChange={(e) => setSearchTerm(e.target.value)} />
      <AttachmentList conversationId={conversationId} searchTerm={debouncedSearchTerm} />
    </div>
  );
}
