import { HubConnection } from '@microsoft/signalr';
import { IConversationDto } from '../model/IConversationDto';
import { IMessageDto } from '../model/IMessageDto';
import IParticipantDto from '../model/IParticipantDto';

export enum UserActionTypes {
  Set = 'SET_CURRENT_USER',
}

export enum ConversationActionTypes {
  SetConversations = 'SET_CONVERSATIONS',
  AddMessageToConversation = 'ADD_MESSAGE_TO_CONVERSATION',
  UpdateMessageFromConversation = 'UPDATE_MESSAGE_FROM_CONVERSATION',
  DeleteMessageFromConversation = 'DELETE_MESSAGE_FROM_CONVERSATION',
  UpdateLastRead = 'UPDATE_LAST_READ',
}

export enum ConnectionActionTypes {
  SetConnection = 'SET_CONNECTION',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type ConversationTypePayload = {
  [ConversationActionTypes.SetConversations]: { conversations: any[]; userId: string };
  [ConversationActionTypes.UpdateLastRead]: { participant: any; userId: string };
  [ConversationActionTypes.AddMessageToConversation]: {
    message: IMessageDto;
    userId: string;
  };
  [ConversationActionTypes.DeleteMessageFromConversation]: { message: IMessageDto };
  [ConversationActionTypes.UpdateMessageFromConversation]: { message: IMessageDto };
};

export type ConversationActions =
  ActionMap<ConversationTypePayload>[keyof ActionMap<ConversationTypePayload>];

type ConnectionTypePayload = {
  [ConnectionActionTypes.SetConnection]: { connection: HubConnection };
};

export type ConnectionActions =
  ActionMap<ConnectionTypePayload>[keyof ActionMap<ConnectionTypePayload>];

export const conversationReducer = (
  state: IConversationDto[] | undefined,
  action: ConversationActions | ConnectionActions,
) => {
  switch (action.type) {
    case ConversationActionTypes.SetConversations:
      return action.payload.conversations.map((conversation) => {
        const currentUserAsParticipant = conversation.participants.find(
          (participant: IParticipantDto) => participant.userId === action.payload.userId,
        );

        if (currentUserAsParticipant && conversation.latestMessage) {
          const conversationClone = { ...conversation };
          conversationClone.latestMessage.isUnread =
            new Date(conversationClone.latestMessage?.createdTime).getTime() >
            new Date(currentUserAsParticipant.lastReadTime).getTime();

          return conversationClone;
        }

        return conversation;
      });
    case ConversationActionTypes.AddMessageToConversation:
      return state?.map((conversation) => {
        if (conversation.id === action.payload.message.conversationId) {
          const conversationClone = { ...conversation };
          // if updating and not latest message, don't change anything.
          if (
            action.payload.message.updatedTime &&
            conversationClone.latestMessage.id !== action.payload.message.id
          ) {
            return conversationClone;
          }

          conversationClone.latestMessage = action.payload.message;

          const currentUserAsParticipant = conversation.participants.find(
            (participant: IParticipantDto) => participant.userId === action.payload.userId,
          );

          if (currentUserAsParticipant && conversation.latestMessage) {
            conversationClone.latestMessage.isUnread =
              new Date(conversationClone.latestMessage?.createdTime).getTime() >
              new Date(currentUserAsParticipant.lastReadTime).getTime();
          }

          return conversationClone;
        }
        return conversation;
      });

    case ConversationActionTypes.DeleteMessageFromConversation:
      return state?.map((conversation) => {
        if (conversation.id === action.payload.message.conversationId) {
          const conversationClone = { ...conversation };

          if (conversationClone.latestMessage.id === action.payload.message.id) {
            conversationClone.latestMessage.text = '-';
          }

          return conversationClone;
        }
        return conversation;
      });

    case ConversationActionTypes.UpdateLastRead:
      return state?.map((conversation) => {
        const currentUserAsParticipant = conversation.participants.find(
          (participant: IParticipantDto) =>
            participant.userId === action.payload.userId &&
            participant.conversationId === action.payload.participant.conversationId,
        );

        if (currentUserAsParticipant) {
          const conversationClone = { ...conversation };

          const _participant = conversationClone.participants.find(
            (participant) => participant.userId === action.payload.participant.userId,
          );

          if (_participant) {
            _participant.lastReadTime = action.payload.participant.lastReadTime;

            if (!conversation.latestMessage) return conversationClone;

            conversationClone.latestMessage.isUnread =
              new Date(conversationClone.latestMessage?.createdTime).getTime() >
              new Date(currentUserAsParticipant.lastReadTime).getTime();
          }

          return conversationClone;
        }

        return conversation;
      });
    default:
      return state;
  }
};

export const connectionReducer = (
  state: HubConnection,
  action: ConversationActions | ConnectionActions,
) => {
  switch (action.type) {
    case ConnectionActionTypes.SetConnection:
      return action.payload.connection;
    default:
      return state;
  }
};
