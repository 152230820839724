interface ISortable {
  id: string;
}
/**
 * Calculates the updated positions for drag and drop within sortable arrays.
 * If you are moving items within the same list, you can pass the same array for
 * both sourceItems and targetItems.
 *
 * @template T - The type of sortable items.
 * @param {T[]} _sourceItems - The source array of sortable items.
 * @param {T[]} _targetItems - The target array of sortable items.
 * @param {string} draggedItemId - The ID of the item being dragged.
 * @param {number} destinationIndex - The index where the item will be dropped in the target array.
 * @returns {{
 *   sourceItems: T[];
 *   targetItems: T[];
 *   moveToPositionAfterItemId: string | null;
 *   itemToBeMoved: T;
 * }} An object containing the updated source and target arrays, the ID of the item that
 *  the dropped item should be inserted after, and the item that has been moved.
 */

export function CalculateDragDropPositions<T extends ISortable>(
  _sourceItems: T[],
  _targetItems: T[],
  draggedItemId: string,
  destinationIndex: number,
) {
  const sourceItems: T[] = [..._sourceItems];
  const targetItems: T[] = [..._targetItems];
  const indexToMove = sourceItems.findIndex((_item: T) => _item.id === draggedItemId);
  if (indexToMove < 0) throw new Error('draggedItemId not found in source');

  // If the source and target are the same, we need to remove the item from the target
  if (JSON.stringify(sourceItems) === JSON.stringify(targetItems)) {
    targetItems.splice(indexToMove, 1);
  }

  const [itemToBeMoved] = sourceItems.splice(indexToMove, 1);

  // Insert the item into the target array at the correct index
  targetItems.splice(destinationIndex, 0, itemToBeMoved);

  const targetIndex = targetItems.findIndex((_item) => _item.id === draggedItemId);
  const itemBeforeTargetIndex = targetIndex - 1;

  const moveToPositionAfterItemId = targetItems[itemBeforeTargetIndex]?.id || null;

  return {
    sourceItems,
    targetItems,
    moveToPositionAfterItemId,
    itemToBeMoved,
  };
}
