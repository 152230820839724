import ServiceHeader from '../building-blocks/ServiceHeader';
import BreadcrumbNavigator from '../../navigation/BreadcrumbNavigator/BreadcrumbNavigator';
import { IBreadcrumbNavigatorItem } from '../../navigation/BreadcrumbNavigator/helpers/breadcrumbNavigatorHelper';
import FlexContainer from '../../structure/FlexContainer';
import { IActionItem } from '../../../model/IActionItem';

export interface IBaseLayoutProps {
  title: string;
  breadcrumbsNavigatorItems?: IBreadcrumbNavigatorItem[];
  headerActions?: IActionItem[];
  contentAfterHeader?: React.ReactNode;
  children: React.ReactNode;
}

function ServiceWindow({ children }: { children: React.ReactNode }) {
  return (
    <FlexContainer
      direction="column"
      className="h-full w-full overflow-hidden px-3 md:px-6 pb-3 md:py-4"
      gap="small"
    >
      {children}
    </FlexContainer>
  );
}

function ServiceContent({ children }: { children: React.ReactNode }) {
  return <div className="flex-1 overflow-hidden">{children}</div>;
}

export function BaseLayout({
  title,
  breadcrumbsNavigatorItems,
  headerActions,
  contentAfterHeader,
  children,
}: IBaseLayoutProps) {
  return (
    <ServiceWindow>
      {breadcrumbsNavigatorItems && <BreadcrumbNavigator items={breadcrumbsNavigatorItems} />}
      <ServiceHeader text={title} actions={headerActions} contentAfterHeader={contentAfterHeader} />
      <ServiceContent>{children}</ServiceContent>
    </ServiceWindow>
  );
}
