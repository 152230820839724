import ContentLoader from 'react-content-loader';

export function NavigationListLoader() {
  return (
    <ContentLoader
      speed={2}
      backgroundColor="#eeeff1"
      foregroundColor="#e0e3e6"
      style={{ width: '100%', height: '100%', padding: '0 20px' }}
    >
      <circle cx="30" cy="30" r="26" />
      <rect x="68" y="8" rx="8" ry="8" width="70" height="16" />
      <rect x="68" y="36" rx="8" ry="8" width="272" height="16" />
      <circle cx="31" cy="112" r="26" />
      <rect x="68" y="90" rx="8" ry="8" width="50" height="16" />
      <rect x="68" y="118" rx="8" ry="8" width="222" height="16" />
      <circle cx="29" cy="190" r="26" />
      <rect x="68" y="168" rx="8" ry="8" width="90" height="16" />
      <rect x="68" y="196" rx="8" ry="8" width="242" height="16" />
    </ContentLoader>
  );
}
