import ContentLoader from 'react-content-loader';

interface IProps {
  speed?: number;
}

export function ListLoader({ speed = 2 }: IProps) {
  return (
    <div>
      <ContentLoader
        speed={speed}
        backgroundColor="#eeeff1"
        foregroundColor="#e0e3e6"
        style={{ width: '100%', height: '155px' }}
      >
        <rect x="0" y="8" rx="8" ry="8" width="100%" height="36" />
        <rect x="0" y="60" rx="8" ry="8" width="100%" height="36" />
        <rect x="0" y="112" rx="8" ry="8" width="100%" height="36" />
      </ContentLoader>
    </div>
  );
}
