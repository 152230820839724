import ContentLoader from 'react-content-loader';
import FlexContainer from '../../shared/components/structure/FlexContainer';

export function RequestLoader() {
  return (
    <FlexContainer direction="column" className="w-full p-4" gap="none">
      <ContentLoader
        speed={2}
        backgroundColor="#eeeff1"
        foregroundColor="#e0e3e6"
        style={{ width: '100%', height: '70px' }}
      >
        <circle cx="18" cy="30" r="18" />
        <rect x="55" y="10" rx="8" ry="8" width="150" height="16" />
        <rect x="55" y="36" rx="8" ry="8" width="272" height="16" />
      </ContentLoader>
    </FlexContainer>
  );
}
