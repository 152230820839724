import { useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListEmptyState from '../../shared/components/lists/ListEmptyState';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { IContactDto } from './models/IContactDto';
import { IContactNoteDto } from './models/IContactNoteDto';
import ContactPrivateNote from './ContactPrivateNote';
import PrivateNoteCreate from './ContactPrivateNoteCreate';
import {
  useCreateUpdatePrivateNoteMutation,
  useDeletePrivateNoteMutation,
  useMoveContactNotesMutation,
  usePrivateNotesPagesQuery,
} from './queries/privateNoteQueries';
import { ListLoader } from '../../shared/components/lists/ListLoader';
import { CardList } from '../../shared/components/cards/CardList';
import Text from '../../shared/components/text/Text';
import { CalculateDragDropPositions } from '../../shared/utils/dragDropUtils';
import DroppableStyled from '../../shared/components/drag-and-drop/DroppableStyled';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import Howto from '../../shared/components/message-popups/HowTo';
import useConfirm from '../../shared/components/alert-dialog/hooks/useConfirm';
import { resolveDeleteConfirm } from '../../shared/components/alert-dialog/resolvers/confirmResolvers';

interface IProps {
  contact: IContactDto;
  showHeader?: boolean;
}

export default function ContactPrivateNotes({ contact, showHeader = true }: IProps) {
  const limit = 50;
  const { openConfirm, closeConfirm } = useConfirm();

  const createUpdatePrivateNotes = useCreateUpdatePrivateNoteMutation();
  const deletePrivateNotes = useDeletePrivateNoteMutation();
  const privateNotesQuery = usePrivateNotesPagesQuery({ contactId: contact.id, limit });
  const notesFlat = privateNotesQuery.data?.pages?.flat() || [];

  const handleCreateUpdateNote = (note: IContactNoteDto) => {
    createUpdatePrivateNotes.mutate({ contactId: contact.id, note });
  };

  const handleContinueDeleteNote = (note: IContactNoteDto) => {
    closeConfirm();
    deletePrivateNotes.mutate({ contactId: contact.id, note });
  };

  const movePrivateNotesMutation = useMoveContactNotesMutation();

  const handleMove = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!notesFlat) return;
    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const { moveToPositionAfterItemId } = CalculateDragDropPositions<IContactNoteDto>(
      notesFlat as IContactNoteDto[],
      notesFlat as IContactNoteDto[],
      draggableId,
      destination.index,
    );

    movePrivateNotesMutation.mutate({
      destinationIndex: destination.index,
      noteId: draggableId,
      note: { moveToPositionAfterNoteId: moveToPositionAfterItemId },
      contactId: contact.id,
      limit,
    });
  };

  if (privateNotesQuery.isSuccess) {
    return (
      <div className="flex h-full flex-col overflow-y-hidden">
        {showHeader && (
          <Text as="h1" size="large">
            Notes
          </Text>
        )}
        <PrivateNoteCreate onCreateNote={handleCreateUpdateNote} />
        {notesFlat.length === 0 && (
          <ListEmptyState>
            Make sure you never forget a detail about your contact. <br />
            Start creating notes.
          </ListEmptyState>
        )}
        {privateNotesQuery.isLoading && <ListLoader />}
        <ScrollBarWrapper id="contactNotesScroll">
          <InfiniteScroll
            scrollableTarget="contactNotesScroll"
            next={() => privateNotesQuery.fetchNextPage()}
            hasMore={privateNotesQuery.hasNextPage || false}
            loader={
              <div className="py-4 text-center">
                <Text as="p" size="small">
                  Loading...
                </Text>
              </div>
            }
            dataLength={notesFlat.length}
            style={{ overflow: '' }}
          >
            <DragDropContext onDragEnd={handleMove}>
              <DroppableStyled>
                <CardList>
                  {notesFlat?.map((note, index) =>
                    note && note.id ? (
                      <Draggable key={note.id} draggableId={note.id} index={index}>
                        {(provided) => (
                          <ContactPrivateNote
                            draggableProvided={provided}
                            key={note.id}
                            note={note}
                            onUpdateNote={handleCreateUpdateNote}
                            onDeleteNote={() => {
                              openConfirm({
                                ...resolveDeleteConfirm('note'),
                                onAction: () => {
                                  handleContinueDeleteNote(note);
                                },
                              });
                            }}
                          />
                        )}
                      </Draggable>
                    ) : null,
                  )}
                </CardList>
              </DroppableStyled>
            </DragDropContext>
          </InfiniteScroll>
        </ScrollBarWrapper>
        <div className="flex pt-2">
          <Howto
            title="Notes"
            description="Take notes about your contact. Your notes are private and are not
          shared with your contact."
          />
        </div>
      </div>
    );
  }

  return <ErrorComponent queryResults={[privateNotesQuery]} />;
}
