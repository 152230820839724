import { ChangeEvent, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '../../shared/components/buttons/Button';
import SelectList from '../../shared/components/lists/SelectList';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SearchBar from '../../shared/components/search/SearchBar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import useDebounce from '../../shared/hooks/useDebounce';
import { userProfileQueryBase } from '../Profile/queries/userProfileQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import { DialogFooter } from '../../shared/components/dialog/Dialog';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';
import {
  useSearchAssignPagesQuery,
  useSetAssignedToOnActivityMutation,
} from './queries/activitiesQueries';
import { IActivityDto } from '../../shared/model/IActitityDto';
import FlexContainer from '../../shared/components/structure/FlexContainer';

export interface IListItem {
  id: string;
  headline: string;
  information: string;
  photoUrl: string;
}

function AssignToSelectList({
  resourceIds,
  resourceType,
  activityId,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  resourceIds: ResourceIds;
  resourceType: ResourceType;
  activityId: string;
  searchTerm: string;
  selectedItem?: IListItem;
  onSelectItem: (item: IListItem) => void;
}) {
  const queryClient = useQueryClient();
  const cleanedSearchTerm = searchTerm.toLocaleLowerCase().trim();
  const resourceMembersQuery = useSearchAssignPagesQuery(
    resourceType,
    resourceIds,
    activityId,
    (member) => member.displayName.toLocaleLowerCase().indexOf(cleanedSearchTerm) >= 0,
  );

  const [listItems, setListItems] = useState<IListItem[]>();
  useEffect(() => {
    async function GetListItems() {
      if (!resourceMembersQuery.data) {
        return;
      }

      const result: IListItem[] = await Promise.all(
        resourceMembersQuery.data.map(async (member) => {
          const userProfile = await queryClient.fetchQuery(userProfileQueryBase(member.id));
          return {
            id: member.id,
            headline: `${userProfile.displayName}`,
            information: userProfile.primaryEmailAddress,
            photoUrl: userProfile.photoUrl,
          };
        }),
      );
      setListItems(result);
    }

    GetListItems();
  }, [resourceMembersQuery.data]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ id }) => id}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

interface IProps {
  resourceIds: ResourceIds;
  entityType: ResourceType;
  activity: IActivityDto;
  onAssign: () => void;
}

export default function ActivityAssignTo({ resourceIds, activity, entityType, onAssign }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState<IListItem | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const assigneeMutation = useSetAssignedToOnActivityMutation(resourceIds, entityType);

  const handleSetAssignedTo = (assignedTo: { id: string }) => {
    assigneeMutation.mutate({ activityId: activity.id, userId: assignedTo.id });
  };

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
    setSelectedAssignee(undefined);
  };

  const handleAssign = () => {
    if (!resourceIds || !activity.id) {
      return;
    }
    if (!selectedAssignee) return;
    handleSetAssignedTo(selectedAssignee);
    onAssign();
  };
  return (
    <FlexContainer gap="small" direction="column">
      <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />

      <ScrollBarWrapper className="max-h-96 text-left">
        <AssignToSelectList
          resourceIds={resourceIds}
          resourceType={entityType}
          activityId={activity.id}
          searchTerm={debouncedSearchTerm}
          selectedItem={selectedAssignee}
          onSelectItem={setSelectedAssignee}
        />
      </ScrollBarWrapper>
      <FlexContainer justify="end" align="end">
        <Button
          color={ButtonColors.Blue}
          text="Assign"
          onClick={handleAssign}
          disabled={!selectedAssignee || assigneeMutation.isPending}
        />
      </FlexContainer>
    </FlexContainer>
  );
}
