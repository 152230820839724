import { cn } from '../../../../lib/utils';
import { ButtonColors } from '../../../constants/ButtonColors';
import { ButtonStyle } from '../Button';

const colorLookup: { [key in ButtonColors]: string } = {
  [ButtonColors.White]:
    'disabled:text-blue text-blue bg-white border-blue hover:text-white hover:bg-cyan-dark',
  [ButtonColors.Gray]:
    'disabled:text-black text-black bg-gray hover:bg-gray-light border-gray-dark',
  [ButtonColors.Green]:
    'disabled:bg-green-light text-white bg-green hover:bg-green-dark border-transparent',
  [ButtonColors.Cyan]:
    'disabled:text-white-600 text-white bg-cyan disabled:bg-cyan-light hover:bg-cyan-dark border-transparent',
  [ButtonColors.Red]:
    'disabled:text-white-600 hover:text-white-700 text-center text-white bg-red hover:bg-red-dark border-transparent',
  [ButtonColors.Blue]:
    'disabled:text-white-600 hover:text-white-700 text-white bg-blue hover:bg-cyan-dark border-transparent disabled:bg-blue',
};

export interface IButtonStyleProps {
  color: ButtonColors;
  isFullWidth?: boolean;
  buttonStyle?: ButtonStyle;
  className?: string;
  size?: 'small' | 'normal';
}

export const useButtonStyle = ({
  color,
  isFullWidth = false,
  buttonStyle = 'filled',
  size = 'normal',
  className: customClassName,
  ...rest
}: IButtonStyleProps) => {
  const className = cn(
    'inline-flex items-center justify-center px-4 py-2 border text-sm font-medium text-nowrap rounded-full shadow-sm cursor-pointer focus:outline-none disabled:opacity-30 disabled:cursor-not-allowed h-9',
    isFullWidth ? 'w-full' : '',
    buttonStyle === 'filled' ? 'border' : 'border-2 bg-transparent',
    size === 'small' && 'scale-75',
    colorLookup[color],
    customClassName,
  );

  return { className, ...rest };
};
