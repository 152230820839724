import { forwardRef } from 'react';
import { NumberFormatBase } from 'react-number-format';
import { swedishCurrencyFormatter } from '../../../utils/numberUtils';

const CurrencyInput = forwardRef(({ ...rest }, ref) => (
  <NumberFormatBase
    getInputRef={ref}
    {...rest}
    format={swedishCurrencyFormatter}
    className="shadow-sm placeholder:text-gray-400 block w-full border-gray-300 rounded-md disabled:bg-gray-200 focus:ring-0 focus:border-zinc-400"
  />
));

export default CurrencyInput;
