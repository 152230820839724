import { UseQueryResult } from '@tanstack/react-query';
import ConnectionRequest from './ConnectionRequest';
import InviteToNetworkRequest from './InviteToNetworkRequest';
import Invitations from './Invitations';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { IInvitationDto } from '../../shared/model/IInvitationDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { RequestLoader } from './RequestLoader';
import InviteToPipelineRequest from './InviteToPipelineRequest';
import { IConnectionRequestDto } from '../../shared/model/IConnectionRequestDto';
import { IOrganizationInvitationDto } from '../Companies/models/IOrganizationInvitationDto';
import OrganizationInvitationRequests from './OrganizationInvitationRequests';
import { IInviteRequestDto } from './models/IInviteRequestDto';
import ProjectInvitationRequests from './ProjectInvitationRequests';

interface IProps {
  connectionRequestsQuery: UseQueryResult<IConnectionRequestDto[]>;
  networkInviteRequestsQuery: UseQueryResult<IInviteRequestDto[]>;
  pipelineInviteRequestsQuery: UseQueryResult<IInviteRequestDto[]>;
  organizationInviteRequestsQuery: UseQueryResult<IOrganizationInvitationDto[]>;
  invitationsQuery: UseQueryResult<IInvitationDto[]>;
  projectInviteRequestsQuery: UseQueryResult<IInviteRequestDto[]>;
}

export default function Requests({
  connectionRequestsQuery,
  networkInviteRequestsQuery,
  pipelineInviteRequestsQuery,
  organizationInviteRequestsQuery,
  invitationsQuery,
  projectInviteRequestsQuery,
}: IProps) {
  if (
    connectionRequestsQuery.isPending ||
    networkInviteRequestsQuery.isPending ||
    pipelineInviteRequestsQuery.isPending ||
    organizationInviteRequestsQuery.isPending ||
    invitationsQuery.isPending ||
    projectInviteRequestsQuery.isPending
  ) {
    return (
      <div>
        <RequestLoader />
      </div>
    );
  }

  if (
    connectionRequestsQuery.isSuccess &&
    networkInviteRequestsQuery.isSuccess &&
    invitationsQuery.isSuccess &&
    pipelineInviteRequestsQuery.isSuccess &&
    organizationInviteRequestsQuery.isSuccess &&
    projectInviteRequestsQuery.isSuccess
  ) {
    return (
      <ScrollBarWrapper>
        <ConnectionRequest connectionRequests={connectionRequestsQuery.data} />
        <InviteToNetworkRequest networkInviteRequests={networkInviteRequestsQuery.data} />
        <InviteToPipelineRequest pipelineInviteRequests={pipelineInviteRequestsQuery.data} />
        <OrganizationInvitationRequests
          organizationInvitations={organizationInviteRequestsQuery.data}
        />
        <ProjectInvitationRequests projectInvitations={projectInviteRequestsQuery.data} />
        <Invitations invitations={invitationsQuery.data} />
      </ScrollBarWrapper>
    );
  }

  return (
    <ErrorComponent
      queryResults={[
        connectionRequestsQuery,
        networkInviteRequestsQuery,
        invitationsQuery,
        pipelineInviteRequestsQuery,
        organizationInviteRequestsQuery,
      ]}
    />
  );
}
