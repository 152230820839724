import { useMemo } from 'react';
import { useParams } from 'react-router';
import getActiveBreadcrumbNavigatorItems, {
  convertToBreadcrumbNavigatorItems,
  IBreadcrumbNavigatorItem,
} from '../../../shared/components/navigation/BreadcrumbNavigator/helpers/breadcrumbNavigatorHelper';
import { useOrganizationsQuery } from '../queries/organizationQueries';
import { useProjectsQuery } from '../../Projects/queries/projectQueries';
import { useBoardsQuery } from '../../Projects/queries/boardQueries';
import { abbreviateString } from '../../../shared/utils/stringUtils';

export default function useCompanyBreadcrumbNavigation() {
  const { tenantId, projectId, boardId } = useParams();

  const companies = useOrganizationsQuery().data?.pages.flat();
  const projects = useProjectsQuery({ tenantId }).data;
  const boards = useBoardsQuery({ projectId: projectId || '' }).data;

  const breadcrumbNavigatorItems = useMemo<IBreadcrumbNavigatorItem[]>(() => {
    if (!companies) return [];

    // Step 1: Convert companies to breadcrumb items with nested projects and boards
    const breadcrumbItemsStructure = convertToBreadcrumbNavigatorItems(
      companies,
      '/companies/',
    ).map((company) => ({
      ...company,
      children: convertToBreadcrumbNavigatorItems(
        projects?.data.filter((p) => p.tenantId === company.id) || [],
        `/companies/${company.id}/projects/`,
      ).map((project) => ({
        ...project,
        children: convertToBreadcrumbNavigatorItems(
          boards?.data.filter((b) => b.projectId === project.id) || [],
          `/companies/${company.id}/projects/${project.id}/boards/`,
        ),
      })),
    }));

    // Step 2: Get active breadcrumb items based on current IDs
    const breadcrumbNavigatorItemsSorted = getActiveBreadcrumbNavigatorItems(
      breadcrumbItemsStructure,
      tenantId || '',
      projectId || '',
      boardId || '',
    );

    // Step 3: Prepend the "My Companies" breadcrumb
    breadcrumbNavigatorItemsSorted.unshift({
      id: '',
      name: 'My Companies',
      abbreviation: abbreviateString('My Companies'),
      route: '/',
      isActive: true,
      isDisabled: true,
    });

    return breadcrumbNavigatorItemsSorted;
  }, [companies, projects, boards]);

  return { breadcrumbNavigatorItems };
}
