import { Badge } from '../../badge/Badge';

interface IProps {
  numberOfNewNotifications: number;
}
export default function SidebarNavNotification({ numberOfNewNotifications }: IProps) {
  if (numberOfNewNotifications === 0) return <></>;
  return (
    <Badge className="absolute left-[20px] top-[-5px] fade-in-element">
      {numberOfNewNotifications}
    </Badge>
  );
}
