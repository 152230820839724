import { Clipboard } from 'lucide-react';
import ActivityBadge, { IActivityBadgeProps } from './ActivityBadge';

export type ITaskActivityBadgeProps = Omit<IActivityBadgeProps, 'icon' | 'classNames'>;

export default function TaskActivityBadge(props: ITaskActivityBadgeProps) {
  return (
    <ActivityBadge
      icon={<Clipboard className="w-4 h-4" stroke="#D527B7" />}
      classNames="bg-pink-200"
      {...props}
    />
  );
}
