import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Text from '../text/Text';

type FileDropAreaState = {
  files: File[];
  openFileDialog: () => void;
  addFiles: (...files: File[]) => void;
  removeFiles: (...files: File[]) => void;
  clearFiles: () => void;
};

const FileDropContext = createContext<FileDropAreaState | undefined>(undefined);

interface IFileDropAreaProps {
  className?: string; // TODO: some kind of container props type instead of string
  children: React.ReactNode;
  onFilesChanged?: (files: File[]) => void;
}

export function FileDropContainer({ className, children, onFilesChanged }: IFileDropAreaProps) {
  const [files, setFiles] = useState([] as File[]);
  const { getRootProps, getInputProps, open, isDragActive, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  const addFiles = useCallback(
    (...filesToAdd: File[]) => {
      const filesClone = [...files];
      filesToAdd.forEach((file) => {
        if (filesClone.findIndex((_file) => file.name === _file.name) >= 0) return;
        filesClone.push(file);
      });
      setFiles(filesClone);
      if (onFilesChanged) onFilesChanged(filesClone);
    },
    [onFilesChanged, files],
  );

  const removeFiles = useCallback(
    (...filesToRemove: File[]) => {
      setFiles((prevFiles) => {
        const filesClone = [...prevFiles];
        filesToRemove.forEach((file) => {
          const index = filesClone.findIndex((_file) => file.name === _file.name);
          if (index < 0) return;
          filesClone.splice(index, 1);
        });
        if (onFilesChanged) onFilesChanged(filesClone);
        return filesClone;
      });
    },
    [onFilesChanged],
  );

  const clearFiles = useCallback(() => {
    setFiles([]);
    if (onFilesChanged) onFilesChanged([]);
  }, [onFilesChanged]);

  useEffect(() => {
    addFiles(...acceptedFiles);
  }, [acceptedFiles.length]);

  const state = useMemo(
    () => ({ files, openFileDialog: open, addFiles, removeFiles, clearFiles }),
    [files, open, addFiles, removeFiles, clearFiles],
  );

  return (
    <FileDropContext.Provider value={state}>
      <div {...getRootProps({ className })}>
        {children}
        {isDragActive && (
          <div className="absolute flex place-content-center place-items-center border-2 inset-0 bg-cyan-light border-cyan-500 opacity-50">
            <Text as="p" size="xLarge" color="cyan" brightness="dark">
              Drop files here..
            </Text>
          </div>
        )}
        <input {...getInputProps()} />
      </div>
    </FileDropContext.Provider>
  );
}

export function useFileDropContext() {
  const context = useContext(FileDropContext);
  return context;
}
