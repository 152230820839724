import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  ShareIcon as ShareIconSolid,
  UsersIcon as UsersIconSolid,
  BuildingOffice2Icon as BuildingOfficeIconSolid,
} from '@heroicons/react/24/solid';
import { ShareIcon, UsersIcon, BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { ISidebarNavItem } from '../../../../model/ISidebarNavItem';
import { useOrganizationsQuery } from '../../../../../features/Companies/queries/organizationQueries';
import { AppContext } from '../../../../context/context';
import {
  connectionConversationFilter,
  networkConversationFilter,
} from '../../../../filters/filters';
import { ResourceType } from '../../../../model/ResourceType';
import useEntityManifest, { resolvePhotoUrl } from '../../../../hooks/useEntityManifest';
import CompanyNavActions from '../../../../../features/Companies/CompanyNavActions';
import { useMyUserProfileQuery } from '../../../../../features/Profile/queries/userProfileQueries';
import { IOrganizationDto } from '../../../../../features/Companies/models/IOrganizationDto';
import { useMyUserId } from '../../../../auth/accountHooks';
import {
  useTenantQuery,
  useTenantsQuery,
} from '../../../../../features/Companies/queries/tenantQueries';
import { ITenantDto } from '../../../../../features/Companies/models/ITenantDto';
import { profilePhotoURL } from '../../../../utils/configuration';
import { createRoute, isCurrentRouteActive } from '../../../navigation/helpers/routingHelpers';

const staticNavItems: Array<ISidebarNavItem> = [
  {
    name: 'Contacts',
    route: 'contacts',
    icon: UsersIcon,
    activeIcon: UsersIconSolid,
    current: false,
    conversationFilter: connectionConversationFilter,
  },
  {
    name: 'Networks',
    route: 'networks',
    icon: ShareIcon,
    activeIcon: ShareIconSolid,
    current: false,
    conversationFilter: networkConversationFilter,
  },
  {
    name: 'Your Profile',
    route: 'profile',
    icon: BuildingOffice2Icon,
    isMobileOnly: true,
    activeIcon: BuildingOfficeIconSolid,
    current: false,
  },
];

const useMainNavigation = () => {
  const location = useLocation();
  const [sidebarNavItems, setSidebarNavItems] = useState([] as Array<ISidebarNavItem>);
  const { state } = useContext(AppContext);

  const organizationManifest = useEntityManifest(ResourceType.Organization);
  const userTenantManifest = useEntityManifest(ResourceType.Contact);
  const tenantQuery = useTenantsQuery();
  const myProfileQuery = useMyUserProfileQuery();
  const myUserId = useMyUserId();

  const updateMenu = () => {
    const currentRoute = location.pathname.substring(1).toLowerCase();
    // flatten and filter out my own tenant (special handling)
    const flatTenants = (tenantQuery.data?.pages?.flat() || []).filter(
      (item) => item.id !== myUserId,
    );
    const personalTenant = {
      id: myUserId,
      name: myProfileQuery.data?.displayName,
      type: 'User',
      photoUrl: myProfileQuery.data?.photoUrl,
    } as ITenantDto;
    const allOrganizations = [personalTenant].concat(flatTenants);
    const navItems = [
      ...staticNavItems.map((item) => ({
        ...item,
        numberOfUnreadNotifications:
          state.conversations?.length && item.conversationFilter
            ? state.conversations
                ?.filter(item.conversationFilter)
                .filter((m) => m.latestMessage?.isUnread === true).length
            : 0,

        current: currentRoute.startsWith(item.route.toLowerCase()),
      })),
      {
        name: 'My Companies',
        route: '',
        icon: BuildingOffice2Icon,
        activeIcon: BuildingOfficeIconSolid,
        isDisabled: true,
        current: false,
        contentBeforeChildren: <CompanyNavActions />,
        children: allOrganizations.map(({ id, name, type, photoUrl, photoUpdateTime }) => ({
          id,
          name,
          route: createRoute('companies', id),
          current: isCurrentRouteActive('companies', id, location.pathname),
          photoUrl:
            photoUrl ||
            resolvePhotoUrl(ResourceType[type], id, photoUpdateTime || new Date().toISOString()),
          entityManifest: type === 'User' ? userTenantManifest : organizationManifest,
        })),
      },
    ];

    const navItemsWithSelectedParent = navItems.map((item) => {
      const itemCopy = { ...item };

      if (item.children && item.children.some((child) => child.current)) {
        itemCopy.current = true;
      }

      return itemCopy;
    });

    setSidebarNavItems(navItemsWithSelectedParent);
  };

  useEffect(() => {
    if (!tenantQuery.data) return;
    if (!state.conversations) return;
    if (!myProfileQuery.data) return;
    if (!myUserId) return;

    updateMenu();
  }, [tenantQuery.data, location, state.conversations, myProfileQuery.data, myUserId]);

  return sidebarNavItems;
};

export default useMainNavigation;
