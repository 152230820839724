export type TagColor = 'yellow' | 'green' | 'red' | 'total';

const tagColorClassMapping: Record<TagColor, string> = {
  yellow: 'bg-yellow-light text-yellow-dark',
  green: 'bg-green-light text-green-dark',
  red: 'bg-red-light text-red-dark',
  total: '',
};

export type TagProps = {
  title: string;
  color: TagColor;
};

const tagClassName =
  'flex justify-center items-center content-center rounded-full py-0.5 px-2 text-center text-xs font-medium whitespace-nowrap truncate';

export default function Tag({ title, color }: TagProps) {
  return <div className={[tagColorClassMapping[color], tagClassName].join(' ')}>{title}</div>;
}
