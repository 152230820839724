import { LinkIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import {
  useConnectWithUserMutation,
  useConnectionIsInitatedQuery,
} from '../../Request/queries/connectionRequestQueries';
import { useHasConnectionQuery } from '../queries/connectionQueries';
import { useFollowMutation, useIsFollowingQuery } from '../queries/contactQueries';
import { ICardMenuItem } from '../../../shared/components/cards/CardMenuBar';
import { useUserProfileQuery } from '../../Profile/queries/userProfileQueries';

export interface ISocialContactActions {
  menuItems: ICardMenuItem[];
}

//  TODO: Ideally we should only have one hook for general action items.
// I haven't merged the two hooks for legacy reasons.
// The action items should be separated from implementation so there should be no need for either
// Card or Menu-actions, just actions. Actions can be then impmenented
// in the card or menu or any other context.

export default function useUserCardActions(userId: string): ISocialContactActions {
  const profileQuery = useUserProfileQuery(userId);
  const isConnectionInitiated = useConnectionIsInitatedQuery(userId);
  const isConnected = useHasConnectionQuery(userId);
  const isFollowing = useIsFollowingQuery(userId);
  const connectMutation = useConnectWithUserMutation();
  const followMutation = useFollowMutation();

  const handleConnect = () => {
    if (!profileQuery.data) return;

    connectMutation.mutate({
      userId: profileQuery.data.id,
      displayName: profileQuery.data.displayName,
    });
  };

  const connectItem: ICardMenuItem = {
    key: `${userId}-connection`,
    label: 'Connect',
    isDisabled:
      !profileQuery.isSuccess ||
      !isConnectionInitiated.isSuccess ||
      !isConnected.isSuccess ||
      isConnected.data ||
      isConnectionInitiated.data,
    icon: (classes) => <UserPlusIcon className={classes} />,
    onClick: handleConnect,
  };

  const handleFollow = () => {
    if (!profileQuery.data) return;

    followMutation.mutate({
      userId: profileQuery.data.id,
      firstName: profileQuery.data.firstName,
      lastName: profileQuery.data.lastName,
    });
  };
  const followItem: ICardMenuItem = {
    key: `${userId}-follow`,
    label: 'Follow',
    isDisabled: !profileQuery.isSuccess || !isFollowing.isSuccess || isFollowing.data,
    icon: (classes) => <LinkIcon className={classes} />,
    onClick: handleFollow,
  };
  return {
    menuItems: [connectItem, followItem],
  };
}
