import { useParams } from 'react-router';
import { useProjectsQuery } from '../queries/projectQueries';

export default function useProjectNavigation() {
  const { tenantId, projectId } = useParams();
  const projectsQuery = useProjectsQuery({ tenantId });

  if (!projectsQuery.isSuccess) {
    return { items: [] };
  }

  const projects = projectsQuery.data.data.filter(
    (p) => p.tenantId === tenantId && p.id !== projectId,
  );

  if (projects.length === 0) {
    return { items: [] };
  }

  const items = projects.map((project) => ({
    id: project.id,
    name: project.name,
    url: `/companies/${tenantId}/projects/${project.id}`,
  }));

  return { items };
}
