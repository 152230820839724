// Can this be integrated with our entity manifest and our notifications?
// Connected to SidePanelLayout and our Routes, perhaps it can be
// even more centralized and connected to where we create our tabs
const tabsByResource = {
  contacts: ['info', 'notes', 'activities', 'attachments', 'share'] as const,
  networks: ['info', 'members', 'activities', 'attachments'] as const,
  companies: ['info'] as const,
};

// Extract the resource types
type Resource = keyof typeof tabsByResource;

// Create a type that maps each resource to its valid tabs
type TabsByResource = {
  [R in Resource]: (typeof tabsByResource)[R][number];
};

// Define a generic type that gets the valid tabs for a given resource
type TabForResource<R extends Resource> = TabsByResource[R];

export function createRoute<R extends Resource>(
  resource: R,
  resourceId: string,
  tab?: TabForResource<R>,
): string {
  return tab ? `/${resource}/${resourceId}?tab=${tab}` : `/${resource}/${resourceId}`;
}

export function isCurrentRouteActive<R extends Resource>(
  resource: R,
  id: string,
  currentPath: string,
): boolean {
  const pattern = new RegExp(`^/${resource}/${id}(/|$)`, 'i');
  return pattern.test(currentPath);
}
