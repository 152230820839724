import { IPagedResult } from '../../../shared/model/IPagedResult';
import apiClient from '../../../shared/utils/apiClient';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import queryStringBuilder from '../../../shared/utils/query-string-builder/queryStringBuilder';
import { ITenantDto } from '../models/ITenantDto';
import { ITenantMemberDto } from '../models/ITenantMemberDto';

export function getTenant(id: string) {
  return apiClient.get(`v1/tenants/${id}`).then<ITenantDto>((response) => response.data);
}

export function getTenants(...queryParams: QueryParam[]) {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/tenants${queryString}`)
    .then<IPagedResult<ITenantDto>>((response) => response.data);
}

export function getTenantMembers(id: string) {
  return apiClient
    .get<IPagedResult<ITenantMemberDto>>(`v1/tenants/${id}/members`)
    .then((response) => response.data);
}
