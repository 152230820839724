import { ListLoader } from './ListLoader';
import Text from '../text/Text';

interface IProps {
  children: React.ReactNode;
}

export default function ListEmptyState({ children }: IProps) {
  return (
    <div>
      <ListLoader speed={0} />
      <div className="mt-4 text-center">
        <Text as="span" size="small" brightness="light">
          {children}
        </Text>
      </div>
    </div>
  );
}
