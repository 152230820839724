import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { groupBy, uniqBy } from 'lodash';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { ShareContactRequestStatuses } from '../../../shared/model/IShareContactRequestDto';
import {
  acceptShareContactRequest,
  getShareContactRequests,
  rejectShareContactRequest,
} from '../../../shared/services/shareContactRequestService';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { shareContact } from '../../Contacts/services/contactService';
import { contactKeys } from '../../Contacts/queries/contactQueries';

export interface IUseShareContactRequestProps {
  searchType?: 'Sent' | 'Received';
  status?: ShareContactRequestStatuses[];
  sharedContactId?: string;
  targetUserId?: string;
  requestorUserId?: string;
}

const shareContactRequestKeys = {
  all: ['share-contact-request'] as const,
  lists: () => [...shareContactRequestKeys.all, 'list'] as const,
  list: (props: IUseShareContactRequestProps) =>
    [...shareContactRequestKeys.lists(), props] as const,
};

const listShareContactRequestsQueryBase = ({
  targetUserId,
  requestorUserId,
  searchType,
  sharedContactId,
  status,
}: IUseShareContactRequestProps) => ({
  queryKey: shareContactRequestKeys.list({
    targetUserId,
    requestorUserId,
    searchType,
    sharedContactId,
    status,
  }),
  queryFn: () =>
    getShareContactRequests(
      new QueryParam('sharedContactId', sharedContactId),
      new QueryParam('searchType', searchType),
      new QueryParam('targetUserId', targetUserId),
      new QueryParam('requestorUserId', requestorUserId),
      ...(status?.map((_status) => new QueryParam('status', _status)) ?? []),
    ),
});

export function useListShareContactRequestsQuery(props: IUseShareContactRequestProps) {
  return useQuery(listShareContactRequestsQueryBase(props));
}

export function useShareContactRequestsCountByStatusQuery(props: IUseShareContactRequestProps) {
  return useQuery({
    ...listShareContactRequestsQueryBase(props),
    select: (contacts) => {
      const uniqueContacts = uniqBy(contacts, 'targetUserId');

      const filteredContacts = uniqueContacts.filter(
        (contact) =>
          contact.status === 'Pending' ||
          contact.status === 'Accepted' ||
          contact.status === 'Rejected',
      );
      const groupedContacts = groupBy(filteredContacts, 'status');

      return {
        Total: filteredContacts.length,
        Pending: (groupedContacts.Pending || []).length || 0,
        Accepted: (groupedContacts.Accepted || []).length || 0,
        Rejected: (groupedContacts.Rejected || []).length || 0,
      };
    },
  });
}

interface IShareContactProps {
  contactId: string;
  targetUserIds: string[];
}

export function useShareContactMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ contactId, targetUserIds }: IShareContactProps) =>
      shareContact(contactId, targetUserIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shareContactRequestKeys.lists() });
    },
    onError: dispatchError,
  });
}

interface IShareContactRequestActionProps {
  id: string;
}

export function useAcceptShareContactRequestMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id }: IShareContactRequestActionProps) => acceptShareContactRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shareContactRequestKeys.lists() });
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
    },
    onError: dispatchError,
  });
}

export function useRejectShareContactRequestMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id }: IShareContactRequestActionProps) => rejectShareContactRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shareContactRequestKeys.lists() });
    },
    onError: dispatchError,
  });
}
