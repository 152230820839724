/* eslint-disable @typescript-eslint/no-empty-function */
import { useMatch, useNavigate } from 'react-router-dom';
import { Menu, MessageCircle } from 'lucide-react';
import NetworkCreateEditModal from '../NetworkCreateEditModal';
import useModal from '../../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../../shared/model/IActionItem';
import AddIcon from '../../../shared/components/icons/AddIcon';

export function useNetworksActions(): IActionItem[] {
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const networksPathMatch = useMatch({ path: '/networks/*' });
  const networksListPathMatch = useMatch({ path: '/networks/list' });

  const handleCreateNetworkSuccess = (networkId: string) => {
    navigate(networkId);
    closeModal();
  };

  return [
    {
      id: 'networks',
      title: 'Networks',
      type: 'header',
    },
    {
      id: 'create',
      title: 'Add network',
      icon: AddIcon,
      addDividerAfter: true,
      onClick: () => {
        openModal({
          title: 'Add network',
          content: (
            <NetworkCreateEditModal onSuccess={handleCreateNetworkSuccess} onCancel={closeModal} />
          ),
        });
      },
    },
    {
      id: 'view',
      title: 'Change view',
      type: 'header',
    },
    networksPathMatch !== null && networksListPathMatch
      ? {
          id: 'social',
          title: 'Social view',
          icon: MessageCircle,
          isActive: networksPathMatch !== null && networksListPathMatch === null,
          onClick: async () => navigate('/networks'),
        }
      : {
          id: 'list',
          title: 'List view',
          icon: Menu,
          isActive: networksListPathMatch !== null,
          onClick: async () => navigate('/networks/list'),
        },
  ];
}
