import { useInfiniteApiQuery } from '../../../hooks/useInfiniteApiQuery';
import { getAttachments } from '../../../services/conversationAttachmentService';

interface IAttachmentProps {
  conversationId: string;
  searchTerm?: string;
}

export const attachmentKeys = {
  all: ['conversation-attachments'] as const,
  pagedLists: () => [...attachmentKeys.all, 'pagedList'] as const,
  pagedList: (props: IAttachmentProps) => [...attachmentKeys.pagedLists(), props] as const,
};

export function useAttachmentsQuery(props: IAttachmentProps) {
  return useInfiniteApiQuery({
    queryKey: attachmentKeys.pagedList(props),
    queryFn: () => getAttachments(props.conversationId, props.searchTerm),
  });
}
