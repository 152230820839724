import { FieldValues, Message, Path, UseFormRegister, ValidationRule } from 'react-hook-form';
import { CustomValidationErrorMessage } from './ValidationErrorMessage';
import Label from '../../text/Label';

interface IProps<T extends FieldValues> {
  labelText?: string;
  name: Path<T>;
  placeholder?: string;
  rows?: number;
  register: UseFormRegister<T>;
  errors?: any;
  errorMessage?: string;
  validationRules?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
  }>;
  useCustomBorder?: boolean;
  className?: string;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onInputBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
}

export default function TextAreaInput<T extends object>({
  labelText,
  name,
  placeholder,
  register,
  rows = 3,
  errors = null,
  errorMessage = '',
  validationRules = {},
  useCustomBorder,
  className,
  onKeyUp,
  onKeyDown,
  onInputBlur,
}: IProps<T>) {
  const { onBlur, ...registration } = register(name, validationRules);
  return (
    <>
      {(errors !== null || labelText?.length) && (
        <Label
          htmlFor={name}
          text={labelText as string}
          extra={
            <>
              {errors !== null && errors[name] && (
                <CustomValidationErrorMessage message={errorMessage} />
              )}
            </>
          }
        />
      )}
      <textarea
        onBlur={(e) => {
          if (onInputBlur) {
            onInputBlur(e);
          }
          onBlur(e);
        }}
        {...registration}
        id={name}
        rows={rows}
        className={`${
          useCustomBorder
            ? 'border-none resize-none border-b-1 border-b-gray-300 focus:ring-transparent focus:border-transparent'
            : ''
        }   scrollbar-hide placeholder:text-gray-400 shadow-sm focus:ring-0 focus:border-zinc-400 block w-full sm:text-sm border-gray-300 rounded-md ${
          errors !== null && errors[name]
            ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
            : ''
        } ${className || ''}`}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        style={{ resize: 'none' }}
      />
    </>
  );
}
