import { IConversationLinkDto } from '../model/IConversationLinkDto';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export const createLink = (conversationId: string, location: string) =>
  apiClient
    .post(`v1/conversations/${conversationId}/links`, { location })
    .then<IConversationLinkDto>((result) => result.data);

export const renameLink = (conversationId: string, linkId: string, name: string) =>
  apiClient
    .post(`v1/conversations/${conversationId}/links/${linkId}/rename`, { name });

export const getLinks = (conversationId: string, searchTerm?: string) => {
  const queryString = queryStringBuilder()
    .add({ name: 'name', value: searchTerm })
    .toQueryString();
  return apiClient
    .get(`v1/conversations/${conversationId}/links${queryString}`)
    .then<IPagedResult<IConversationLinkDto>>((response) => response.data);
};

export const deleteLink = (conversationId: string, linkId: string) =>
  apiClient.delete(`v1/conversations/${conversationId}/links/${linkId}`);
