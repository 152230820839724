import { Suspense, useRef } from 'react';

// temp solution
type ImageMaxHeight = 'max-h-80';

interface IProps {
  src: string;
  alt: string;
  maxHeight?: ImageMaxHeight;
}

const imageCache = new Set();

function useSuspenseImage(src: string) {
  if (!imageCache.has(src)) {
    throw new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        imageCache.add(src);
        resolve(null);
      };
    });
  }
}

function LazyImage({
  alt,
  className,
  imageRef,
  src,
}: {
  alt: string;
  className: string | null;
  imageRef: { current: null | HTMLImageElement };
  src: string;
}): JSX.Element {
  useSuspenseImage(src);
  return <img className={className || undefined} src={src} alt={alt} ref={imageRef} />;
}

export default function ImageComponent({ src, alt, maxHeight }: IProps): JSX.Element {
  const imageRef = useRef<null | HTMLImageElement>(null);
  const className = maxHeight || null;

  return (
    <Suspense fallback={null}>
      <div>
        <LazyImage className={className} src={src} alt={alt} imageRef={imageRef} />
      </div>
    </Suspense>
  );
}
