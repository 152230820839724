import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { To } from 'react-router';
import { IColorSetting } from '../../../hooks/useEntityManifest';
import { IActionItem } from '../../../model/IActionItem';
import { ViewName } from '../../../store/slices/viewSlice';
import { useGlobalStore } from '../../../store/useGlobalStore';
import ProfileAvatar from '../../avatar/ProfileAvatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../drop-down-menu/DropdownMenu';
import { renderDropdownMenuItems } from '../../drop-down-menu/utils';
import { HeaderMobileDetail } from '../application/HeaderMobileDetail';
import SidePanel from '../building-blocks/SidePanel';
import SidePanelHeadline from '../building-blocks/SidePanelHeadline';
import TabDropdownItem from '../../tabs/TabDropdownItem';
import TabContent from '../../tabs/TabContent';
import TabContentWrapper from '../../tabs/TabContentWrapper';
import TabHeaderWrapper from '../../tabs/TabHeaderWrapper';
import TabNavItem from '../../tabs/TabNavItem';
import TabWrapper from '../../tabs/TabWrapper';
import useSearchParamsTabNavigation from '../../tabs/hooks/useSearchParamsTabNavigation';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

export interface ISidePanelLayoutContent {
  id: string;
  title: string;
  renderContent: () => React.JSX.Element;
  /** Only the first item with 'main' will be rendered. Defaults to 'tab' */
  desktopRenderTarget?: 'main' | 'tab' | 'menu' | 'none';
  /** Only the first item with 'headerLink' will be rendered. Defaults to 'tab' */
  mobileRenderTarget?: 'tab' | 'menu' | 'headerLink' | 'none';
}

export interface ISidePanelLayoutAction {
  id: string;
  title: string;
  icon?: React.ElementType;
  disabled?: boolean;
  onClick: () => void;
}

interface ISidePanelLayoutProps {
  title: string;
  subTitle: string;
  imgSource: string;
  imgAlt: string;
  colorSetting: IColorSetting;
  sidePanelViewSettingName: ViewName;
  /**
   * These are the feature components that are rendered in either
   * the main content area or in the tabs. In mobile view all features
   * are rendered in tabs, but there is a special case for one feature
   * which can be reached from the header.
   */
  content: ISidePanelLayoutContent[];
  /** Actions showed in the menu on the tab view of the sidebar */
  actions: IActionItem[];
  /** Actions showed in the header while in mobile view. */
  mobileHeaderActions?: (ISidePanelLayoutAction & { icon: React.ElementType })[];
  /** Should be an id of a content object. Otherwise will do nothing. */
  defaultTab?: string;
}

interface ISidePanelLayoutTabsContentProps
  extends Pick<ISidePanelLayoutProps, 'content' | 'actions' | 'defaultTab'> {
  isMobile: boolean;
}

function getHeaderNavigationTo(content?: ISidePanelLayoutContent): To | undefined {
  if (!content) return undefined;
  return { pathname: '.', search: `?tab=${content.id}` };
}

function SidePanelLayoutMainContent({ content }: Pick<ISidePanelLayoutProps, 'content'>) {
  const rendderMainContent =
    content.find((c) => c.desktopRenderTarget === 'main')?.renderContent ?? (() => <div />);

  return rendderMainContent();
}

function SidePanelLayoutTabsContent({
  isMobile,
  defaultTab,
  content,
  actions,
}: ISidePanelLayoutTabsContentProps) {
  const actualDefaultTab =
    defaultTab ??
    content.find(
      ({ desktopRenderTarget = 'tab', mobileRenderTarget = 'tab' }) =>
        (isMobile && mobileRenderTarget === 'tab') || (!isMobile && desktopRenderTarget === 'tab'),
    )?.id ??
    '';
  const [activeTab, setActiveTab] = useSearchParamsTabNavigation(actualDefaultTab);

  const contentToRender = isMobile
    ? content
    : content.filter(({ desktopRenderTarget = 'tab' }) => desktopRenderTarget !== 'main');
  const tabItems = contentToRender.filter(
    ({ desktopRenderTarget = 'tab', mobileRenderTarget = 'tab' }) =>
      (isMobile && mobileRenderTarget === 'tab') || (!isMobile && desktopRenderTarget === 'tab'),
  );
  let menuItems: IActionItem[] = contentToRender
    .filter(
      ({ desktopRenderTarget = 'tab', mobileRenderTarget = 'tab' }) =>
        (isMobile && mobileRenderTarget === 'menu') ||
        (!isMobile && desktopRenderTarget === 'menu'),
    )
    .map(({ id, title }) => ({ id, title, onClick: () => setActiveTab(id) }));
  if (menuItems.length > 0) menuItems[menuItems.length - 1].addDividerAfter = true;
  menuItems = menuItems.concat(actions);

  return (
    <TabWrapper>
      <TabHeaderWrapper>
        {tabItems.map(({ id, title }) => (
          <TabNavItem
            key={id}
            id={id}
            activeId={activeTab}
            setActiveTab={setActiveTab}
            title={title}
          />
        ))}

        {menuItems.length > 0 && (
          <TabDropdownItem
            isActive={menuItems.findIndex(({ id }) => activeTab === id) !== -1}
            menuItems={menuItems}
          />
        )}
      </TabHeaderWrapper>
      <TabContentWrapper>
        {contentToRender.map(({ id, renderContent }) => (
          <TabContent key={id} id={id} activeTabId={activeTab}>
            {renderContent()}
          </TabContent>
        ))}
      </TabContentWrapper>
    </TabWrapper>
  );
}

function ClosedSidePanel({
  imgSource,
  imgAlt,
  actions,
}: Pick<ISidePanelLayoutProps, 'imgSource' | 'imgAlt' | 'actions'>) {
  return (
    <>
      <ProfileAvatar
        avatarProps={{
          src: imgSource,
          alt: imgAlt,
          widthClass: 'w-12',
          heightClass: 'h-12',
        }}
      />
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisHorizontalIcon className="w-8 h-8 text-white" />
        </DropdownMenuTrigger>
        <DropdownMenuContent>{renderDropdownMenuItems(actions)}</DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}

// Uses split layout with a side panel on the right side in desktop view.
// For mobile view its a single content area and the main content is displayed in the first tab.
export function SidePanelLayout({
  title,
  subTitle,
  imgSource,
  imgAlt,
  colorSetting,
  sidePanelViewSettingName,
  content,
  actions,
  mobileHeaderActions,
  defaultTab,
}: ISidePanelLayoutProps) {
  const isMobile = useCheckMobileScreen();
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const { isSidePanelOpen, name: viewName } = getViewSettings(sidePanelViewSettingName);

  return (
    <div className="flex flex-col md:flex-row h-full w-full overflow-hidden">
      {isMobile && (
        <>
          <HeaderMobileDetail
            title={title}
            subTitle={subTitle}
            avatarPhotoUrl={imgSource}
            avatarAlt={imgAlt}
            headerNavigationTo={getHeaderNavigationTo(
              content.find(({ mobileRenderTarget }) => mobileRenderTarget === 'headerLink'),
            )}
            actions={mobileHeaderActions?.map((action) => ({ ...action, key: action.id }))}
          />
          <SidePanelLayoutTabsContent
            isMobile={isMobile}
            content={content}
            actions={actions}
            defaultTab={defaultTab}
          />
        </>
      )}
      {!isMobile && (
        <>
          <section className="flex-1 overflow-hidden">
            <SidePanelLayoutMainContent content={content} />
          </section>
          <SidePanel
            isOpen={isSidePanelOpen}
            onToggle={() => toggleSidePanel(viewName)}
            openSidePanel={() => setSidePanelOpen(viewName, true)}
            closeSidePanel={() => setSidePanelOpen(viewName, false)}
            colorSetting={colorSetting}
            renderClosed={() => (
              <ClosedSidePanel imgSource={imgSource} imgAlt={imgAlt} actions={actions} />
            )}
          >
            <SidePanelHeadline
              headline={title}
              subHeadline={subTitle}
              imageSrc={imgSource}
              colorSetting={colorSetting}
            />
            <SidePanelLayoutTabsContent
              isMobile={isMobile}
              content={content}
              actions={actions}
              defaultTab={defaultTab}
            />
          </SidePanel>
        </>
      )}
    </div>
  );
}
