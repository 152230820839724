import { useEffect, useState, useContext, useRef } from 'react';
import {
  IMessageFileDto,
  IMessageLinkDto,
  IMessageMetaDataDto,
  IMessageDto,
  IUpdateMessageDto,
} from '../../shared/model/IMessageDto';
import MessagesList from './MessagesList';
import { getFileLease, uploadFile } from '../../shared/services/conversationFileService';
import { downloadUrlByLink } from '../../shared/utils/fileUtils';
import {
  createConversationMessage,
  setConversationRead,
} from '../../shared/services/conversationService';
import {
  UseMessagesQueryResult,
  useMessageSubscription,
  useUpdateMessageMutation,
} from './queries/messageQueries';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { useCreateFileAttachmentMutation } from '../../shared/components/attachments/queries/fileAttachmentQueries';
import { useCreateLinkAttachmentMutation } from '../../shared/components/attachments/queries/linkAttachmentQueries';
import { MessageEditor, MessageEditorRefType } from './editor/MessageEditor';
import { FileDropContainer } from '../../shared/components/file/FileDropContainer';
import { useMessageMentions } from './messagingUtils';
import {
  IMessageSubmitProps,
  LinkExport,
  MentionExport,
  ReplyToMessageExport,
} from './editor/useMessageSubmitCommand';
import { MessageEditorActionProps } from './editor/MessageEditorActionMenu';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import { IConversationDto } from '../../shared/model/IConversationDto';
import { useToast } from '../../shared/components/toasts/use-toast';

interface IProps {
  conversation: IConversationDto;
  messages: UseMessagesQueryResult;
}

function Messaging({
  conversation,
  messages,
  externalMenuActions,
}: IProps & MessageEditorActionProps) {
  const [files, setFiles] = useState([] as File[]);
  const editorRef = useRef<MessageEditorRefType>(null);
  const [responseToMessage, setResponseToMessage] = useState<IMessageDto>();
  const { toast } = useToast();
  const myUserId = useMyUserId();

  const createFileAttachmentMutation = useCreateFileAttachmentMutation();
  const createLinkAttachmentMutation = useCreateLinkAttachmentMutation();
  const updateMessageMutation = useUpdateMessageMutation();

  const isMobile = useCheckMobileScreen();

  const borderClass = isMobile ? 'border-none' : 'border';

  const { createShareContactRequests, processMessageMentions } = useMessageMentions({
    conversation,
  });

  useEffect(() => {
    if (!conversation) return;
    setConversationRead(conversation.id);
  }, [conversation?.id]);

  useMessageSubscription(conversation?.id);

  const uploadFiles = async (messageFiles: IMessageFileDto[]) => {
    if (messageFiles.length === 0) return;

    const promises = messageFiles.map((messageFile) =>
      uploadFile(
        conversation.id,
        messageFile.fileId,
        messageFile.contentType as string,
        messageFile.file as File,
        ({ loadedBytes }: { loadedBytes: number }) => {
          if (!messageFile.file || messageFile.file.size === 0) return;

          const currentProgress = (loadedBytes / messageFile.file.size) * 100;
          messages.setFileUploadProgress(messageFile.fileId, currentProgress);

          if (currentProgress === 100) {
            setTimeout(() => messages.setFileUploadProgress(messageFile.fileId, undefined), 500);
          }
        },
      ).catch(() => {
        toast({
          title: 'Error',
          variant: 'destructive',
          description: `File '${messageFile.name}' could not be uploaded!`,
        });
      }),
    );

    const uploadResults = await Promise.all(promises);
    if (uploadResults.length === 1 && uploadResults[0] !== undefined) {
      toast({
        title: 'Success',
        variant: 'success',
        description: `File '${uploadResults[0].name}' uploaded!`,
      });
    } else {
      const successCount = uploadResults.filter((result) => result !== undefined).length;

      toast({
        title: 'Success',
        variant: 'success',
        description: `${successCount} files uploaded!`,
      });
    }
  };

  const createMessage = async (
    text: string,
    _responseToMessage?: ReplyToMessageExport,
    mentions?: MentionExport[],
    links?: LinkExport[],
  ) => {
    if (!files.length && !text.length) return;
    if (!messages.data) return;

    const filesMetadata =
      files.length > 0
        ? files.map((file, index) => ({
            fileId: `TempAttachmentId_${index}`,
            name: file.name,
            file,
          }))
        : undefined;

    const linksMetadata =
      links && links.length > 0
        ? links.map((link, index) => ({
            linkId: `TempLinkId_${index}`,
            location: link.url,
          }))
        : undefined;
    let metaData: IMessageMetaDataDto | undefined;
    if (filesMetadata) {
      metaData = metaData || {};
      metaData.files = filesMetadata;
    }
    if (linksMetadata) {
      metaData = metaData || {};
      metaData.links = linksMetadata;
    }
    if (_responseToMessage) {
      metaData = metaData || {};
      metaData.responseToMessage = {
        id: _responseToMessage.id,
        text: _responseToMessage.text,
      };
    }

    const { sharedContactsMetaData, newShareContactMentions, mentionsMetaData } =
      await processMessageMentions(mentions);
    if (sharedContactsMetaData && sharedContactsMetaData.length > 0) {
      metaData = metaData || {};
      metaData.sharedContacts = sharedContactsMetaData;
    }
    if (mentionsMetaData && mentionsMetaData.length > 0) {
      metaData = metaData || {};
      metaData.mentions = mentionsMetaData;
    }

    // Create optimistic message
    const newMessage: IMessageDto = {
      id: 'TempMessageId',
      text,
      senderUserId: myUserId ?? '',
      createdTime: new Date(Date.now()).toISOString(),
      updatedTime: '',
      isUnread: false,
      dividerHeadline: undefined,
      reactions: [],
      metaData,
    };

    // Add to messages
    messages.add(newMessage);

    // Send to backend (everything below)
    if (
      newMessage.metaData &&
      newMessage.metaData.files &&
      newMessage.metaData.files.length !== 0
    ) {
      // Create file attachments
      const createFilePromises = newMessage.metaData.files.map((file) =>
        createFileAttachmentMutation
          .mutateAsync({ conversationId: conversation.id, name: file.name })
          .then((createdAttachment) => ({
            id: createdAttachment.id,
            tempId: file.fileId,
            contentType: createdAttachment.contentType,
          })),
      );
      const createFileResults = await Promise.all(createFilePromises);

      newMessage.metaData.files = newMessage.metaData?.files.map((file) => {
        const createFileResult = createFileResults.find((res) => res.tempId === file.fileId);
        if (!createFileResult) return file; // should not happen
        return {
          ...file,
          fileId: createFileResult.id,
          contentType: createFileResult.contentType,
        } as IMessageFileDto;
      });

      // Start upload
      uploadFiles(newMessage.metaData.files);
    }

    if (
      newMessage.metaData &&
      newMessage.metaData.links &&
      newMessage.metaData.links.length !== 0
    ) {
      // Create link
      const createLinksPromises = newMessage.metaData.links.map((link) =>
        createLinkAttachmentMutation
          .mutateAsync({ conversationId: conversation.id, location: link.location })
          .then((createdLink) => ({
            id: createdLink.id,
            tempId: link.linkId,
            location: createdLink.location,
          })),
      );
      const createLinkResults = await Promise.all(createLinksPromises);

      newMessage.metaData.links = newMessage.metaData?.links.map((link) => {
        const createLinkResult = createLinkResults.find((res) => res.tempId === link.linkId);
        if (!createLinkResult) return link; // should not happen
        return {
          ...link,
          linkId: createLinkResult.id,
          location: createLinkResult.location,
        } as IMessageLinkDto;
      });
    }

    // Create message
    await createConversationMessage(conversation.id, newMessage as IMessageDto).then(
      (createdMessage) => {
        messages.update(createdMessage, newMessage.id);
      },
    );

    // Create share contact requests
    if (newShareContactMentions && newShareContactMentions.length > 0) {
      createShareContactRequests(newShareContactMentions);
    }

    await setConversationRead(conversation.id);

    // TODO remove on failure (stop uploads, remove message, remove any attachments etc.)
  };

  const handleUpdateMessage = async (
    message: IMessageDto,
    { messageText, mentions }: IMessageSubmitProps,
  ) => {
    if (conversation.id !== message.conversationId) return;
    if (!messageText) return;

    let metaData = message.metaData ? { ...message.metaData } : undefined;
    const { sharedContactsMetaData, newShareContactMentions } = await processMessageMentions(
      mentions,
      metaData?.sharedContacts,
    );
    if (metaData || sharedContactsMetaData) {
      metaData = metaData ?? {};
      metaData.sharedContacts = sharedContactsMetaData;
    }

    const updateMessageClone: IUpdateMessageDto = {
      text: messageText,
      metaData,
    };

    updateMessageMutation.mutate({
      conversationId: message.conversationId as string,
      messageId: message.id as string,
      message: updateMessageClone,
    });

    if (newShareContactMentions && newShareContactMentions.length > 0) {
      createShareContactRequests(newShareContactMentions);
    }
  };

  const sendMessage = async ({ messageText, replyTo, mentions, links }: IMessageSubmitProps) => {
    await createMessage(messageText, replyTo, mentions, links);
    setFiles([]);
    setResponseToMessage(undefined);
  };

  const handleReplyToMessage = (messageToReply: IMessageDto) => {
    setResponseToMessage(messageToReply);
    if (editorRef.current) editorRef.current.focus();
  };

  const handleFileDownload = (file: IMessageFileDto) => {
    getFileLease(conversation.id, file.fileId).then(({ url }) => {
      downloadUrlByLink(url, file.name);
    });
  };

  return (
    <FileDropContainer
      className="flex flex-col h-full p-0 md:mr-4 relative"
      onFilesChanged={setFiles}
    >
      <div className={`flex-1 overflow-y-hidden  rounded-lg ${borderClass}`}>
        <MessagesList
          messages={messages.data?.pages.flat()}
          hasNextPage={messages.hasNextPage}
          fetchNextPage={messages.fetchNextPage}
          onFileClick={handleFileDownload}
          onReplyToMessage={handleReplyToMessage}
          onUpdateMessage={handleUpdateMessage}
        />
      </div>
      <div>
        <MessageEditor
          ref={editorRef}
          conversationId={conversation.id}
          onSubmit={sendMessage}
          replyToMessage={responseToMessage}
          onReplyToMessageChanged={setResponseToMessage}
          externalMenuActions={externalMenuActions}
        />
      </div>
    </FileDropContainer>
  );
}

export default Messaging;
