import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ViewName } from '../../shared/store/slices/viewSlice';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import SidePanel from '../../shared/components/layout/building-blocks/SidePanel';
import SidePanelHeadline from '../../shared/components/layout/building-blocks/SidePanelHeadline';
import { useOrganizationQuery } from './queries/organizationQueries';
import { IOrganizationDto } from './models/IOrganizationDto';
import useCompanyActions from './hooks/useCompanyActions';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import Header2 from '../../shared/components/headers/Header2';
import Text from '../../shared/components/text/Text';
import useSearchParamsTabNavigation from '../../shared/components/tabs/hooks/useSearchParamsTabNavigation';

function OrganizationClosedSidePanel({ tenantId }: { tenantId: string }) {
  const resourceManifest = useEntityManifest(ResourceType.Organization);
  const { data: company } = useOrganizationQuery(tenantId);
  return (
    <ProfileAvatar
      avatarProps={{
        src: company?.photoUrl || resourceManifest.icon,
        widthClass: 'w-14',
        heightClass: 'h-14',
      }}
    />
  );
}

interface IOrganizationProps {
  organization: IOrganizationDto;
}

function Organization({ organization }: IOrganizationProps) {
  const [activeTab, setActiveTab] = useSearchParamsTabNavigation('info');

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate('/companies');
  };

  const { items: menuItems } = useCompanyActions(handleNavigateBack);
  const hasDescription = !!organization?.description;
  return (
    <TabWrapper>
      <TabHeaderWrapper>
        <TabNavItem
          id="info"
          activeId={activeTab}
          title="Info"
          setActiveTab={() => setActiveTab('info')}
        />

        {menuItems.length > 0 && <TabDropdownItem isActive={false} menuItems={menuItems} />}
      </TabHeaderWrapper>
      <TabContentWrapper>
        <TabContent id="info" activeTabId={activeTab}>
          <FlexContainer direction="column" gap="small" align="start">
            <Header2>Overview</Header2>
            {hasDescription && <Text size="small">{organization.description}</Text>}
            {!hasDescription && (
              <Text size="small" variant="italic">
                No description available...
              </Text>
            )}
          </FlexContainer>
        </TabContent>
      </TabContentWrapper>
    </TabWrapper>
  );
}

export default function OrganizationSidePanel() {
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const currentView: ViewName = 'organization';
  const viewSettings = getViewSettings(currentView);
  const resourceType = ResourceType.Organization;
  const resourceManifest = useEntityManifest(resourceType);
  const params = useParams();
  const { tenantId } = params;
  const organizationQuery = useOrganizationQuery(tenantId as string);

  useEffect(() => {
    setSidePanelOpen(currentView, true);
  }, [tenantId]);

  if (!organizationQuery.isSuccess) {
    return <></>;
  }

  const company = organizationQuery.data;

  return (
    <>
      {tenantId && (
        <SidePanel
          renderClosed={() => <OrganizationClosedSidePanel tenantId={tenantId} />}
          colorSetting={resourceManifest.color}
          isOpen={viewSettings.isSidePanelOpen}
          openSidePanel={() => setSidePanelOpen(currentView, true)}
          closeSidePanel={() => setSidePanelOpen(currentView, false)}
          onToggle={() => toggleSidePanel(currentView)}
        >
          <SidePanelHeadline
            headline={company.name}
            colorSetting={resourceManifest.color}
            imageSrc={company.photoUrl || resourceManifest.icon}
          />
          <Organization organization={company} />
        </SidePanel>
      )}
    </>
  );
}
