import { EditorThemeClasses } from 'lexical';
import { LINK } from '@lexical/markdown';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import {
  IMAGE,
  MENTION,
} from '../../../shared/lexical/markdown/LexicalMarkdownTransformers';
import { MentionNode } from '../../../shared/lexical/mentions/MentionNode';
import { ImageNode } from '../../../shared/lexical/images/ImageNode';

// Order matters becuase of the nature of markdown. The first transformer to match will be used.
export const MARKDOWN_TRANSFORMERS = [MENTION, IMAGE, LINK];

const theme: EditorThemeClasses = {
  link: 'anchor',
  autolink: 'anchor',
  mention: 'bg-blue-light',
};

export const MESSAGE_EDITOR_CONFIG = {
  namespace: 'MessageEditor',
  onError: (error: unknown) => {
    throw error;
  },
  nodes: [AutoLinkNode, LinkNode, MentionNode, ImageNode],
  theme,
};
