import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { IPipelineItemDto } from '../models/IPipelineItemDto';
import useSearchParamsTabNavigation from '../../../shared/components/tabs/hooks/useSearchParamsTabNavigation';

type Period = {
  startTime: Date;
  endTime: Date;
};

export type PeriodKey = 'week' | 'month';

const defaultPeriod: Period = {
  startTime: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endTime: endOfWeek(new Date(), { weekStartsOn: 1 }),
};

export const periodTranlationsMappings: { [key in PeriodKey]: string } = {
  week: 'Weekly',
  month: 'Monthly',
};
function calculateKpiPeriod(time: PeriodKey): Period {
  if (time === 'week') {
    return defaultPeriod;
  }

  return { startTime: startOfMonth(new Date()), endTime: endOfMonth(new Date()) };
}

export type SidePanelViewType = 'EditPipeline' | 'EditItem';

export type EditPipelineTabs =
  | 'info'
  | 'members'
  | 'stages'
  | 'budget'
  | 'attachments'
  | 'activities'
  | 'chat';

export interface ActivePipelineSettings {
  pipelineId: string;
  stageId: string;
  item: IPipelineItemDto;
}

interface Store {
  activeSidePanelView: SidePanelViewType;
  setActiveSidePanelView: (view: SidePanelViewType) => void;
  navigateToOverview: () => void;
  selectStage: (stageId: string) => void;
  selectItem: (item: IPipelineItemDto, stageId: string) => void;
  selectPipeline: (pipelineId: string) => void;
  activePipelineSettings: ActivePipelineSettings;
  closeSidebar: () => void;
  activeTab: string;
  setActiveTab: (tab: EditPipelineTabs) => void;
  resetPipelineSettings: () => void;
  isKPIsVisible: boolean;
  setIsKPIsVisible: Dispatch<SetStateAction<boolean>>;
  recalculateValue: (time: PeriodKey) => void;
  kpiPeriodKey: PeriodKey;
  setKpiPeriodKey: Dispatch<SetStateAction<PeriodKey>>;
  kpiPeriod: React.MutableRefObject<Period>;
}

const usePipelineStore = (): Store => {
  const [activeSidePanelView, setActiveSidePanelView] = useState<SidePanelViewType>('EditPipeline');
  const [kpiPeriodKey, setKpiPeriodKey] = useState<PeriodKey>('week');

  const [activeTab, setActiveTab] = useSearchParamsTabNavigation('info');

  const [isKPIsVisible, setIsKPIsVisible] = useState<boolean>(true);

  const kpiPeriod = useRef<Period>(defaultPeriod);

  const defaultPipelineSettings = {
    stageId: '',
    pipelineId: '',
    item: {} as IPipelineItemDto,
  };

  const [activePipelineSettings, setActivePipelineSettings] =
    useState<ActivePipelineSettings>(defaultPipelineSettings);

  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const recalculateValue = (time: PeriodKey) => {
    kpiPeriod.current = calculateKpiPeriod(time);
    setKpiPeriodKey(time);
  };

  const navigateToOverview = () => {
    navigate('/boards');
    setActivePipelineSettings(defaultPipelineSettings);
  };

  const selectStage = (stageId: string) => {
    setActivePipelineSettings((prevState) => ({
      ...prevState,
      stageId,
    }));

    setActiveTab('stages');
    setActiveSidePanelView('EditPipeline');
  };

  const selectPipeline = (pipelineId: string) => {
    setActivePipelineSettings((prevState) => ({
      ...prevState,
      pipelineId,
    }));

    setActiveSidePanelView('EditPipeline');
  };

  const selectItem = (item: IPipelineItemDto, stageId: string) => {
    if (item.id === activePipelineSettings.item.id) {
      setActivePipelineSettings((prevState) => ({
        ...prevState,
        stageId,
        item: {} as IPipelineItemDto,
      }));

      setSearchParams({ tab: 'info' });
      setActiveSidePanelView('EditPipeline');
    } else {
      setActivePipelineSettings((prevState) => ({
        ...prevState,
        stageId,
        item,
      }));

      setSearchParams({ tab: 'info' });
      setActiveSidePanelView('EditItem');
    }
  };

  const resetPipelineSettings = () => {
    setActivePipelineSettings((prevState) => ({
      ...defaultPipelineSettings,
      pipelineId: prevState.pipelineId,
    }));

    setActiveSidePanelView('EditPipeline');
  };

  const closeSidebar = () => {
    setActivePipelineSettings(defaultPipelineSettings);
  };

  return {
    activePipelineSettings,
    activeSidePanelView,
    setActiveSidePanelView,
    navigateToOverview,
    selectStage,
    selectItem,
    selectPipeline,
    closeSidebar,
    activeTab,
    setActiveTab,
    resetPipelineSettings,
    isKPIsVisible,
    setIsKPIsVisible,
    recalculateValue,
    kpiPeriodKey,
    setKpiPeriodKey,
    kpiPeriod,
  };
};

export const PipelineStoreContext = createContext<Store | null>(null);

export function PipelineContextProvider({ children }: { children: ReactNode }) {
  return (
    <PipelineStoreContext.Provider value={usePipelineStore()}>
      {children}
    </PipelineStoreContext.Provider>
  );
}

export const usePipelineStoreContext = (): Store => {
  const store = useContext(PipelineStoreContext);

  if (!store) {
    throw new Error('usePipelineStoreContext must be used within a PipelineContextProvider');
  }

  return store;
};
