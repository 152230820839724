import { QueryKey, useQueryClient } from '@tanstack/react-query';
import {
  getInitalDataPropsFromQueries,
  getInitialDataPropsFromQuery,
} from '../utils/queryClientUtils';

export function useInitialDataPropsFromQuery<TData>(
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) {
  const queryClient = useQueryClient();
  return getInitialDataPropsFromQuery(queryClient, queryKey, filter);
}

export function useInitialDataPropsFromQueries<TData>(
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) {
  const queryClient = useQueryClient();
  return getInitalDataPropsFromQueries(queryClient, queryKey, filter);
}
