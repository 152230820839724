import { useParams } from 'react-router';
import { IDropdownNavigationItem } from '../../../shared/components/navigation/DropdownNavigator';
import { useTenantsQuery } from '../queries/tenantQueries';

export default function useCompanyNavigation() {
  const tenantsQuery = useTenantsQuery();
  const { tenantId } = useParams();

  if (!tenantsQuery.isSuccess) {
    return { items: [] };
  }

  const tenants = tenantsQuery.data?.pages?.flat().filter((tenant) => tenant.id !== tenantId);

  if (tenants.length === 0) {
    return { items: [] };
  }

  const items: IDropdownNavigationItem[] = tenants.map((company) => ({
    id: company.id,
    name: company.name,
    url: `/companies/${company.id}`,
  }));

  return { items };
}
