const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';

export const REGEX_EMAIL = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,9})$/;

export const REGEX_EMOJI = /\p{Emoji}/u;

export const URL_REGEX =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const REGEX_IMAGE_ATTACHMENT = /\.(jpg|jpeg|png|gif)$/i;

// Mentions

const MENTION_TRIGGERS = ['@'].join('');
// Valid chars in a mention. We allow anything that's not a trigger, punctuation, or whitespace.
const MENTION_VALID_CHARS = `[^${MENTION_TRIGGERS}${PUNCTUATION}\\s]`;

// Chars that join together valid chars.
const MENTION_VALID_JOINS = `(?:\\.[ |$]| |[${PUNCTUATION}]|)`;

const MENTION_LENGTH_LIMIT = 75;

export const MENTIONS_REGEX = new RegExp(
  `(^|\\s|\\()([${MENTION_TRIGGERS}]((?:${MENTION_VALID_CHARS}${MENTION_VALID_JOINS}){0,${MENTION_LENGTH_LIMIT}}))$`,
);
