import { BellIcon } from 'lucide-react';
import Text from '../../shared/components/text/Text';
import FlexContainer from '../../shared/components/structure/FlexContainer';

export function EmptyNotificationMessages() {
  return (
    <FlexContainer
      direction="column"
      justify="center"
      align="center"
      className="text-center min-w-0 space-y-4"
    >
      <BellIcon className="h-8 w-8 rounded-2xl mt-6 text-cyan-dark" />
      <Text as="p" brightness="light" breakMode="words" size="small" weight="normal">
        Looks like there are no notifications here yet.
      </Text>
    </FlexContainer>
  );
}
