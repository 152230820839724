import { InfiniteData } from '@tanstack/react-query';
import { IPagedResult } from '../model/IPagedResult';
import { chunkArray } from './arrayUtils';

/**
 * Converts a flat list of items into a structure suitable for infinite data pages.
 *
 * This function takes a flat list of items and restructures it into a format that is compatible
 * with pagination, specifically for use with infinite data structures. It chunks the flat list
 * into arrays of a specified size and then maps these chunks onto the existing pages of data.
 *
 * @template T - The type of the elements in the flat list and the paged data.
 *
 * @param {InfiniteData<IPagedResult<T>>} items - The existing infinite data structure.
 * @param {T[]} flatList - The flat list of items to be converted into paged data.
 * @param {number} pageSize - The size of each page; this determines how the flat list is chunked.
 * @returns {InfiniteData<IPagedResult<T>>} - The modified infinite data structure with the flat
 * list items distributed across its pages.
 *
 */

export default function ConvertFlatListToInfiniteDataPages<T>(
  items: InfiniteData<IPagedResult<T>>,
  flatList: T[],
  pageSize: number,
) {
  const notesChunked = chunkArray<T>(flatList, pageSize);

  return items.pages
    .map((page, index) => {
      const pageClone = { ...page };
      pageClone.data = notesChunked[index];

      return pageClone;
    })
    .filter((page) => page.data);
}
