import { BlockBlobClient } from '@azure/storage-blob';
import { IConversationFileDto } from '../model/IConversationFileDto';
import apiClient from '../utils/apiClient';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';
import { IPagedResult } from '../model/IPagedResult';

export const createFile = (conversationId: string, name: string) =>
  apiClient
    .post(`v1/conversations/${conversationId}/files`, { name })
    .then<IConversationFileDto>((result) => result.data);

const reserveFile = (conversationId: string, fileId: string) =>
  apiClient
    .post(`v1/conversations/${conversationId}/files/${fileId}/reserve`)
    .then<{ url: string }>((result) => result.data);

const finishFile = (conversationId: string, fileId: string) =>
  apiClient
    .post(`v1/conversations/${conversationId}/files/${fileId}/finish`)
    .then<IConversationFileDto>((result) => result.data);

export const renameFile = (conversationId: string, fileId: string, name: string) =>
  apiClient
    .post(`v1/conversations/${conversationId}/files/${fileId}/rename`, { name });

const uploadBlob = (
  url: string,
  contentType: string,
  file: File,
  onProgress?: (progress: { loadedBytes: number }) => void,
) => {
  const client = new BlockBlobClient(url);
  return client.uploadData(file, {
    blobHTTPHeaders: { blobContentType: contentType },
    onProgress,
  });
};

export const uploadFile = async (
  conversationId: string,
  fileId: string,
  contentType: string,
  file: File,
  onProgress?: (progress: { loadedBytes: number }) => void,
) => {
  const { url: uploadUrl } = await reserveFile(conversationId, fileId);
  await uploadBlob(uploadUrl, contentType, file, onProgress);
  return finishFile(conversationId, fileId);
};

export const getFiles = (conversationId: string, searchTerm?: string) => {
  const queryString = queryStringBuilder()
    .add({ name: 'name', value: searchTerm })
    .toQueryString();
  return apiClient
    .get(`v1/conversations/${conversationId}/files${queryString}`)
    .then<IPagedResult<IConversationFileDto>>((response) => response.data);
};

export const getFileLease = (conversationId: string, fileId: string) =>
  apiClient
    .post(`v1/conversations/${conversationId}/files/${fileId}/lease`)
    .then<{ url: string }>((result) => result.data);

export const deleteFile = (conversationId: string, fileId: string) =>
  apiClient.delete(`v1/conversations/${conversationId}/files/${fileId}`);
