import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import {
  IMessageCancelCommandProps,
  MESSAGE_CANCEL_COMMAND,
  useMessageCancelCommand,
} from './useMessageCancelCommand';
import {
  IMessageSubmitCommandProps,
  MESSAGE_SUBMIT_COMMAND,
  useMessageSubmitCommand,
} from './useMessageSubmitCommand';
import IconButton from '../../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../../shared/constants/ButtonColors';

export function MessageEditorEditButtonsPlugin({
  onSubmit,
  onCancel,
}: IMessageSubmitCommandProps & IMessageCancelCommandProps) {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor, true);
  useMessageSubmitCommand({ onSubmit });
  useMessageCancelCommand({ onCancel });
  return (
    <div className="flex flex-row justify-end">
      <IconButton
        onClick={() => editor.dispatchCommand(MESSAGE_SUBMIT_COMMAND, undefined)}
        icon={<CheckIcon className="w-6 h-6" />}
        isDisabled={isEmpty}
        color={ButtonColors.Gray}
      />
      <IconButton
        onClick={() => editor.dispatchCommand(MESSAGE_CANCEL_COMMAND, undefined)}
        icon={<XMarkIcon className="w-6 h-6" />}
        color={ButtonColors.Gray}
      />
    </div>
  );
}
