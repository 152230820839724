import { FilterConfig } from '../../../shared/components/filters/hooks/types';
import { INetworkKeyProps } from '../queries/networkQueries';

export type NetworkFilterState = Pick<INetworkKeyProps, 'type' | 'visibility'>;

export const networkFilterConfig: FilterConfig<NetworkFilterState>[] = [
  {
    key: 'type',
    label: 'Privacy',
    type: 'radio',
    options: [
      { label: 'Public', value: 'Public' },
      { label: 'Private', value: 'Private' },
    ],
  },
];
