import { cn } from '../../lib/utils';
import FlexContainer from '../../shared/components/structure/FlexContainer';

interface INotificationListItemLayoutProps<T extends object> {
  item: T;
  isActive?: boolean;
  useDivider?: boolean;
  renderAvatar: (item: T) => React.ReactNode;
  renderTitle: (item: T) => React.ReactNode;
  renderContent: (item: T) => React.ReactNode;
  renderContentRight?: (item: T) => React.ReactNode;
  renderActions?: (item: T) => React.ReactNode;
}

export function NotificationListItemLayout<T extends object>({
  item,
  isActive,
  useDivider,
  renderAvatar,
  renderTitle,
  renderContent,
  renderContentRight,
  renderActions,
}: INotificationListItemLayoutProps<T>) {
  return (
    <>
      <FlexContainer
        direction="column"
        gap="small"
        className={cn(
          'px-5 py-2  hover:bg-blue-light',
          isActive && 'bg-blue-light',
          useDivider && 'border-b-gray-200 border-b-[1px]',
        )}
      >
        <FlexContainer gap="small">
          <div className="min-w-10">{renderAvatar(item)}</div>
          <FlexContainer direction="column" gap="none" flex={1}>
            <FlexContainer direction="row" align="start" justify="start">
              {renderTitle(item)}
              <div className="ml-auto relative top-[-5px]">
                {renderContentRight && renderContentRight(item)}
              </div>
            </FlexContainer>
            {renderContent(item)}
          </FlexContainer>
        </FlexContainer>
        <div className="text-right">{renderActions && renderActions(item)}</div>
      </FlexContainer>
    </>
  );
}
