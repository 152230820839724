import { parseISO, isDate } from 'date-fns';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import getProfileImageUrl from '../../shared/services/profileImageService';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';
import { ResourceLink } from './ResourceLink';
import Text from '../../shared/components/text/Text';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import DateOnly from '../../shared/components/dates/DateOnly';
import Button from '../../shared/components/buttons/Button';
import { EmptyNotificationMessages } from './EmptyNotificationMessages';
import { UseInfiniteApiQueryResult } from '../../shared/hooks/useInfiniteApiQuery';
import { InfiniteApiQueryScroll } from '../../shared/components/scrolling/InfiniteApiQueryScroll';
import { getInfiniteQueryDataLength } from '../../shared/utils/queryClientUtils';
import { NotificationListItemLayout } from './NotificationListItemLayout';

const DayMillis = 1000 * 3600 * 24;

export function renderDateTime(date: string | Date) {
  let dateClone: Date;

  if (typeof date === 'string') {
    dateClone = parseISO(date);
  } else if (isDate(date)) {
    dateClone = date;
  } else {
    return null;
  }

  const currentDate = new Date();
  const differenceInDays = Math.floor((currentDate.getTime() - dateClone.getTime()) / DayMillis);

  if (differenceInDays < 7) {
    return <TimeAgo date={dateClone} />;
  }

  return <DateOnly date={dateClone} />;
}

interface IProps {
  query: UseInfiniteApiQueryResult<INotificationDto>;
  closeCallback: () => void;
  onExecuteAction: (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => void;
}

function Loader() {
  return (
    <div className="py-4 text-center">
      <Text as="p" size="small">
        Loading...
      </Text>
    </div>
  );
}

export default function NotificationList({ query, closeCallback, onExecuteAction }: IProps) {
  const handleExecuteAction = (notification: INotificationDto, action: Action) =>
    onExecuteAction(action.execute(notification.id), notification, action);

  if (query.isPending) {
    return <Loader />;
  }

  if (query.isSuccess && getInfiniteQueryDataLength(query.data) === 0) {
    return <EmptyNotificationMessages />;
  }

  return (
    <InfiniteApiQueryScroll query={query} loader={<Loader />}>
      {({ data }) => (
        <>
          {data.map((notification) => (
            <NotificationListItemLayout<INotificationDto>
              key={notification.id}
              item={notification}
              isActive={!notification.isRead}
              renderAvatar={(_notification) => (
                <ContactUserProfileLink userId={_notification.payload['source.id']}>
                  <ProfileAvatar
                    avatarProps={{
                      src: getProfileImageUrl(_notification.payload['source.id']),
                      alt: _notification.message,
                      widthClass: 'w-8',
                      heightClass: 'h-8',
                    }}
                  />
                </ContactUserProfileLink>
              )}
              renderTitle={(_notification) => (
                <ContactUserProfileLink
                  userId={_notification.payload['source.id']}
                  className="text-sm font-semibold flex flex-row items-center"
                >
                  {_notification.payload['source.displayName'] || 'Unknown User'}
                </ContactUserProfileLink>
              )}
              renderContent={(_notification) => (
                <>
                  <Text as="p" leading="relaxed" size="xSmall" weight="normal">
                    {_notification.message}
                  </Text>
                  {_notification.type === 'Request' && _notification.isResponded && (
                    <Text as="p" variant="italic" leading="loose" size="xSmall">
                      {_notification.response === 'Accept'
                        ? 'You accepted this request.'
                        : 'You rejected this request.'}
                    </Text>
                  )}
                  {_notification.resourceLink && (
                    <Text as="p" leading="loose" size="xSmall">
                      <ResourceLink
                        key={_notification.id}
                        onClick={closeCallback}
                        {..._notification.resourceLink}
                      />
                    </Text>
                  )}
                </>
              )}
              renderContentRight={(_notification) => (
                <Text as="span" size="xSmall" brightness="light">
                  {renderDateTime(_notification.createdTime)}
                </Text>
              )}
              renderActions={(_notification) => {
                if (
                  !_notification.isResponded &&
                  _notification.actions &&
                  _notification.actions.length > 0
                ) {
                  return (
                    <>
                      {_notification.actions.map((action) => (
                        <Button
                          className="relative left-2"
                          size="small"
                          key={action.text}
                          color={action.type === 'Accept' ? ButtonColors.Blue : ButtonColors.White}
                          text={action.text}
                          disabled={_notification.isResponded}
                          onClick={() => handleExecuteAction(_notification, action)}
                        />
                      ))}
                    </>
                  );
                }
                return <></>;
              }}
              useDivider
            />
          ))}
        </>
      )}
    </InfiniteApiQueryScroll>
  );
}
