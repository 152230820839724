// modalStore.ts
import { StateCreator } from 'zustand';
import { CombinedSlices } from '../useGlobalStore';
import { ButtonColors } from '../../constants/ButtonColors';

interface IConfirmContentProps {
  title: string;
  description?: string;
  actionText: string;
  actionColor?: ButtonColors;
  onAction?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

const defaultConfirmProps: IConfirmContentProps = {
  title: '',
  description: '',
  actionText: '',
  actionColor: ButtonColors.Red,
};

export interface ConfirmSlice {
  confirmContentProps: IConfirmContentProps;
  confirm: {
    isOpen: boolean;
    open: (confirmProps: IConfirmContentProps) => void;
    close: () => void;
  };
}

export const createConfirmSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  ConfirmSlice
> = (set) => ({
  confirm: {
    isOpen: false,
    open: (confirmContentProps) =>
      set((state) => ({
        confirm: { ...state.confirm, isOpen: true },
        confirmContentProps,
      })),
    close: () =>
      set((state) => ({
        confirm: { ...state.confirm, isOpen: false },
        confirmContentProps: defaultConfirmProps,
      })),
  },
  confirmContentProps: defaultConfirmProps,
});

export default createConfirmSlice;
