import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

const useIsBaseRoute = () => {
  const [isBaseRoute, setIsBaseRoute] = useState(true);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      setIsBaseRoute(false);
      return;
    }

    setIsBaseRoute(true);
  }, [params]);

  return isBaseRoute;
};

export default useIsBaseRoute;
