export enum ConversationAttachmentType {
    File = 'File',
    Link = 'Link',
}

export interface IConversationAttachmentDto {
    id: string,
    conversationId: string;
    type: ConversationAttachmentType;
    name: string;
    location: string;
    contentType?: string;
    createdTime: string;
}
