const convertFormattedCurrencyToNumber = (value: number | string) =>
  Number(value.toString().replace(/[^0-9.-]+/g, ''));

export const swedishCurrencyFormatter = (numStr: string | number) => {
  if (numStr === '') return '';
  return new Intl.NumberFormat('sv-SE', {
    maximumFractionDigits: 0,
  }).format(numStr as number);
};

export default convertFormattedCurrencyToNumber;
