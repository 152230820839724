import { forwardRef } from 'react';
import { cn } from '../../../lib/utils';

interface IProps {
  text: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  data?: number;
  useDivider?: boolean;
  onClick?: React.MouseEventHandler<Element>;
}

const MenuItemActionButton = forwardRef<HTMLButtonElement, IProps>(
  ({ text, disabled, icon, data, useDivider, onClick }: IProps, ref) => (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={cn(
        icon ? 'flex flex-row px-1' : 'block px-4',
        'py-2 text-left text-sm text hover:bg-gray-light w-full disabled:text-disabled flex flex-1',
        useDivider && 'border-b border-black border-opacity-5',
      )}
    >
      {icon && <span className="pr-2">{icon}</span>}
      {text}
      {data ? (
        <span className="bg-blue text-white whitespace-nowrap rounded-sm px-1 font-medium text-xs items-center">
          {data}
        </span>
      ) : (
        <span className="hidden" />
      )}
    </button>
  ),
);

export default MenuItemActionButton;
