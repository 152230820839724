import { UserPlusIcon } from 'lucide-react';
import ActivityBadge, { IActivityBadgeProps } from './ActivityBadge';

export type IContactActivityBadgeProps = Omit<IActivityBadgeProps, 'icon' | 'classNames'>;

export default function ContactActivityBadge(props: IContactActivityBadgeProps) {
  return (
    <ActivityBadge
      icon={<UserPlusIcon className="w-4 h-4" stroke="#5B9279" />}
      classNames="bg-green-light"
      {...props}
    />
  );
}
