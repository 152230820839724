import { Grid } from '@giphy/react-components';
import { useLayoutEffect, useRef, useState } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { GifIcon } from '@heroicons/react/24/solid';
import ScrollBarWrapper from '../scrolling/ScrollBarWrapper';
import { giphyApiKey } from '../../utils/configuration';
import SearchBar from '../search/SearchBar';
import { Popover, PopoverContent, PopoverTrigger } from '../popovers/Popover';

const scrollbarWidth = 8; // Vulnerable to change.
const giphyFetchLimit = 10;
const giphyGridInitailWidth = 384;

interface IGifPickerProps {
  onSelect: (gif: { url: string; title: string }) => void;
}

interface IGif {
  title: string;
  images: { original: { url: string } };
}

function Picker({ onSelect }: IGifPickerProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [gridWidth, setGridWidth] = useState(giphyGridInitailWidth);
  const giphyFetchRef = useRef<GiphyFetch>(new GiphyFetch(giphyApiKey));
  const ref = useRef<HTMLDivElement>(null);

  const fetchGifs = (offset: number) =>
    searchTerm.length > 0
      ? giphyFetchRef.current.search(searchTerm, { offset, limit: giphyFetchLimit })
      : giphyFetchRef.current.trending({ offset, limit: giphyFetchLimit });

  const handleSelectGif = (gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();
    const { title } = gif;
    const { url } = gif.images.original;
    onSelect({ url, title });
  };

  useLayoutEffect(() => {
    if (!ref.current) return;
    const { width } = ref.current.getBoundingClientRect();
    setGridWidth(width - scrollbarWidth);
  }, []);

  return (
    <div ref={ref} className="flex flex-col gap-2 h-full w-full">
      <SearchBar searchTerm={searchTerm} onSearchChange={(e) => setSearchTerm(e.target.value)} />

      <ScrollBarWrapper className="max-h-96">
        <Grid
          key={searchTerm}
          columns={2}
          width={gridWidth}
          noLink
          fetchGifs={fetchGifs}
          onGifClick={handleSelectGif}
          hideAttribution
        />
      </ScrollBarWrapper>
      <div>
        <span className="text-sm text-light">Powered by GIPHY</span>
      </div>
    </div>
  );
}

export function GifPicker({ onSelect }: IGifPickerProps) {
  return (
    <Popover>
      <PopoverTrigger>
        <GifIcon className="w-5 h-5" />
      </PopoverTrigger>
      <PopoverContent align="end" sideOffset={10} alignOffset={10} className="h-96 w-96">
        <Picker onSelect={onSelect} />
      </PopoverContent>
    </Popover>
  );
}
