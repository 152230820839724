export function isGuidNull(id: string | undefined) {
  if (id === '00000000-0000-0000-0000-000000000000' || !id) return true;

  return false;
}

export function formatGuidCompact(id: string | undefined) {
  if (!id) {
    return '';
  }

  return id.replaceAll('-', '');
}
