import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import {
  createInvitation,
  getInvitationsRequest,
  resendInvitation,
} from '../../../shared/services/invitationService';
import { InvitationStatus } from '../../../shared/model/IInvitationDto';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { ResourceType } from '../../../shared/model/ResourceType';
import { useToast } from '../../../shared/components/toasts/use-toast';

interface IUseInvitationProps {
  status?: InvitationStatus;
}

const invitationKeys = {
  all: ['invitation'] as const,
  lists: () => [...invitationKeys.all, 'list'] as const,
  list: (props: IUseInvitationProps) => [...invitationKeys.lists(), props] as const,
};

interface ICreateInvitationProps {
  email: string;
  resourceId?: string;
  resourceType?: ResourceType;
}

export function useCreateInvitationMutation() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ email, resourceId, resourceType }: ICreateInvitationProps) =>
      createInvitation(email, resourceId, resourceType),
    onSuccess: (_, { email }) => {
      queryClient.invalidateQueries({ queryKey: invitationKeys.lists() });

      toast({
        title: 'Success',
        variant: 'success',
        description: `An invitation has been sent to ${email}`,
      });
    },
    onError: dispatchError,
  });
}

export function useInvitationsQuery({ status }: IUseInvitationProps) {
  return useQuery({
    queryKey: invitationKeys.list({ status }),
    queryFn: () => getInvitationsRequest(new QueryParam('status', status)),
  });
}

interface IResendInvitationProps {
  id: string;
}

export function useResendInvitationMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id }: IResendInvitationProps) => resendInvitation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: invitationKeys.lists() });
    },
    onError: dispatchError,
  });
}
