import { DefaultErrorText, getApiErrorMessage } from '../utils/apiClientUtils';
import { useToast } from '../components/toasts/use-toast';

export const createErrorToast = (error: unknown, defaultErrorTexts?: DefaultErrorText[]) => {
  const message = getApiErrorMessage(error, defaultErrorTexts) || 'An error occurred';
  return {
    title: 'Error',
    description: message,
  };
};

export function useDispatchApiError(defaultErrorTexts?: DefaultErrorText[]) {
  const { toast } = useToast();

  return (error: unknown) =>
    toast({ ...createErrorToast(error, defaultErrorTexts), variant: 'destructive' });
}
