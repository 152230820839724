import { Menu, MessageCircle } from 'lucide-react';
import { useMatch, useNavigate } from 'react-router';
import AddIcon from '../../../shared/components/icons/AddIcon';
import useModal from '../../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../../shared/model/IActionItem';
import { ContactCreateModal } from '../ContactCreateModal';

export function useContactsActions(): IActionItem[] {
  const { openModal } = useModal();

  const navigate = useNavigate();
  const contactsPathMatch = useMatch({ path: '/contacts/*' });
  const contactsListPathMatch = useMatch({ path: '/contacts/list' });

  return [
    {
      id: 'resource',
      title: 'Contacts',
      type: 'header',
    },
    {
      id: 'create',
      title: 'Add contact',
      icon: AddIcon,
      onClick: () => {
        openModal({ content: <ContactCreateModal /> });
      },
    },
    {
      id: 'view',
      title: 'Change view',
      type: 'header',
    },
    contactsPathMatch !== null && contactsListPathMatch
      ? {
          id: 'social',
          title: 'Social view',
          icon: MessageCircle,
          isActive: contactsPathMatch !== null && contactsListPathMatch === null,
          onClick: async () => navigate('/contacts'),
        }
      : {
          id: 'list',
          title: 'List view',
          icon: Menu,
          isActive: contactsListPathMatch !== null,
          onClick: async () => navigate('/contacts/list'),
        },
  ];
}
