import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { InviteRequestType } from '../models/InviteRequestType';
import { deleteInviteRequest, getInviteRequest } from '../services/inviteRequestService';

interface IUseBoardInviteRequestProps {
  type?: 'Sent' | 'Received';
  status?: ConnectionRequestStatuses;
}

const boardInviteRequestKeys = {
  all: ['board-invite-request'] as const,
  lists: () => [...boardInviteRequestKeys.all, 'list'] as const,
  list: (props: IUseBoardInviteRequestProps) => [...boardInviteRequestKeys.lists(), props] as const,
};

export function useBoardInviteRequestsQuery({ type, status }: IUseBoardInviteRequestProps) {
  return useQuery({
    queryKey: boardInviteRequestKeys.list({ type, status }),
    queryFn: () =>
      getInviteRequest(
        InviteRequestType.Board,
        new QueryParam('type', type),
        new QueryParam('status', status),
      ),
  });
}

interface IDeleteBoardInviteRequestProps {
  id: string;
}

export function useDeleteBoardInviteRequestMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id }: IDeleteBoardInviteRequestProps) =>
      deleteInviteRequest(InviteRequestType.Board, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: boardInviteRequestKeys.lists() });
    },
    onError: dispatchError,
  });
}
