import { TextMatchTransformer } from '@lexical/markdown';
import {
  $createMentionNode,
  $isMentionNode,
  MentionNode,
  isMentionType,
} from '../mentions/MentionNode';
import { $createImageNode, $isImageNode, ImageNode } from '../images/ImageNode';

/*
 * This file contains the transformers used by Lexical Markdown to convert
 * nodes to and from markdown.
 *
 * Basics can be found here: https://github.com/facebook/lexical/blob/main/packages/lexical-markdown/README.md
 *
 * Since the documentation is lacking, here is a quick overview of how the
 * text-match transformers work:
 *
 * 1. The export function is used to convert a node to markdown.
 * 2. The importRegExp is used when parsing markdown from text.
 * 3. The regExp is used by MarkdownShortcutPlugin to replace text with a node.
 * 4. The replace function is used to replace a text node with a specific node
 *   based on a match.
 * 5. The trigger is used to determine when to trigger the transformer when typing
 *   in the editor. Used by MarkdownShortcutPlugin. Should be the last character of
 *   the markdown.
 */

export const MENTION: TextMatchTransformer = {
  dependencies: [MentionNode],
  export: (node) => {
    if (!$isMentionNode(node)) return null;

    const reference = node.getReference();
    const displayName = node.getDisplayName();
    const mentionType = node.getMentionType();

    return `@[${displayName}](${mentionType} ${reference})`;
  },
  importRegExp:
    /(?:@\[([^[]+)\])(?:\((?:([^()\s]+))(?:\s([0-9A-f]{8}[-](?:[0-9A-f]{4}[-]){3}[0-9A-f]{12}))\))/,
  regExp:
    /(?:@\[([^[]+)\])(?:\((?:([^()\s]+))(?:\s([0-9A-f]{8}[-](?:[0-9A-f]{4}[-]){3}[0-9A-f]{12}))\))$/,
  replace: (textNode, match) => {
    const [, displayName, type, reference] = match;
    if (isMentionType(type)) {
      const mentionNode = $createMentionNode(reference, displayName, type);
      textNode.replace(mentionNode);
      return;
    }

    textNode.setTextContent(displayName);
  },
  trigger: ')',
  type: 'text-match',
};

export const IMAGE: TextMatchTransformer = {
  dependencies: [ImageNode],
  export: (node) => {
    if (!$isImageNode(node)) {
      return null;
    }

    return `![${node.getAlt()}](${node.getSrc()})`;
  },
  importRegExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))/,
  regExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))$/,
  replace: (textNode, match) => {
    const [, alt, src] = match;
    const imageNode = $createImageNode({
      alt,
      src,
    });
    textNode.replace(imageNode);
  },
  trigger: ')',
  type: 'text-match',
};
