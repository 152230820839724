interface IButtonElementProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Renders a button-like element without any button style.
 *
 * @param {React.ReactNode} props.children - The content to be displayed inside the button.
 * @param {(e: React.MouseEvent<HTMLButtonElement ) => void} props.onClick - The function
 * to call when the button is clicked.
 * @returns {JSX.Element} A button element with custom content and click behavior.
 */

export default function ButtonLikeElement({ children, onClick }: IButtonElementProps) {
  return (
    <button type="button" onClick={(e) => onClick(e)} className="flex flex-row w-full items-center">
      {children}
    </button>
  );
}
