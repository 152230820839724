import { useContext } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AppContext } from '../../../shared/context/context';
import { networkConversationFilter } from '../../../shared/filters/filters';
import { useIsMeByUserId } from '../../../shared/auth/accountHooks';
import { ICreateUpdateNetworkDto, INetworkDto } from '../../../shared/model/INetwork';
import sortConversation from '../../../shared/services/conversationService';
import {
  createNetwork,
  deleteNetwork,
  getNetwork,
  updateNetwork,
  getNetworks,
  getMyNetworks,
} from '../../../shared/services/networkService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import { useToast } from '../../../shared/components/toasts/use-toast';

type NetworkSearchField = 'Name';

type NetworkPrivacySettings = 'Public' | 'Private';
type NetworkVisibilitySettings = 'Visible' | 'Hidden';

export interface INetworkKeyProps {
  id?: string;
  limit?: number;
  searchTerm?: string;
  name?: string;
  type?: NetworkPrivacySettings;
  visibility?: NetworkVisibilitySettings;
  searchFields?: NetworkSearchField[];
}

interface ICreateUpdateNetworkProps {
  id?: string;
  network: ICreateUpdateNetworkDto;
}

export const networkKeys = {
  all: ['networks'] as const,
  lists: () => [...networkKeys.all, 'list'] as const,
  list: (props: INetworkKeyProps) => [...networkKeys.lists(), props] as const,
  listMy: (props: INetworkKeyProps) => [...networkKeys.lists(), 'my', props] as const,
  details: () => [...networkKeys.all, 'detail'] as const,
  detail: (id: string) => [...networkKeys.details(), id] as const,
};
const STALE_TIME = 1000 * 60;

const networksBaseQuery = (props: INetworkKeyProps) => ({
  queryKey: networkKeys.list(props),
  queryFn: () =>
    getNetworks(
      new QueryParam('limit', props.limit || 250),
      new QueryParam('id', props.id),
      new QueryParam('searchTerm', props.searchTerm),
      new QueryParam('name', props.name),
      new QueryParam('type', props.type),
      ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
    ).then((result) => result.data),
  staleTime: STALE_TIME,
  placeholderData: keepPreviousData,
});

export const networkBaseQuery = (id: string): UseQueryOptions<INetworkDto> => ({
  queryKey: networkKeys.detail(id),
  queryFn: () => getNetwork(id),
});

export const myNetworksBaseQuery = (props: INetworkKeyProps) => ({
  queryKey: networkKeys.listMy(props),
  queryFn: () =>
    getMyNetworks(
      new QueryParam('limit', props.limit || 250),
      new QueryParam('id', props.id),
      new QueryParam('searchTerm', props.searchTerm),
      new QueryParam('name', props.name),
      ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
    ).then((result) => result.data),
  staleTime: STALE_TIME,
  placeholderData: keepPreviousData,
});

export function useNetworksQuery(props: INetworkKeyProps = {}) {
  return useQuery({
    ...networksBaseQuery(props),
  });
}

export function useMyNetworksQuery(props: INetworkKeyProps = {}) {
  return useQuery({
    ...myNetworksBaseQuery(props),
  });
}

export function useNetworksInfiniteQuery(props: INetworkKeyProps = {}) {
  const { limit = 150, searchTerm = '', searchFields = [], type = 'Private' } = props;
  return useInfiniteApiQuery({
    queryKey: networkKeys.list({ limit, searchTerm, searchFields, type }),
    queryFn: () =>
      getNetworks(
        new QueryParam('limit', limit),
        new QueryParam('searchTerm', searchTerm),
        new QueryParam('type', type),

        ...searchFields.map((field) => new QueryParam('searchFields', field)),
      ),
  });
}

export function useChatNetworkQuery(searchTerm?: string) {
  const { state } = useContext(AppContext);
  const isMe = useIsMeByUserId();
  return useQuery({
    ...networksBaseQuery({ searchTerm }),
    enabled: !!state.conversations,
    select: (networks: INetworkDto[]) => {
      const networksConversations = state.conversations?.filter(networkConversationFilter) ?? [];
      if (networksConversations.length === 0) return [];

      const networkSorted = sortConversation(
        networksConversations,
        networks,
        (network, conversation) => network.id === conversation.resourceId,
        isMe,
      );

      return networkSorted;
    },
  });
}

export function useNetworkQuery(networkId?: string) {
  return useQuery({
    ...networkBaseQuery(networkId || ''),
    enabled: !!networkId,
  });
}

export function useCreateUpdateNetworkMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id, network }: ICreateUpdateNetworkProps) => {
      if (id) {
        return updateNetwork(network, id);
      }
      return createNetwork(network);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: networkKeys.lists() });
      if (id) {
        queryClient.invalidateQueries({ queryKey: networkKeys.detail(id) });
      }
    },
    onError: dispatchError,
    retry: 3,
  });
}

export function useDeleteNetworkMutation() {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const dispatchError = useDispatchApiError();

  return useMutation({
    mutationFn: ({ networkId }: { networkId: string; networkName: string }) =>
      deleteNetwork(networkId).then((response) => response.data),
    onSuccess: (_, { networkName }) => {
      queryClient.invalidateQueries({ queryKey: networkKeys.lists() });

      toast({
        title: 'Success',
        variant: 'success',
        description: `The network ${networkName} has been deleted.`,
      });
    },
    onError: dispatchError,
  });
}
