import { useQuery } from '@tanstack/react-query';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { getTenant, getTenants } from './tenantServices';

type TenantSearchField = 'Name';

interface ITenantProps {
  searchTerm?: string;
  searchFields?: TenantSearchField[];
  limit?: number;
}

export const tenantKeys = {
  all: ['tenants'] as const,
  lists: () => [...tenantKeys.all, 'lists'] as const,
  list: (props: ITenantProps) => [...tenantKeys.lists(), props] as const,
  details: () => [...tenantKeys.all, 'detail'] as const,
  detail: (id: string) => [...tenantKeys.details(), id] as const,
};

export function tenantBaseQuery(id: string) {
  return {
    queryKey: tenantKeys.detail(id),
    queryFn: () => getTenant(id),
  };
}

export function useTenantsQuery(props: ITenantProps = {}) {
  return useInfiniteApiQuery({
    queryKey: tenantKeys.list(props),
    queryFn: () =>
      getTenants(
        new QueryParam('limit', props.limit || 250),
        new QueryParam('searchTerm', props.searchTerm),
        ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
      ),
  });
}

export function useTenantQuery(id: string) {
  return useQuery(tenantBaseQuery(id));
}
