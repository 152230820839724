import axios from 'axios';
import { IProblemDetail } from '../model/IProblemDetail';

export interface DefaultErrorText {
  code: number;
  text: string;
}

export function transformAxiosError(error: unknown) {
  return axios.isAxiosError<IProblemDetail>(error) ? error.response?.data : undefined;
}

export function getApiErrorMessage(error: unknown, defaultErrorTexts?: DefaultErrorText[]) {
  const problemDetail = transformAxiosError(error);
  if (problemDetail) {
    return (
      defaultErrorTexts?.find(({ code }) => code === problemDetail.status)?.text ||
      problemDetail.detail ||
      problemDetail.title
    );
  }

  if (error instanceof Error) {
    return error.message;
  }

  return undefined;
}
