export enum ActivityType {
  Note = 'Note',
  Task = 'Task',
  Meeting = 'Meeting',
}
export enum ActivityStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Blocked = 'Blocked',
}

export interface IActivityUserDto {
  id: string;
  displayName: string;
}

export interface IActivityDto {
  id: string;
  parentId: string;
  assignedUser?: { id: string; displayName: string };
  title: string;
  text: string | null | undefined;
  type: ActivityType;
  status: ActivityStatus;
  createdTime: string;
  createdBy: string;
  expireTime: string;
  reminderTime: string;
  statusChangedTime: string;
}
