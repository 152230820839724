import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import {
  createOrganizationInvitation,
  deleteOrganizationMember,
  getOrganizationMembers,
} from './organizationServices';
import { ResourceIds } from '../../../shared/hooks/useEntityManifest';
import { tenantMembersKeys } from './tenantMemberQueries';

const organizationMembersKeys = {
  all: ['members', 'Organization'] as const,
  lists: () => [...organizationMembersKeys.all, 'list'] as const,
  list: (id: string) => [...organizationMembersKeys.all, id] as const,
};

export function useOrganizationMembersQuery(id: string) {
  return useInfiniteApiQuery({
    queryKey: organizationMembersKeys.list(id),
    queryFn: () => getOrganizationMembers(id),
  });
}

interface IUseInviteOrganizationMemberProps {
  resourceIds: ResourceIds; // To match generic member API
  userId: string;
}

export function useInviteOrganizationMemberMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ resourceIds, userId }: IUseInviteOrganizationMemberProps) =>
      createOrganizationInvitation(resourceIds[0], userId),
    onSuccess: (_, { resourceIds }) => {
      queryClient.invalidateQueries({ queryKey: organizationMembersKeys.list(resourceIds[0]) });
    },
    onError: dispatchError,
  });
}

interface IUseRemoveOrganizationMemberProps {
  tenantId: string;
  userId: string;
}

export function useRemoveOrganizationMemberMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ tenantId, userId }: IUseRemoveOrganizationMemberProps) =>
      deleteOrganizationMember(tenantId, userId),
    onSuccess: (_, { tenantId }) => {
      queryClient.invalidateQueries({ queryKey: organizationMembersKeys.list(tenantId) });
      // tenant members changes as well
      queryClient.invalidateQueries({ queryKey: tenantMembersKeys.list(tenantId) });
    },
    onError: dispatchError,
  });
}
