import { ForwardedRef, forwardRef, ReactElement, Ref, useRef, useState } from 'react';
import { FieldValues, Message, Path, UseFormRegister, ValidationRule } from 'react-hook-form';
import useAutosizeTextArea from '../../../hooks/useAutosizeTextBox';
import { CustomValidationErrorMessage } from './ValidationErrorMessage';

interface IProps<T extends FieldValues> {
  labelText?: string;
  name: Path<T>;
  placeholder?: string;
  register: UseFormRegister<T>;
  errors?: any;
  errorMessage?: string;
  autosize?: boolean;
  validationRules?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
  }>;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onInputBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  inputCss?: string;
  inputFocusCss?: string;
}

function RenderTextArea<T extends FieldValues>(
  {
    labelText,
    name,
    placeholder,
    register,
    errors = null,
    errorMessage = '',
    validationRules = {},
    onKeyUp,
    onKeyDown,
    onInputBlur,
    inputCss,
    inputFocusCss = 'focus:ring-0 focus:border-zinc-400',
  }: IProps<T>,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  const { onBlur, ref: formRef, onChange, ...registration } = register(name, validationRules);
  // must have null in type to get a mutable ref
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [value, setValue] = useState<string | null>(null);
  useAutosizeTextArea(inputRef.current, value);
  const hasErrors = errors !== null && errors[name];
  const hasLabelText = !!labelText;

  return (
    <>
      {hasErrors && hasLabelText && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
          {labelText}
          {errors !== null && errors[name] && (
            <CustomValidationErrorMessage message={errorMessage} />
          )}
        </label>
      )}

      <textarea
        onChange={(e) => {
          onChange(e);
          setValue(e.target?.value);
        }}
        ref={(e) => {
          formRef(e);
          inputRef.current = e;
          if (typeof ref === 'function') ref(e);
          // eslint-disable-next-line no-param-reassign
          else if (ref) ref.current = e;

          // We set the value here directly to avoid flickering in UI
          // when value is updated later (and async?) from the actual ref.
          setValue(e?.value || '');
        }}
        onBlur={(e) => {
          if (onInputBlur) {
            onInputBlur(e);
          }
          onBlur(e);
        }}
        {...registration}
        id={name}
        rows={1} // must be 1 because of autosize hook
        className={`text-sm scrollbar-hide placeholder:text-gray-400 shadow-sm block w-full rounded-md resize-none ${inputFocusCss} ${inputCss} ${
          errors !== null && errors[name]
            ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
            : ''
        }`}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      />
    </>
  );
}

const TextAreaInputAutosized = forwardRef(RenderTextArea) as <T extends FieldValues>(
  // eslint-disable-next-line no-use-before-define
  p: IProps<T> & { ref?: Ref<HTMLTextAreaElement> },
) => ReactElement;

export default TextAreaInputAutosized;
