import { MessageCircleMore } from 'lucide-react';
import ActivityBadge, { IActivityBadgeProps } from './ActivityBadge';

export type IConversationActivityBadgeProps = Omit<IActivityBadgeProps, 'icon' | 'classNames'>;

export default function ConversationActivityBadge(props: IConversationActivityBadgeProps) {
  return (
    <ActivityBadge
      icon={<MessageCircleMore className="w-4 h-4" stroke="#3984B9" />}
      classNames="bg-blue-300"
      {...props}
    />
  );
}
