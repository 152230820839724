import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

interface IProps {
  children: React.ReactNode;
}

export default function WrapperMobile({ children }: IProps) {
  const isMobile = useCheckMobileScreen();

  if (!isMobile) return <></>;

  return <>{children}</>;
}
