import { useEffect, useState } from 'react';
import { ButtonColors } from '../../constants/ButtonColors';
import { ButtonGroupDirections } from '../../constants/ButtonGroupDirections';
import Button from '../buttons/Button';
import ButtonGroup from '../buttons/ButtonGroup';
import UploadButton from '../buttons/UploadButton';
import HorizontalSpacer from '../spacing/HorizontalSpacer';
import Text from '../text/Text';
import { UploadProfilePhotoModal } from './UploadProfilePhotoModal';
import ProfileAvatar from './ProfileAvatar';
import useModal from '../modal/hooks/useModal';

interface IProps {
  url?: string;
  emptyStateUrl?: string;
  altText?: string;
  description?: string;
  onUpload: (file: Blob) => void;
  onRemove: () => void;
  isPending?: boolean;
}

export default function ProfileAvatarForm({
  url,
  emptyStateUrl,
  altText,
  onUpload,
  onRemove,
  isPending = false,
  description = 'Recommended image size 256 x 256px and maximum file size 1MB',
}: IProps) {
  const [imgSource, setImgSource] = useState('');

  const { openModal, closeModal } = useModal();
  const handleUploadProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || e.currentTarget.files.length === 0) return;

    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgSource(reader.result?.toString() || '');
    });
    reader.readAsDataURL(file);
    // eslint-disable-next-line no-param-reassign
    e.currentTarget.value = '';
  };

  useEffect(() => {
    if (!imgSource) return;
    openModal({
      title: 'Upload Profile Photo',
      content: (
        <UploadProfilePhotoModal
          imgSrc={imgSource}
          isUploadDisabled={isPending}
          onUpload={onUpload}
          onClose={closeModal}
        />
      ),
    });
  }, [imgSource]);

  const handleRemoveProfilePicture = () => {
    onRemove();
  };

  return (
    <>
      <div className="mt-2 flex basis-48 items-center gap-6">
        <ProfileAvatar
          avatarProps={{
            src: url || emptyStateUrl,
            alt: altText,
            widthClass: 'w-32',
            heightClass: 'h-32',
          }}
        />
        <div>
          <ButtonGroup direction={ButtonGroupDirections.Column}>
            <UploadButton
              text="Change photo"
              onChange={handleUploadProfilePicture}
              isDisabled={isPending}
            />
            <Button
              text="Remove photo"
              color={ButtonColors.Red}
              onClick={handleRemoveProfilePicture}
              disabled={isPending}
            />
          </ButtonGroup>
        </div>
      </div>
      <HorizontalSpacer />
      {description && (
        <Text as="p" size="small" brightness="light" leading="snug">
          {description}
        </Text>
      )}
    </>
  );
}
