import TimeAgo from '../../shared/components/dates/TimeAgo';
import { projectBaseQuery } from '../Projects/queries/projectQueries';
import { IInviteRequestDto } from './models/IInviteRequestDto';
import { useDeleteProjectInviteRequestMutation } from './queries/projectInviteQueries';
import { RequestItems } from './RequestItems';

interface IProps {
  projectInvitations: IInviteRequestDto[];
}

export default function ProjectInvitationRequests({ projectInvitations }: IProps) {
  const deleteProjectInviteRequestMutation = useDeleteProjectInviteRequestMutation();
  return (
    <RequestItems
      requests={projectInvitations}
      resourceQuery={(request: IInviteRequestDto) => projectBaseQuery(request.id)}
      text={(request, project) => (
        <>
          Invite to project &apos;{project?.name}&apos; sent <TimeAgo date={request.createdTime} />{' '}
          ago.
        </>
      )}
      actions={({ id }) => [
        {
          text: 'Cancel',
          disabled: deleteProjectInviteRequestMutation.isPending,
          onClick: () => deleteProjectInviteRequestMutation.mutate({ id }),
        },
      ]}
    />
  );
}
