import InfoIcon from '../info/InfoIcon';
import Text from './Text';

interface ILabelProps {
  text: string;
  extra?: React.ReactNode;
  infoText?: string;
  htmlFor?: string;
}

export default function Label({ text, infoText, htmlFor, extra }: ILabelProps) {
  return (
    <label htmlFor={htmlFor}>
      <Text as="span" size="small" weight="medium" leading="loose">
        {text}
      </Text>
      {extra}
      {infoText && (
        <span className="inline-block relative left-[5px] top-[5px]">
          <InfoIcon text={infoText} />
        </span>
      )}
    </label>
  );
}
