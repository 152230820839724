// From shadcn/ui - https://ui.shadcn.com/docs/components/checkbox
// Docs - https://www.radix-ui.com/primitives/docs/components/checkbox

import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@heroicons/react/24/solid';
import { cn } from '../../../lib/utils';

interface IProps {
  className?: string;
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & IProps
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'flex justify-center items-center h-5 w-5 my-2 border-2 border-gray-200 rounded-md data-[state=checked]:bg-white  data-[state=checked]:text-black text-blue ',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator>
      <CheckIcon className="h-3 w-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
