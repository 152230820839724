import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { TrendType } from '../../../features/Pipelines/models/IPipelineKpiDto';
import FlexContainer from '../structure/FlexContainer';
import { Badge } from '../badge/Badge';

interface IKpi {
  value: string | number | undefined;
  label?: string;
  trend?: TrendType;
  icon: React.ReactElement;
}

const labelVariants = cva('text-sm', {
  variants: {
    trend: {
      Up: 'arrow-up',
      Down: 'arrow-down',
      Flat: 'arrow-right',
      None: '',
    },
  },
  defaultVariants: {
    trend: 'None',
  },
});

type LabelVariantsProps = VariantProps<typeof labelVariants>;

export function LabelWithTrend({ label, trend }: { label: string; trend?: TrendType }) {
  let trendVariant: LabelVariantsProps['trend'];
  if (trend === 'Up' || trend === 'Down') {
    trendVariant = trend;
  } else if (trend) {
    trendVariant = 'Flat';
  } else {
    trendVariant = 'None';
  }

  return <span className={labelVariants({ trend: trendVariant })}>{label}</span>;
}

export function KpiIndicatorLayout({ value, label, trend, icon }: IKpi) {
  return (
    <FlexContainer align="center" justify="center" direction="column" gap="small">
      <div className="flex items-center gap-2">
        {icon}
        <Badge variant="unstyled">{value}</Badge>

        {!label && <LabelWithTrend label={label || ''} trend={trend as TrendType} />}
      </div>
      {label && <LabelWithTrend label={label || ''} trend={trend as TrendType} />}
    </FlexContainer>
  );
}

interface KpiIndicatorProps {
  data: IKpi[];
}

export function KpiIndicator({ data }: KpiIndicatorProps) {
  return (
    <FlexContainer gap="large">
      {data.map((item) => (
        <KpiIndicatorLayout
          key={item.label}
          value={item.value}
          label={item.label}
          trend={item.trend as TrendType}
          icon={item.icon}
        />
      ))}
    </FlexContainer>
  );
}
