import ProfileAvatarGroup, { IProfileAvatarSlim } from '../../avatar/ProfileAvatarGroup';

interface MemberRowCellCellProps {
  maxCount?: number;
  members: IProfileAvatarSlim[];
}

export default function MemberRowCell({ members, maxCount = 5 }: MemberRowCellCellProps) {
  return <ProfileAvatarGroup avatars={members} maxCount={maxCount} />;
}
