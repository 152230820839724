import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from '../tooltip/Tooltip';

interface IProps {
  text: string;
}
export default function InfoIcon({ text }: IProps) {
  return (
    <Tooltip message={text}>
      <div className=" w-5 h-5 rounded-full">
        <InformationCircleIcon className=" text w-5 h-5" />
      </div>
    </Tooltip>
  );
}
