import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../drop-down-menu/DropdownMenu';
import { cn } from '../../../lib/utils';
import Text from '../text/Text';
import FlexItem from '../structure/FlexItem';

export interface IDropdownNavigationItem {
  id: string;
  name: string;
  url: string;
  isDisabled?: boolean;
}

interface IDropdownNavigatorProps {
  items: IDropdownNavigationItem[];
  triggerClassName?: string;
}

export default function DropdownNavigator({ items, triggerClassName }: IDropdownNavigatorProps) {
  if (items.length === 0) {
    return <></>;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <ChevronDown className={cn('w-6 h-6 mt-2.5 text-black ', triggerClassName)} />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {items.map((item) => (
          <DropdownMenuItem key={item.id} asChild>
            {item.isDisabled ? (
              <FlexItem>
                <Text color="normal" brightness="light">
                  {item.name}
                </Text>
              </FlexItem>
            ) : (
              <Link to={item.url} className="w-full">
                {item.name}
              </Link>
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
