import { useState } from 'react';
import { ChevronDown, X } from 'lucide-react';
import SearchBar from '../../search/SearchBar';
import { UseInfiniteApiQueryResult } from '../../../hooks/useInfiniteApiQuery';
import { InfiniteApiQueryScroll } from '../../scrolling/InfiniteApiQueryScroll';
import DataTable, { DataTableProps } from '../../table/DataTable';
import { BaseLayout, IBaseLayoutProps } from './BaseLayout';
import { useOptionalControlledState } from '../../../hooks/useOptionalControlledState';
import { ILayoutActionItem } from './types';
import { IBreadcrumbNavigatorItem } from '../../navigation/BreadcrumbNavigator/helpers/breadcrumbNavigatorHelper';
import FlexContainer from '../../structure/FlexContainer';
import { Popover, PopoverContent, PopoverTrigger } from '../../popovers/Popover';

import FlexItem from '../../structure/FlexItem';
import { FiltersSettings } from '../../filters/hooks/types';
import Filters from '../../filters/Filters';
import FiltersActiveList from '../../filters/FiltersActiveList';
// Potentially, we can add KPI's here.

export interface IListLayoutProps<TData, TValue, TFilter>
  extends Omit<IBaseLayoutProps, 'children'>,
    Omit<
      DataTableProps<TData, TValue>,
      'data' | 'isLoading' | 'globalFilter' | 'columnFilters' | 'onColumnFiltersChange'
    >,
    Partial<Pick<DataTableProps<TData, TValue>, 'columnFilters' | 'onColumnFiltersChange'>> {
  query: UseInfiniteApiQueryResult<TData>;
  actions?: ILayoutActionItem[];
  breadcrumbsNavigatorItems?: IBreadcrumbNavigatorItem[];
  contentAfterHeader?: React.ReactNode;
  searchPlaceholder?: string;
  filters?: FiltersSettings<TFilter>;
}

export function ListLayout<TData, TValue, TFilter>({
  title,
  headerActions,
  breadcrumbsNavigatorItems,
  contentAfterHeader,
  query,
  filters,
  searchPlaceholder,
  columnFilters: controlledColumnFilters,
  onColumnFiltersChange,
  ...dataTableProps
}: IListLayoutProps<TData, TValue, TFilter>) {
  const [searchTerm, setSearchTerm] = useState('');

  const [columnFilters, setColumnFilters] = useOptionalControlledState(
    controlledColumnFilters,
    onColumnFiltersChange,
    [],
  );

  return (
    <BaseLayout
      title={title}
      headerActions={headerActions}
      breadcrumbsNavigatorItems={breadcrumbsNavigatorItems}
      contentAfterHeader={contentAfterHeader}
    >
      <FlexContainer direction="column" className="h-full w-full overflow-hidden">
        <FlexContainer className="w-full overflow-hidden">
          <FlexContainer className="w-full">
            <FlexItem className="basis-4/6 md:basis-2/6">
              <SearchBar
                searchTerm={searchTerm}
                onSearchChange={(e) => setSearchTerm(e.target.value)}
                placeholder={searchPlaceholder}
              />
            </FlexItem>
            {filters && (
              <Popover>
                <PopoverTrigger>
                  <FlexContainer align="center">
                    Filter <ChevronDown className="h-5 w-5 ml-1" />
                  </FlexContainer>
                </PopoverTrigger>
                <PopoverContent
                  align="start"
                  sideOffset={-15}
                  alignOffset={15}
                  className="min-w-64"
                >
                  <Filters filter={filters} />
                </PopoverContent>
              </Popover>
            )}
          </FlexContainer>
        </FlexContainer>
        {filters && (
          <FlexContainer align="start">
            <FiltersActiveList filter={filters} />
          </FlexContainer>
        )}

        <div className="flex-1 w-full overflow-hidden">
          <InfiniteApiQueryScroll query={query}>
            {({ data }) => (
              <DataTable
                data={data}
                isLoading={query.isPending}
                globalFilter={searchTerm}
                columnFilters={columnFilters}
                onColumnFiltersChange={setColumnFilters}
                {...dataTableProps}
              />
            )}
          </InfiniteApiQueryScroll>
        </div>
      </FlexContainer>
    </BaseLayout>
  );
}
