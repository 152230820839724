import { useQuery } from '@tanstack/react-query';
import { getOrganizationPrivileges } from './organizationServices';
import { ITenantPrivilegesDto } from '../models/ITenantPrivilegesDto';

const keys = {
  all: ['tenant', 'privileges'] as const,
  details: () => [...keys.all, 'detail'] as const,
  detail: (id: string) => [...keys.details(), id] as const,
};

export function useTenantPrivilegesQuery(id: string) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getOrganizationPrivileges(id),
    select: (data): ITenantPrivilegesDto => ({
      canRead:
        data.privileges.includes('Organization.Read') || data.privileges.includes('Tenant.Read'),
      canEdit: data.privileges.includes('Organization.Write'),
      canDelete: data.privileges.includes('Organization.Delete'),
      canReadMembers:
        data.privileges.includes('Organization.Member.Read') ||
        data.privileges.includes('Tenant.Member.Read'),
      canInviteMember: data.privileges.includes('Organization.Member.Invite'),
      canRemoveMember: data.privileges.includes('Organization.Member.Remove'),
    }),
  });
}
