import { AtSign } from 'lucide-react';
import ActivityBadge, { IActivityBadgeProps } from './ActivityBadge';

export type IMentionActivityBadgeProps = Omit<IActivityBadgeProps, 'icon' | 'classNames'>;

export default function MentionActivityBadge(props: IMentionActivityBadgeProps) {
  return (
    <ActivityBadge
      icon={<AtSign className="w-4 h-4" stroke="#F782C2" />}
      classNames="bg-yellow-light"
      {...props}
    />
  );
}
