import { useState } from 'react';
import { ICreateUpdateNetworkDto, INetworkDto } from '../../shared/model/INetwork';
import {
  useCreateUpdateNetworkMutation,
  useMyNetworksQuery,
} from '../Networks/queries/networkQueries';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import SearchBar from '../../shared/components/search/SearchBar';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SelectList from '../../shared/components/lists/SelectList';
import useDebounce from '../../shared/hooks/useDebounce';
import FlexItem from '../../shared/components/structure/FlexItem';
import ButtonLikeElement from '../../shared/components/buttons/ButtonLikeElement';
import { NetworkCreateEditForm, useNetworkCreateEditForm } from '../Networks/NetworkCreateEditForm';
import { Popover, PopoverContent, PopoverTrigger } from '../../shared/components/popovers/Popover';
import { ResourceType } from '../../shared/model/ResourceType';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import NetworkAddIcon from '../../shared/components/icons/NetworkAddIcon';

interface ISelectNetworkProps {
  onSelect: (network: INetworkDto) => void;
  onCreate: (network: INetworkDto) => void;
}

export function SelectNetwork({ onSelect, onCreate }: ISelectNetworkProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState<INetworkDto | undefined>(undefined);

  const [isCreateNetworkPopoverOpen, setIsCreateNetworkPopoverOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm);

  const resourceManifest = useEntityManifest(ResourceType.Network);

  const {
    data: networks,
    isLoading: isNetworksLoading,
    isSuccess,
  } = useMyNetworksQuery({ searchTerm: debouncedSearchTerm });
  const createUpdateNetworkMutation = useCreateUpdateNetworkMutation();

  const handleCreateNetwork = (network: ICreateUpdateNetworkDto) => {
    createUpdateNetworkMutation.mutateAsync({ network }).then((result) => {
      onCreate(result);
      setIsCreateNetworkPopoverOpen(false);
    });
  };

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleSelectNetwork = (network: INetworkDto) => {
    setSelectedItem(network);
    onSelect(network);
  };

  const networkForm = useNetworkCreateEditForm();

  if (!isSuccess) return null;

  return (
    <FlexContainer direction="column">
      <FlexContainer justify="start" align="center">
        <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
        <FlexItem className="ml-auto" self="center">
          <Popover open={isCreateNetworkPopoverOpen}>
            <PopoverTrigger>
              <ButtonLikeElement onClick={() => setIsCreateNetworkPopoverOpen(true)}>
                <NetworkAddIcon className="w-7 h-7" />
              </ButtonLikeElement>
            </PopoverTrigger>
            <PopoverContent align="start" sideOffset={-15} alignOffset={15} className="w-64">
              <NetworkCreateEditForm
                form={networkForm}
                submitButtonText="Add"
                cancelButtonText="Cancel"
                onSubmit={handleCreateNetwork}
                onCancel={() => setIsCreateNetworkPopoverOpen(false)}
              />
            </PopoverContent>
          </Popover>
        </FlexItem>
      </FlexContainer>
      <ScrollBarWrapper className="max-h-60">
        <SelectList<INetworkDto>
          data={networks ?? []}
          isLoading={isNetworksLoading}
          itemIdProperty={({ id }) => id}
          itemHeadlineProperty={({ name }) => name}
          itemInformationProperty={({ description }) => description}
          onSelectItem={handleSelectNetwork}
          selectedItem={selectedItem}
          itemImgSrcProperty={({ photoUrl }) => photoUrl || resourceManifest?.icon}
        />
      </ScrollBarWrapper>
    </FlexContainer>
  );
}
