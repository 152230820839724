import Text from '../text/Text';

interface ISharedFeatureHeaderProps {
  children: React.ReactNode;
}

export default function Header2({ children }: ISharedFeatureHeaderProps) {
  return (
    <Text as="h2" size="large" brightness="dark" weight="semibold">
      {children}
    </Text>
  );
}
