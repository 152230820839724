import { useContext, useEffect } from 'react';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../shared/context/context';
import {
  prefetchInfiniteApiQuery,
  useInfiniteApiQuery,
  UseInfiniteApiQueryResult,
} from '../../shared/hooks/useInfiniteApiQuery';
import { INotificationDto } from '../../shared/model/INotificationDto';
import finalizeNotification, {
  getNotifications,
  readNotification,
} from '../../shared/services/notificationService';
import QueryParam from '../../shared/utils/query-string-builder/QueryParam';
import {
  updateInfiniteQueriesData,
  updateInfiniteQueryData,
} from '../../shared/utils/queryClientUtils';
import { IPagedResult } from '../../shared/model/IPagedResult';
import { NotificationArea, NotificationType } from '../../shared/constants/NotificationConstants';

/**
 * Creates a new list of notifications based on the current state. Determines if a notification
 * is new or if an old one needs to be updated
 * @param notification takes in a notification
 * @param notifications takes in a list of notifications
 * @returns a cloned list of notifications
 */
const updateOrAddNotification = (
  notification: INotificationDto,
  notifications: INotificationDto[] | undefined,
): INotificationDto[] => {
  const notificationsClone = notifications ? [...notifications] : ([] as INotificationDto[]);
  const index = notificationsClone.findIndex((n) => n.id === notification.id);

  if (index === -1) {
    notificationsClone.unshift(notification);
    return notificationsClone;
  }

  notificationsClone.splice(index, 1, notification);
  return notificationsClone;
};

export type UseNotificationsQueryResult = UseInfiniteApiQueryResult<INotificationDto> & {
  setIsResponded: (id: string) => void;
};

export interface IUseNotificationProps {
  limit?: number;
  hasResponse?: boolean;
  isUnread?: boolean;
  notificationType?: NotificationType;
  notificationArea?: NotificationArea;
}

interface IReadNotificationProps {
  id: string;
}

export const notificationKeys = {
  all: ['notfications'] as const,
  lists: () => [...notificationKeys.all, 'list'] as const,
  list: (props: IUseNotificationProps) => [...notificationKeys.lists(), props] as const,
  details: () => [...notificationKeys.all, 'detail'] as const,
  detail: (id: string) => [...notificationKeys.details(), id] as const,
};

const useNotificationDefaults: IUseNotificationProps = {
  limit: 20,
};

const notificationsQueryBase = (options: IUseNotificationProps) => ({
  queryKey: notificationKeys.list(options),
  queryFn: () =>
    getNotifications(
      new QueryParam('limit', options.limit),
      new QueryParam('notificationType', options.notificationType),
      new QueryParam('notificationArea', options.notificationArea),
      new QueryParam('hasResponse', options.hasResponse?.toString()),
      new QueryParam('isUnread', options.isUnread?.toString()),
    ),
  select: (data: InfiniteData<IPagedResult<INotificationDto>, string | undefined>) => ({
    pageParams: data.pageParams,
    pages: data.pages.map((page) => page.data.map((n) => finalizeNotification(n))),
  }),
  staleTime: Infinity,
  useKeepPreviousData: true,
});

export function useNotificationsQuery(
  options?: IUseNotificationProps,
): UseNotificationsQueryResult {
  const _options = {
    ...useNotificationDefaults,
    ...options,
  };

  const queryClient = useQueryClient();
  const queryResult = useInfiniteApiQuery(notificationsQueryBase(_options));

  const setIsResponded = (id: string) => {
    updateInfiniteQueriesData<INotificationDto>(
      queryClient,
      notificationKeys.list(_options),
      (data) =>
        data.map((n) => {
          if (n.id !== id) return n;

          const notificationClone: INotificationDto = { ...n, isResponded: true };
          return notificationClone;
        }),
    );
  };

  return {
    setIsResponded,
    ...queryResult,
  };
}

function matchNotificationAgainstOptions(
  notification: INotificationDto,
  options: IUseNotificationProps,
) {
  return (
    (!options.notificationType || notification.type === options.notificationType) &&
    (!options.notificationArea || notification.area === options.notificationArea) &&
    (!options.hasResponse || notification.isResponded === options.hasResponse) &&
    (!options.isUnread || notification.isRead === options.isUnread)
  );
}

export function useNotificationSubscription(options?: IUseNotificationProps) {
  const _options = {
    ...useNotificationDefaults,
    ...options,
  };
  const { state } = useContext(AppContext);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!state.connection.on) return undefined;

    prefetchInfiniteApiQuery(queryClient, notificationsQueryBase(_options));

    state.connection.on('ReceiveNotification', (newNotification: INotificationDto) => {
      if (!matchNotificationAgainstOptions(newNotification, _options)) return;

      updateInfiniteQueryData<INotificationDto>(
        queryClient,
        notificationKeys.list(_options),
        (data) => updateOrAddNotification(newNotification, data),
      );
    });

    // eslint-disable-next-line consistent-return
    return () => {
      state.connection.off('ReceiveNotification');
      // Invalidate queries but don't refetch automatically
      queryClient.invalidateQueries({ queryKey: notificationKeys.all, refetchType: 'none' });
    };
  }, [queryClient, state.connection]);
}

export function useReadNotificationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IReadNotificationProps) => readNotification(id),
    onMutate: ({ id }) => {
      // optimistic update
      let oldData: INotificationDto | undefined;
      updateInfiniteQueriesData<INotificationDto>(queryClient, notificationKeys.lists(), (data) =>
        data.map((n) => {
          if (n.id !== id) return n;
          oldData = n;
          const notificationClone: INotificationDto = { ...n, isRead: true };
          return notificationClone;
        }),
      );
      return oldData;
    },
    onError: (_, { id }, oldData: INotificationDto | undefined) => {
      // rollback optimistic update
      if (typeof oldData === 'undefined') return;
      updateInfiniteQueriesData<INotificationDto>(queryClient, notificationKeys.lists(), (data) =>
        data.map((n) => {
          if (n.id !== id) return n;
          return oldData;
        }),
      );
    },
  });
}
