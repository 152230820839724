import { resolveEndpointName, ResourceIds } from '../hooks/useEntityManifest';
import { IActivityDto, ActivityType, IActivityUserDto } from '../model/IActitityDto';
import { IMoveActivityDto } from '../model/IMoveActivityDto';
import { IPagedResult } from '../model/IPagedResult';
import { ResourceType } from '../model/ResourceType';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export interface IUpdateActivityDto {
  title?: string;
  text?: string;
}

export const getActivities = (ids: ResourceIds, type: ResourceType, limit?: number) => {
  const endpointName = resolveEndpointName(type, ids);
  const queryString = limit
    ? queryStringBuilder().add(new QueryParam('limit', limit)).toQueryString()
    : '';
  return apiClient
    .get(`v1/${endpointName}/activities${queryString}`)
    .then<IPagedResult<IActivityDto>>((response) => response.data);
};

export const searchAssign = (
  ids: ResourceIds,
  type: ResourceType,
  activityId: string,
  limit?: number,
) => {
  const endpointName = resolveEndpointName(type, ids);
  const queryString = limit
    ? queryStringBuilder().add(new QueryParam('limit', limit)).toQueryString()
    : '';
  return apiClient
    .get(`v1/${endpointName}/activities/${activityId}/searchAssign${queryString}`)
    .then<IPagedResult<IActivityUserDto>>((response) => response.data);
};

export const createActivity = (
  ids: ResourceIds,
  entityType: ResourceType,
  title: string,
  type: ActivityType,
  autoAssignToCreator: boolean,
) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient
    .post(`v1/${endpointName}/activities`, { title, type, autoAssignToCreator })
    .then<IActivityDto>((response) => response.data);
};

export const updateActivity = (
  ids: ResourceIds,
  entityType: ResourceType,
  activityId: string,
  payload: IUpdateActivityDto,
) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.patch(`v1/${endpointName}/activities/${activityId}`, payload);
};

export const deleteActivity = (ids: ResourceIds, entityType: ResourceType, activityId: string) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.delete(`v1/${endpointName}/activities/${activityId}`);
};

export const completeActivity = (
  ids: ResourceIds,
  entityType: ResourceType,
  activityId: string,
) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.post(`v1/${endpointName}/activities/${activityId}/complete`);
};

export const pauseActivity = (ids: ResourceIds, entityType: ResourceType, activityId: string) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.post(`v1/${endpointName}/activities/${activityId}/pause`);
};

export const startActivity = (ids: ResourceIds, entityType: ResourceType, activityId: string) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.post(`v1/${endpointName}/activities/${activityId}/start`);
};

export const blockActivity = (ids: ResourceIds, entityType: ResourceType, activityId: string) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.post(`v1/${endpointName}/activities/${activityId}/block`);
};

export const setReminderOnActivity = (
  ids: ResourceIds,
  entityType: ResourceType,
  activityId: string,
  remindAtTime: string,
) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.put(`v1/${endpointName}/activities/${activityId}/reminder`, {
    remindAtTime,
  });
};

export const setExpirationOnActivity = (
  ids: ResourceIds,
  entityType: ResourceType,
  activityId: string,
  expireAtTime: string,
) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.put(`v1/${endpointName}/activities/${activityId}/expire`, {
    expireAtTime,
  });
};

export const setAssignedToOnActivity = (
  ids: ResourceIds,
  entityType: ResourceType,
  activityId: string,
  userId: string,
) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.post(`v1/${endpointName}/activities/${activityId}/assign`, {
    userId,
  });
};

export const moveActivityTask = (
  ids: ResourceIds,
  entityType: ResourceType,
  activityId: string,
  moveActivity: IMoveActivityDto,
) => {
  const endpointName = resolveEndpointName(entityType, ids);
  return apiClient.put(`v1/${endpointName}/activities/${activityId}/move`, moveActivity);
};
