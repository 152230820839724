import { UseQueryResult } from '@tanstack/react-query';
import { DefaultErrorText, getApiErrorMessage } from '../../utils/apiClientUtils';

interface IProps {
  queryResults: UseQueryResult[];
  defaultErrorTexts?: DefaultErrorText[];
}

export function ErrorComponent({ queryResults, defaultErrorTexts }: IProps) {
  const error = queryResults.find((result) => result.isError)?.error;
  const message = getApiErrorMessage(error, defaultErrorTexts);
  return (
    <>
      {message && (
        <div className="flex flex-col justify-center items-center w-full h-full">
          <span className="text-6xl text leading-relaxed font-medium w-fit">
            An error occurred!
          </span>
          <span className="text-2xl text leading-relaxed w-fit">{message}</span>
        </div>
      )}
    </>
  );
}
