// modalStore.ts
import { StateCreator } from 'zustand';
import { CombinedSlices } from '../useGlobalStore';

export type ModalSize = 'small' | 'medium' | 'fullscreen';

interface IModalContentProps {
  title?: string;
  content: React.ReactNode | null;
  modalSize?: ModalSize;
  hideCloseButton?: boolean;
}

const defaultModalProps: IModalContentProps = {
  title: undefined,
  content: null,
  modalSize: 'medium',
  hideCloseButton: false,
};

export interface ModalSlice {
  modalContentProps: IModalContentProps;
  modal: {
    isOpen: boolean;
    open: (modalProps: IModalContentProps) => void;
    close: () => void;
  };
}

export const createModalSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  ModalSlice
> = (set) => ({
  modal: {
    isOpen: false,
    open: (modalContentProps) =>
      set((state) => ({
        modal: { ...state.modal, isOpen: true },
        modalContentProps,
      })),
    close: () =>
      set((state) => ({
        modal: { ...state.modal, isOpen: false },
        modalContentProps: defaultModalProps,
      })),
  },
  modalContentProps: defaultModalProps,
});

export default createModalSlice;
