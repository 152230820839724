import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { deleteMember, getMembers, invite } from '../services/MemberService';
import { IMember } from '../models/IMember';
import { ResourceType } from '../../../shared/model/ResourceType';
import { ResourceIds } from '../../../shared/hooks/useEntityManifest';

interface IMemberKeyProps {
  resourceIds: ResourceIds;
}

interface IRemoveMemberProps {
  member: IMember;
}

interface IInviteMemberProps {
  resourceIds: ResourceIds;
  userId: string;
}

const keys = {
  all: (type: ResourceType) => ['members', type] as const, // 'members'
  lists: (type: ResourceType) => [...keys.all(type), 'list'] as const, // 'members', 'list'
  list: (type: ResourceType, props: IMemberKeyProps) => [...keys.lists(type), props] as const, //
  details: (type: ResourceType) => [...keys.all(type), 'detail'] as const,
  detail: (type: ResourceType, id: string) => [...keys.details(type), id] as const,
};

function membersBaseQuery(type: ResourceType, resourceIds?: ResourceIds) {
  return {
    queryKey: keys.list(type, { resourceIds: resourceIds || '' }),
    queryFn: () => (resourceIds ? getMembers(type, resourceIds) : Promise.resolve([])),
    enabled: !!resourceIds,
  };
}

export function useMembersQuery(type: ResourceType, resourceIds?: ResourceIds) {
  return useQuery(membersBaseQuery(type, resourceIds));
}

export function useMembersWithFilterQuery(
  type: ResourceType,
  resourceId: ResourceIds,
  filter: (member: IMember) => boolean,
) {
  return useQuery({
    ...membersBaseQuery(type, resourceId),
    select: (members: IMember[]) => members.filter(filter),
  });
}

export function useRemoveMemberMutation(type: ResourceType, relatedKeyToInvalidate: string[] = []) {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ member }: IRemoveMemberProps) =>
      deleteMember(type, member.resourceId, member.userId),
    onSuccess: (_, { member }) => {
      queryClient.invalidateQueries({
        queryKey: keys.list(type, { resourceIds: member.resourceId }),
      });
      queryClient.invalidateQueries({ queryKey: relatedKeyToInvalidate });
    },
    onError: dispatchError,
  });
}

export function useInviteMemberMutation(type: ResourceType) {
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ resourceIds, userId }: IInviteMemberProps) => invite(type, resourceIds, userId),
    onError: dispatchError,
  });
}
