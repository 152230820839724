export default class QueryParam {
  name: string;

  value?: number | string | undefined;

  constructor(name: string, value: number | string | undefined) {
    this.name = name;
    this.value = value;
  }
}
