import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';

import { getInitalDataPropsFromQueries } from '../../../shared/utils/queryClientUtils';
import {
  ICreatePipelineItemDto,
  IMovePipelineItemDto,
  IPipelineItemDto,
  IPipelineItemMoveRollbackDto,
  IUpdatePipelineItemDto,
} from '../models/IPipelineItemDto';
import { CalculateDragDropPositions } from '../../../shared/utils/dragDropUtils';
import { pipelineKeys } from './pipelineQueries';
import { pipelineKpiKeys } from './pipelineKpiQueries';
import { stageKeys } from './stageQueries';
import {
  getPipelineItems,
  getPipelineItem,
  createPipelineItem,
  updatePipelineItem,
  deletePipelineItem,
  movePipelineItem,
} from '../services/pipelineItemService';

interface IUsePipelineItemProps {
  pipelineId: string;
  stageId: string;
  itemId: string;
}

interface IUsePipelineItemsProps {
  pipelineId: string;
  stageId: string;
}

export const pipelineItemKeys = {
  all: ['pipeline-items'] as const,
  lists: () => [...pipelineItemKeys.all, 'list'] as const,
  list: (props: IUsePipelineItemsProps) => [...pipelineItemKeys.lists(), props] as const,
  details: () => [...pipelineItemKeys.all, 'detail'] as const,
  detail: (id: string) => [...pipelineItemKeys.details(), id] as const,
};

const STALE_TIME = 1000 * 60 * 60 * 24; // 24 hours

export const pipelineItemsBaseQuery = (props: IUsePipelineItemsProps) => ({
  queryKey: pipelineItemKeys.list(props),
  queryFn: () => getPipelineItems(props.pipelineId as string, props.stageId as string),
  staleTime: STALE_TIME,
  enabled: !!props.pipelineId && !!props.stageId,
});

export function usePipelineItemsQuery(props: IUsePipelineItemsProps) {
  return useQuery({
    ...pipelineItemsBaseQuery(props),
  });
}

export const pipelineItemBaseQuery = (props: IUsePipelineItemProps, queryClient: QueryClient) => ({
  queryKey: pipelineItemKeys.detail(props.itemId as string),
  queryFn: () =>
    getPipelineItem(props.pipelineId as string, props.stageId as string, props.itemId as string),
  ...getInitalDataPropsFromQueries<IPipelineItemDto>(
    queryClient,
    pipelineItemKeys.list(props),
    (item) => item.id === props.itemId,
  ),
});

export function usePipelineItemQuery(props: IUsePipelineItemProps) {
  const queryClient = useQueryClient();
  return useQuery({
    ...pipelineItemBaseQuery(props, queryClient),
    enabled: !!props.pipelineId && !!props.stageId && !!props.itemId,
  });
}

interface ICreatePipelineItemProps {
  pipelineId: string;
  stageId: string;
  item: ICreatePipelineItemDto;
}

export function useCreatePipelineItemMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ pipelineId, stageId, item }: ICreatePipelineItemProps) =>
      createPipelineItem(pipelineId, stageId, item),
    onSuccess: (_, { pipelineId, stageId }) => {
      queryClient.invalidateQueries({
        queryKey: pipelineItemKeys.list({ pipelineId, stageId }),
      });
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({ queryKey: stageKeys.detail(stageId) });
      queryClient.invalidateQueries({ queryKey: pipelineKpiKeys.detail({ pipelineId }) });
    },
    onError: dispatchError,
  });
}

export function useUpdatePipelineItemMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      itemId,
      item,
    }: {
      pipelineId: string;
      stageId: string;
      itemId: string;
      item: IUpdatePipelineItemDto;
    }) => updatePipelineItem(pipelineId, stageId, itemId, item).then((response) => response.data),
    onSuccess: (_, { pipelineId, stageId, itemId }) => {
      queryClient.invalidateQueries({
        queryKey: pipelineItemKeys.list({ pipelineId, stageId }),
      });
      queryClient.invalidateQueries({ queryKey: pipelineItemKeys.detail(itemId) });
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({ queryKey: stageKeys.detail(stageId) });
      queryClient.invalidateQueries({ queryKey: pipelineKpiKeys.detail({ pipelineId }) });
    },
    onError: dispatchError,
  });
}

export function useDeletePipelineItemMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      itemId,
    }: {
      pipelineId: string;
      stageId: string;
      itemId: string;
    }) => deletePipelineItem(pipelineId, stageId, itemId).then((response) => response.data),
    onSuccess: (_, { pipelineId, stageId }) => {
      queryClient.invalidateQueries({
        queryKey: pipelineItemKeys.list({ pipelineId, stageId }),
      });
      queryClient.invalidateQueries({ queryKey: pipelineKpiKeys.detail({ pipelineId }) });
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({ queryKey: stageKeys.detail(stageId) });
    },
    onError: dispatchError,
  });
}

export function useMovePipelineItemMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      pipelineId,
      sourceStageId,
      itemId,
      item,
    }: {
      pipelineId: string;
      sourceStageId: string;
      itemId: string;
      destinationIndex: number;
      item: IMovePipelineItemDto;
    }) =>
      movePipelineItem(pipelineId, sourceStageId, itemId, item).then((response) => response.data),
    onMutate: ({
      pipelineId,
      destinationIndex,
      sourceStageId,
      itemId,
      item,
    }): IPipelineItemMoveRollbackDto => {
      const targetStageId = item.moveToStageId as string;

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // TODO: Verify if this is the correct keys
      queryClient.cancelQueries({
        queryKey: pipelineItemKeys.list({ pipelineId, stageId: sourceStageId }),
      });
      queryClient.cancelQueries({
        queryKey: pipelineItemKeys.list({ pipelineId, stageId: targetStageId }),
      });

      const previousSourceItems: IPipelineItemDto[] = queryClient.getQueryData(
        pipelineItemKeys.list({ pipelineId, stageId: sourceStageId }),
      ) as IPipelineItemDto[];

      const previousTargetItems: IPipelineItemDto[] = queryClient.getQueryData(
        pipelineItemKeys.list({ pipelineId, stageId: targetStageId }),
      ) as IPipelineItemDto[];

      const { sourceItems, targetItems } = CalculateDragDropPositions<IPipelineItemDto>(
        previousSourceItems,
        previousTargetItems,
        itemId,
        destinationIndex,
      );

      queryClient.setQueryData(
        pipelineItemKeys.list({ pipelineId, stageId: sourceStageId }),
        sourceItems,
      );
      queryClient.setQueryData(
        pipelineItemKeys.list({ pipelineId, stageId: targetStageId }),
        targetItems,
      );

      return { previousSourceItems, previousTargetItems };
    },
    onSuccess: (_, { pipelineId, sourceStageId, item, itemId }) => {
      queryClient.invalidateQueries({
        queryKey: pipelineItemKeys.list({ pipelineId, stageId: sourceStageId }),
      });
      queryClient.invalidateQueries({
        queryKey: pipelineItemKeys.list({ pipelineId, stageId: item.moveToStageId as string }),
      });
      queryClient.invalidateQueries({ queryKey: pipelineItemKeys.detail(itemId) });

      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({ queryKey: stageKeys.detail(sourceStageId) });
      if (item.moveToStageId) {
        queryClient.invalidateQueries({ queryKey: stageKeys.detail(item.moveToStageId) });
      }
      queryClient.invalidateQueries({ queryKey: pipelineKpiKeys.detail({ pipelineId }) });
    },
    onError: (
      _,
      { pipelineId, sourceStageId, item },
      oldData: IPipelineItemMoveRollbackDto | undefined,
    ) => {
      const targetStageId = item.moveToStageId as string;
      queryClient.setQueryData(
        pipelineItemKeys.list({ pipelineId, stageId: sourceStageId }),
        oldData?.previousSourceItems,
      );
      queryClient.setQueryData(
        pipelineItemKeys.list({ pipelineId, stageId: targetStageId }),
        oldData?.previousTargetItems,
      );
    },
  });
}
