import Text from '../text/Text';

interface IProps {
  headline: string;
  children: React.ReactNode;
}
export default function InfoBox({ headline, children }: IProps) {
  return (
    <div className="flex flex-col w-full items-center relative">
      <div className="relative top-2 w-fit bg-cyan-light rounded-lg px-4 py-1 text-white">
        {headline && (
          <div className="flex flex-row items-center space-x-2 triangle-down">
            <Text size="large" weight="bold" color="cyan" brightness="dark">
              {headline}
            </Text>
          </div>
        )}
      </div>
      <div className="bg-cyan text-white p-4 rounded-lg ">{children}</div>
    </div>
  );
}
