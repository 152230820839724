import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo.svg';
import logoY from '../../../../assets/images/logoY.svg';
import Notifications from '../../../../features/Notifications/Notifications';
import { Filler } from '../Filler';
import HeaderNavigation from './HeaderNavigation';

function HeaderDesktopContainer({ children }: { children: React.ReactNode }) {
  return <header className="flex flex-shrink-0 h-18 w-full items-start">{children}</header>;
}

function HeaderDesktopActions({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-row p-2.5 mr-2 min-w-32 justify-end items-center">
      <div className="flex flex-row gap-4 ">{children}</div>
    </div>
  );
}

export default function HeaderDesktop() {
  return (
    <HeaderDesktopContainer>
      <Link to="/" className="flex items-center justify-start my-2 h-10">
        <img className="hidden 2xl:block h-10 w-auto ml-[58px]" src={logo} alt="Yoin" />
        <img className="block 2xl:hidden h-7 w-auto ml-[18px]" src={logoY} alt="Yoin" />
      </Link>
      <Filler />
      <HeaderDesktopActions>
        <Notifications />
        <HeaderNavigation />
      </HeaderDesktopActions>
    </HeaderDesktopContainer>
  );
}
