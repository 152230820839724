import { useState } from 'react';
import { ActivityType } from '../../shared/model/IActitityDto';
import { useCreateActivityMutation } from './queries/activitiesQueries';
import useAutoFocus from '../../shared/hooks/useAutoFocus';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';

interface IProps {
  resourceIds: ResourceIds;
  resourceType: ResourceType;
  placeholder: string;
  defaultTitle?: string;
  defaultActivityType?: ActivityType;
}

export default function ActivityCreateInput({
  resourceIds,
  resourceType,
  defaultTitle = '',
  defaultActivityType = ActivityType.Task,
  placeholder,
}: IProps) {
  const [title, setTitle] = useState('');
  const createActivityMutation = useCreateActivityMutation(resourceIds, resourceType);
  const handleActivityCreate = () => {
    if (!title) {
      return;
    }
    createActivityMutation.mutate({ title, type: defaultActivityType });
    setTitle('');
  };
  const inputFocus = useAutoFocus();
  return (
    <div className="flex">
      <div className="flex-1 min-w-0">
        <label htmlFor="title" className="sr-only">
          Create activity
        </label>
        <div className="relative rounded-md shadow-sm">
          <input
            value={title || defaultTitle}
            maxLength={255}
            type="text"
            enterKeyHint="go"
            onChange={(event) => setTitle(event.target.value)}
            onKeyDown={(event) => {
              if (event.key !== 'Enter') {
                return;
              }
              handleActivityCreate();
            }}
            ref={inputFocus}
            name="title"
            id="title"
            className="focus:ring-0 focus:border-zinc-400 block w-full pl-3 pr-10 text-sm border-gray-300 placeholder-gray-400 rounded-md"
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
}
