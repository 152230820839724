import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useEffect, useRef } from 'react';
import { loginRequest } from '../../shared/auth/authConfig';

export function SignIn() {
  const { instance: msalInstance, inProgress } = useMsal();
  const redirectStartedRef = useRef(false);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None || redirectStartedRef.current) return;

    redirectStartedRef.current = true;
    msalInstance.loginRedirect(loginRequest);
  }, [msalInstance, inProgress]);

  return <div />;
}
