import { formatISO } from 'date-fns';
import { IPipelineKpiDto } from '../models/IPipelineKpiDto';
import apiClient from '../../../shared/utils/apiClient';

export const getPipelineKpi = (pipelineId: string, periodStartTime: Date, periodEndTime: Date) => {
  const periodStartDateString = formatISO(periodStartTime);
  const periodEndDateString = formatISO(periodEndTime);
  return apiClient
    .get<IPipelineKpiDto>(`v1/pipelines/${pipelineId}/kpi`, {
      params: { periodStartTime: periodStartDateString, periodEndTime: periodEndDateString },
    })
    .then((response) => response.data);
};
