import ProfileAvatar, { IProfileAvatarProps } from './ProfileAvatar';
import StatusIndicator, { IStatusIndicatorProps } from '../status-indicator/StatusIndicator';

type AvatarWithStatusProps = {
  profileAvatarProps: IProfileAvatarProps;
  statusProps: IStatusIndicatorProps;
};

export default function ProfileAvatarStatus({
  profileAvatarProps,
  statusProps,
}: AvatarWithStatusProps) {
  return (
    <div className="relative inline-block cursor-pointer">
      <ProfileAvatar {...profileAvatarProps} />
      <div className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4">
        <StatusIndicator {...statusProps} />
      </div>
    </div>
  );
}
