import { useEffect } from 'react';

/**
 * A custom hook that executes callbacks based on the window size.
 *
 * @param smallSizeCallback - The callback called below the maxScreenSize.
 * @param largeSizeCallback - The callback called when equal to or above the maxScreenSize.
 * @param maxScreenSize - The maximum screen size to consider as small. Defaults to 1500 pixels.
 */

const useCallbackOnResize = (
  smallSizeCallback: () => void,
  largeSizeCallback: () => void,
  maxScreenSize = 1500,
) => {
  useEffect(() => {
    const onresize = (event: Event) => {
      const targetAsWindow = event.target as Window;

      if (targetAsWindow.innerWidth < maxScreenSize) {
        smallSizeCallback();
      } else {
        largeSizeCallback();
      }
    };

    window.addEventListener('resize', onresize);
    return () => window.removeEventListener('resize', onresize);
  }, []);
};

export default useCallbackOnResize;
