import { set } from 'date-fns';

export default function getTime(date?: Date | string) {
  return date !== null && typeof date !== 'undefined' ? new Date(date).getTime() : 0;
}

export function setTime(date: Date, time: string) {
  const [hours, minutes, seconds] = time.split(':');
  return set(date, {
    hours: parseInt(hours || '0', 10),
    minutes: parseInt(minutes || '0', 10),
    seconds: parseInt(seconds || '0', 10),
  });
}

export function datesAreOnSameDay(_firstDate: string, _secondDate: string) {
  const firstDate = new Date(_firstDate);
  const secondDate = new Date(_secondDate);
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
}

export const messagingDividerDateFormat = 'EEEE, d LLLL';

const resetTimePartOfDate = (_date: Date) =>
  set(_date, { hours: 9, seconds: 0, minutes: 0, milliseconds: 0 });
const nowBaseDate = new Date();
export const todayBaseDate = resetTimePartOfDate(nowBaseDate);
