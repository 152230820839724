import { forwardRef } from 'react';
import { SVGIconProps, SVGIconRef } from './types';

function AddIcon({ titleId, title, ...props }: SVGIconProps, ref: SVGIconRef) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title && <title id={titleId}>{title}</title>}
      <circle cx="50" cy="50" r="40" fill="#0E7490" />

      <line x1="50" y1="30" x2="50" y2="70" stroke="white" strokeWidth="6" />
      <line x1="30" y1="50" x2="70" y2="50" stroke="white" strokeWidth="6" />
    </svg>
  );
}

export default forwardRef(AddIcon);
