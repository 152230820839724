import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMsal } from '@azure/msal-react';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { ICreateUpdateOrganizationDto } from '../models/ICreateUpdateOrganizationDto';
import {
  createOrganization,
  deleteOrganization,
  getOrganization,
  getOrganizations,
  updateOrganization,
} from './organizationServices';
import { getAccessToken } from '../../../shared/auth/authUtils';

type OrganizationSearchField = 'Name';

interface IOrganizationProps {
  searchTerm?: string;
  searchFields?: OrganizationSearchField[];
  limit?: number;
}

export const organizationKeys = {
  all: ['organziations'] as const,
  lists: () => [...organizationKeys.all, 'lists'] as const,
  list: (props: IOrganizationProps) => [...organizationKeys.lists(), props] as const,
  details: () => [...organizationKeys.all, 'detail'] as const,
  detail: (id: string) => [...organizationKeys.details(), id] as const,
};

export function organizationBaseQuery(id: string) {
  return {
    queryKey: organizationKeys.detail(id),
    queryFn: () => getOrganization(id),
  };
}

export function useOrganizationsQuery(props: IOrganizationProps = {}) {
  return useInfiniteApiQuery({
    queryKey: organizationKeys.list(props),
    queryFn: () =>
      getOrganizations(
        new QueryParam('limit', props.limit || 250),
        new QueryParam('searchTerm', props.searchTerm),
        ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
      ),
  });
}

export function useOrganizationQuery(id: string) {
  return useQuery(organizationBaseQuery(id));
}

export function useCreateUpdateOrganizationMutation() {
  const queryClient = useQueryClient();
  const { instance } = useMsal();
  const dispatchError = useDispatchApiError();

  return useMutation({
    mutationFn: ({ id, ...data }: { id?: string } & ICreateUpdateOrganizationDto) =>
      typeof id === 'undefined'
        ? createOrganization(data)
        : updateOrganization(id, data).then(() => ({ id, ...data })),
    onSuccess: async ({ id }) => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.lists() });
      queryClient.invalidateQueries({ queryKey: organizationKeys.detail(id) });
      await getAccessToken(instance, true);
    },
    onError: dispatchError,
  });
}

export function useDeleteOrganizationMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id }: { id: string }) => deleteOrganization(id),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.lists() });
      queryClient.removeQueries({ queryKey: organizationKeys.detail(id) });
    },
    onError: dispatchError,
  });
}
