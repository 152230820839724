import { ResourceType } from '../../../shared/model/ResourceType';
import { IPipelineCreateDto } from '../../Pipelines/models/IPipelineDto';
import { IVisitResource } from '../../Tracking/models/IVisitResource';

export enum BoardType {
  Pipeline = 'Pipeline',
}
export interface IBoardVisitDto extends IVisitResource {
  userId: string;
  lastVisitedTime: string;
  conversationsCount: number;
  mentionsCount: number;
  contactsCount: number;
  tasksCount: number;
}

export interface IBoardDto {
  id: string;
  projectId: string;
  resourceId?: string;
  displayName: string;
  name: string;
  type: BoardType;
  description: string;
  memberCount: number;
  photoUrl: string;
  createdTime: string;
  updatedTime: string;
  visits: IBoardVisitDto[];
}

export interface IBoardPrivileges {
  canEdit: boolean;
  canDelete: boolean;
  canReadMembers: boolean;
  canInviteMember: boolean;
  canRemoveMember: boolean;
  canReadActivities: boolean;
  canWriteActivities: boolean;
}

export interface IUpdateBoardDto {
  name: string;
  description: string;
}

export interface ICreateBoardDto {
  name: string;
  description: string;
  type: BoardType;
  tenantId: string;
  // Union type here for all different boardType resources
  resourcePayload: IPipelineCreateDto | { [key: string]: any };
}

export const BoardTypeToResourceType: { [key in keyof typeof BoardType]: ResourceType } = {
  Pipeline: ResourceType.Pipeline,
};
