import { $getRoot, $getSelection, $setSelection, LexicalEditor } from 'lexical';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { useCallback } from 'react';
import { cutText } from '../../utils/stringUtils';
import { MARKDOWN_TRANSFORMERS } from '../../../features/Messaging/editor/messageEditorConfig';

export function useLexicalMarkdownToPlainText(editor?: LexicalEditor) {
  const markdownToPlainText = useCallback(
    (markdown: string, maxLength?: number) => {
      let content = '';
      if (!editor) return content;
      editor.update(
        () => {
          const previousEditorState = editor.getEditorState();
          const previousSelection = $getSelection();
          try {
            // Load the editor state with the markdown string
            $convertFromMarkdownString(markdown, MARKDOWN_TRANSFORMERS);
            // Get plain text from the editor state
            content = $getRoot().getTextContent();
          } finally {
            // Restore the editor state
            editor.setEditorState(previousEditorState);
            $setSelection(previousSelection);
          }
        },
        { discrete: true },
      );
      if (maxLength) {
        content = cutText(content, maxLength);
      }
      return content;
    },
    [editor],
  );

  return markdownToPlainText;
}
