import { useQuery } from '@tanstack/react-query';
import { getPipelineKpi } from '../services/pipelineKpiService';

interface IUsePipelineKpiKeyProps {
  pipelineId?: string;
  periodStartTime?: Date;
  periodEndTime?: Date;
}

export const pipelineKpiKeys = {
  all: ['pipelines-kpi'] as const,
  details: () => [...pipelineKpiKeys.all, 'detail'] as const,
  detail: (props: IUsePipelineKpiKeyProps) => [...pipelineKpiKeys.details(), props] as const,
};

const STALE_TIME = 1000 * 60;

export function usePipelineKpiQuery(
  pipelineId: string | undefined,
  periodStartTime: Date,
  periodEndTime: Date,
) {
  return useQuery({
    queryKey: pipelineKpiKeys.detail({ pipelineId, periodStartTime, periodEndTime }),
    queryFn: () => getPipelineKpi(pipelineId as string, periodStartTime, periodEndTime),
    staleTime: STALE_TIME,
    enabled: !!pipelineId,
  });
}
