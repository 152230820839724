import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { ICreateUpdateProjectDto, IProjectDto } from './models/IProjectDto';
import { useCreateUpdateProjectMutation } from './queries/projectQueries';
import { ProjectCreateEditForm, useProjectCreateEditForm } from './ProjectCreateEditForm';
import useModal from '../../shared/components/modal/hooks/useModal';

interface IProps {
  project?: IProjectDto;
}

export default function ProjectCreateEditModal({ project }: IProps) {
  const { tenantId } = useParams();
  const { closeModal } = useModal();
  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = useProjectCreateEditForm({ values: project });
  const createUpdateProject = useCreateUpdateProjectMutation();

  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleSubmit: SubmitHandler<ICreateUpdateProjectDto> = (_project) => {
    if (!tenantId) return;

    createUpdateProject.mutate(
      {
        id: project?.id,
        project: { name: _project.name, description: _project.name, tenantId },
      },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  const handleClose = () => {
    reset();
    closeModal();
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      closeModal();
    }
  };

  return (
    <>
      <ProjectCreateEditForm
        form={form}
        project={project}
        isDisabled={createUpdateProject.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </>
  );
}
