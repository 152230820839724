import * as React from 'react';
import { useState } from 'react';
import { format } from 'date-fns';
import { Calendar } from '../calendar/Calendar';
import { TimePickerInput } from './TimePicker/TimePicker';
import Label from '../text/Label';
import Button from '../buttons/Button';
import { ButtonColors } from '../../constants/ButtonColors';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ButtonGroup from '../buttons/ButtonGroup';
import { todayBaseDate } from '../../utils/dateUtils';

interface IProps {
  initialDate: string;
  hasTime?: boolean;
  onSelect?: (date: Date) => void;
}

export function DatePicker({ initialDate, hasTime = false, onSelect }: IProps) {
  const isMobile = useCheckMobileScreen();

  const [date, setDate] = useState<Date | undefined>(
    initialDate ? new Date(initialDate) : todayBaseDate,
  );

  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);

  const setDateIncludingTime = (timeString: string) => {
    setDate((prev) => {
      const [hours, minutes] = timeString.split(':').map(Number);
      const adjustedDate = prev ? new Date(prev.getTime()) : new Date(new Date().getTime());
      adjustedDate.setHours(hours, minutes, 0, 0);

      return adjustedDate;
    });
  };

  return (
    <>
      {isMobile ? (
        <>
          <div className="flex flex-row space-x-2 justify-center items-end mt-4 mb-2">
            <input
              type="date"
              className="border-gray-300 rounded-md"
              value={date?.toISOString().slice(0, 10)}
              onChange={(e) => setDate(new Date(e.target.value))}
            />
            {hasTime && (
              <input
                type="time"
                className="border-gray-300 rounded-md focus-ring-0 focus:outline-none"
                value={format(date as Date, 'HH:mm')}
                onChange={(e) => setDateIncludingTime(e.target.value)}
              />
            )}
          </div>
          <ButtonGroup>
            <Button
              color={ButtonColors.White}
              text={hasTime ? 'Set date & time' : 'Set date'}
              onClick={() => onSelect?.(date as Date)}
            />
          </ButtonGroup>
        </>
      ) : (
        <>
          <Calendar mode="single" selected={date} onSelect={setDate} initialFocus />
          {!hasTime && (
            <ButtonGroup>
              <Button
                color={ButtonColors.White}
                text="Set date"
                onClick={() => onSelect?.(date as Date)}
              />
            </ButtonGroup>
          )}
          {hasTime && (
            <div className="flex flex-row space-x-2 justify-center items-end">
              <div className="flex flex-col">
                <Label htmlFor="hours" text="Hours" />
                <TimePickerInput
                  picker="hours"
                  date={date}
                  setDate={setDate}
                  ref={hourRef}
                  onRightFocus={() => minuteRef.current?.focus()}
                />
              </div>
              <div className="flex flex-col">
                <Label htmlFor="minutes" text="Minutes" />
                <TimePickerInput
                  picker="minutes"
                  date={date}
                  setDate={setDate}
                  ref={minuteRef}
                  onLeftFocus={() => hourRef.current?.focus()}
                />
              </div>

              <Button
                color={ButtonColors.White}
                text="Set date & time"
                onClick={() => onSelect?.(date as Date)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
