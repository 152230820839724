import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import ActivitiesList from './ActivitiesList';
import ActivityCreateInput from './ActivityCreateInput';
import Howto from '../../shared/components/message-popups/HowTo';
import { ResourceType } from '../../shared/model/ResourceType';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';

const activityEntityTypeHelpTextMap = {
  [ResourceType.Contact]:
    'Keep track of your contacts by creating tasks. These tasks are kept private and not shared with your contact.',
  [ResourceType.Network]:
    'Collaborate with your business network. Create, assign and keep track over important activities.',
  [ResourceType.Project]:
    'Collaborate within your project. Create, assign and keep track over important activities.',
  [ResourceType.Board]:
    'Collaborate within your board. Create, assign and keep track over important activities.',
  [ResourceType.PipelineItem]:
    'Collaborate with stakeholders around specific items within your pipeline. Create, assign and keep track over important activities.',
} as { [key: string]: string | undefined };
interface IProps {
  resourceIds: ResourceIds;
  type: ResourceType;
}

export default function Activities({ resourceIds, type }: IProps) {
  if (!activityEntityTypeHelpTextMap[type]) {
    throw new Error(`Trying to resolve activities help text for resource of type ${type} `);
  }

  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <ActivityCreateInput
        resourceIds={resourceIds}
        resourceType={type}
        placeholder="Start typing to add a task..."
      />
      <ScrollBarWrapper id="activities-scrollbar">
        <ActivitiesList entityType={type} resourceIds={resourceIds} />
      </ScrollBarWrapper>
      <Howto title="Tasks" description={activityEntityTypeHelpTextMap[type]} />
    </div>
  );
}
