import { ChatBubbleLeftIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import {
  createNavigationSource,
  DetailListLayout,
} from '../../shared/components/layout/pages/DetailListLayout';
import useDebounce from '../../shared/hooks/useDebounce';
import { useChatNetworkQuery, useNetworksQuery } from './queries/networkQueries';
import { useStandaloneEditor } from '../../shared/lexical/useStandaloneEditor';
import { MESSAGE_EDITOR_CONFIG } from '../Messaging/editor/messageEditorConfig';
import { useLexicalMarkdownToPlainText } from '../../shared/lexical/markdown/useLexicalMarkdownToPlainText';
import { useNetworksActions } from './hooks/useNetworksActions';
import { ResourceType } from '../../shared/model/ResourceType';
import useEntityManifest from '../../shared/hooks/useEntityManifest';

export function NetworkDetailList() {
  const { icon: defaultIcon } = useEntityManifest(ResourceType.Network);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  const chatNetworksQuery = useChatNetworkQuery(debouncedSearchTerm);
  const alphabeticallySortedNetworksQuery = useNetworksQuery({ searchTerm: debouncedSearchTerm });

  const { editor } = useStandaloneEditor(MESSAGE_EDITOR_CONFIG);
  const markdownToPlainText = useLexicalMarkdownToPlainText(editor);

  const actions = useNetworksActions();

  return (
    <DetailListLayout
      title="Networks"
      navigationSources={[
        createNavigationSource({
          id: 'chat',
          title: 'Chat',
          icon: ChatBubbleLeftIcon,
          query: chatNetworksQuery,
          itemDataSelector: (item) => ({
            id: item.id,
            to: item.id,
            title: item.name,
            information: item.latestMessage ? markdownToPlainText(item.latestMessage?.text) : '',
            secondaryInformation: item.latestMessage?.createdTimeFriendly ?? '',
            imgSource: item.photoUrl || defaultIcon,
            highlightInformation: item.latestMessage?.isUnread ?? false,
            showHighlightIndicator: item.latestMessage?.isUnread ?? false,
          }),
        }),
        createNavigationSource({
          id: 'az',
          title: 'A-Z',
          icon: ClipboardDocumentCheckIcon,
          query: alphabeticallySortedNetworksQuery,
          itemDataSelector: (item) => ({
            id: item.id,
            to: item.id,
            title: item.name,
            information: item.name, // dumb but copied from the old code
            imgSource: item.photoUrl || defaultIcon,
            highlightInformation: false,
            showHighlightIndicator: false,
          }),
          itemGroupSelector: (item) => item.name.charAt(0).toUpperCase(),
        }),
      ]}
      actions={actions}
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
      searchPlaceholder="Search to find networks"
    />
  );
}
