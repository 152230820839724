import { IVisitResource } from '../../features/Tracking/models/IVisitResource';
import { IMessageDto } from './IMessageDto';

export enum NetworkVisibilities {
  Visible = 'Visible',
  Hidden = 'Hidden',
}

export enum NetworkTypes {
  Private = 'Private',
  Public = 'Public',
}

export interface INetworkVisitDto extends IVisitResource {
  userId: string;
  lastVisitedTime: string;
  conversationsCount: number;
  mentionsCount: number;
  contactsCount: number;
  tasksCount: number;
}

export interface INetworkDto {
  id: string;
  tenantId: string;
  name: string;
  description: string;
  type: NetworkTypes;
  visibility: NetworkVisibilities;
  memberCount: number;
  photoUrl?: string;
  visits: INetworkVisitDto[];
  // TODO: Investigate if the following properties should be
  // on this interface or if the belong anywhere else
  // The following variables are added only in frontend:
  latestMessage?: IMessageDto;
  hasUnreadMessages: boolean;
}

export interface ICreateUpdateNetworkDto {
  name: string;
  description: string;
  type: NetworkTypes;
  visibility: NetworkVisibilities;
}
