import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import Label from '../text/Label';

interface ICurrencySelectorProps<T extends FieldValues> {
  name: Path<T>;
  register: UseFormRegister<T>;
}

export const currencies = [
  {
    label: 'SEK',
    value: 'SEK',
  },
  {
    label: 'NOK',
    value: 'NOK',
  },
  {
    label: 'DKK',
    value: 'DKK',
  },
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'GBP',
    value: 'GBP',
  },
];

export default function CurrencySelector<T extends object>({
  name,
  register,
}: ICurrencySelectorProps<T>) {
  const { ...registration } = register(name);

  return (
    <>
      <Label htmlFor={name} text="Currency" />
      <select
        {...registration}
        className="shadow-sm placeholder:text-gray-400 block w-full border-gray-300 rounded-md focus:ring-0 focus:border-zinc-400"
      >
        {currencies.map((currency) => (
          <option key={currency.value} value={currency.value}>
            {currency.label}
          </option>
        ))}
      </select>
    </>
  );
}
