import { Fragment } from 'react';
import { IActionItem, IActionItemCustomIcon } from '../../model/IActionItem';
import {
  DropdownMenuBadge,
  DropdownMenuDivider,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuShortcut,
} from './DropdownMenu';
import MenuItemActionButton from '../menus/MenuItemActionButton';

export function renderDropdownMenuItems(menuItems: IActionItem[]) {
  const anyIcon = menuItems.some(({ icon }) => icon);
  return menuItems.map(
    ({
      id,
      icon,
      title,
      shortcut,
      addDividerAfter: useDivider,
      badgeCount,
      disabled,
      type,
      ...props
    }) => (
      <Fragment key={id}>
        {type === 'header' ? (
          <DropdownMenuLabel>{title}</DropdownMenuLabel>
        ) : (
          <DropdownMenuItem inset={anyIcon && !icon} {...props} disabled={disabled}>
            {icon && <DropdownMenuIcon icon={icon} />}
            {title}
            {shortcut && <DropdownMenuShortcut>{shortcut}</DropdownMenuShortcut>}
            {!shortcut && <DropdownMenuBadge count={badgeCount} />}
          </DropdownMenuItem>
        )}

        {useDivider && <DropdownMenuDivider />}
      </Fragment>
    ),
  );
}

export function renderMenuItems(menuItems: IActionItemCustomIcon[]) {
  return menuItems.map(({ id, icon, title, addDividerAfter: useDivider, disabled, onClick }) => (
    <MenuItemActionButton
      key={id}
      text={title}
      icon={icon}
      disabled={disabled}
      useDivider={useDivider}
      onClick={onClick}
    />
  ));
}
