import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { deleteMyUserPhoto, uploadMyUserPhoto } from '../services/userPhotoService';
import { userProfileKeys } from './userProfileQueries';

type MutateMyUserPhotoProps = {
  file?: Blob;
};

export function useMyUserPhotoMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ file }: MutateMyUserPhotoProps) => {
      if (typeof file === 'undefined') {
        return deleteMyUserPhoto();
      }

      return uploadMyUserPhoto(file);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userProfileKeys.detail('my') });
    },
    onError: dispatchError,
  });
}
