import { useState } from 'react';
import { IContactDto } from '../Contacts/models/IContactDto';
import useDebounce from '../../shared/hooks/useDebounce';
import { useContactsQuery } from '../Contacts/queries/contactQueries';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import SearchBar from '../../shared/components/search/SearchBar';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SelectList from '../../shared/components/lists/SelectList';
import FlexItem from '../../shared/components/structure/FlexItem';
import useModal from '../../shared/components/modal/hooks/useModal';
import ButtonLikeElement from '../../shared/components/buttons/ButtonLikeElement';
import ContactCreateForm, { useContactCreateForm } from '../Contacts/ContactCreateForm';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import ContactAddIcon from '../../shared/components/icons/ContactAddIcon';
import { useMyUserId } from '../../shared/auth/accountHooks';

interface IContactCreateFormProps {
  onSuccess: (contact: IContactDto) => void;
}

function ContactCreateFormWrapper({ onSuccess }: IContactCreateFormProps) {
  const { closeModal } = useModal();
  const formObject = useContactCreateForm({ values: {} });
  // TenantId is current user here
  const tenantId = useMyUserId();

  return (
    <ContactCreateForm
      tenantId={tenantId ?? ''}
      onSuccess={(_contact) => {
        closeModal();
        onSuccess(_contact);
      }}
      onCancel={closeModal}
      form={formObject}
    />
  );
}

interface ISelectContactProps {
  onSelect: (contact: IContactDto) => void;
  onCreate: (contact: IContactDto) => void;
}

export function SelectContact({ onSelect, onCreate }: ISelectContactProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState<IContactDto | undefined>(undefined);

  const { openModal } = useModal();
  const debouncedSearchTerm = useDebounce(searchTerm);
  const resourceManifest = useEntityManifest(ResourceType.Contact);
  const contactsQuery = useContactsQuery({ searchTerm: debouncedSearchTerm });

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleSelectContact = (contact: IContactDto) => {
    setSelectedItem(contact);
    onSelect(contact);
  };

  if (!contactsQuery.isSuccess) return null;

  return (
    <FlexContainer direction="column">
      <FlexContainer justify="start" align="center">
        <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />

        <FlexItem className="ml-auto" self="center">
          <ButtonLikeElement
            onClick={() => {
              openModal({
                content: <ContactCreateFormWrapper onSuccess={onCreate} />,
              });
            }}
          >
            <ContactAddIcon className="w-7 h-7" />
          </ButtonLikeElement>
        </FlexItem>
      </FlexContainer>
      <ScrollBarWrapper className="max-h-40">
        <SelectList<IContactDto>
          data={contactsQuery.data ?? []}
          isLoading={contactsQuery.isLoading}
          itemIdProperty={({ id }) => id}
          itemHeadlineProperty={({ displayName }) => `${displayName}`}
          itemInformationProperty={({ company }) => company}
          itemImgSrcProperty={({ photoUrl }) => photoUrl || resourceManifest?.icon}
          selectedItem={selectedItem}
          onSelectItem={handleSelectContact}
        />
      </ScrollBarWrapper>
    </FlexContainer>
  );
}
