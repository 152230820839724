/* eslint-disable react/jsx-props-no-multi-spaces */
import { BellIcon } from '@heroicons/react/24/outline';
import { BellIcon as BellIconSolid } from '@heroicons/react/24/solid';
import { useContext, useMemo, useState } from 'react';
import {
  useFloating,
  FloatingFocusManager,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
} from '@floating-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../shared/context/context';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import {
  notificationKeys,
  useNotificationsQuery,
  useNotificationSubscription,
  useReadNotificationMutation,
} from './notificationQueries';
import NotificationsTray from './NotificationsTray';
import Text from '../../shared/components/text/Text';
import BorderActiveHorizontal from '../../shared/components/indicators/BorderActiveHorizontal';
import { getInfiniteQueryDataLength } from '../../shared/utils/queryClientUtils';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { Badge } from '../../shared/components/badge/Badge';
import { useToast } from '../../shared/components/toasts/use-toast';
import { useDispatchApiError } from '../../shared/hooks/useDispatchApiError';

export default function Notifications() {
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();
  const notificationsQuery = useNotificationsQuery();

  const dispatchError = useDispatchApiError();

  const { toast } = useToast();

  useNotificationSubscription();
  useNotificationSubscription({ isUnread: true });
  useNotificationSubscription({ notificationArea: 'Contact' });
  useNotificationSubscription({ notificationArea: 'Network' });
  useNotificationSubscription({ notificationArea: 'Board' });
  useNotificationSubscription({ notificationArea: 'Project' });
  useNotificationSubscription({ notificationArea: 'Organization' });

  const numberOfUnreadNotifications = useMemo(
    () => getInfiniteQueryDataLength(notificationsQuery.data, (n) => !n.isRead),
    [notificationsQuery.data],
  );

  const readNotification = useReadNotificationMutation();
  const handleClearNotifications = async () => {
    notificationsQuery.data?.pages
      .flat()
      .filter((n) => !n.isRead)
      .forEach((n) => {
        readNotification.mutate({ id: n.id });
      });
  };

  const handleToggleNotificationsTray = (_isOpen: boolean) => {
    if (!_isOpen) handleClearNotifications();
    setIsOpen(_isOpen);
    queryClient.invalidateQueries({ queryKey: notificationKeys.all });
  };

  const handleExecuteAction = (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => {
    promise
      .then(() => {
        if (action.type === 'Accept') {
          toast({
            title: 'Success',
            variant: 'success',
            description: notification.acceptMessage,
          });
        }
        notificationsQuery.setIsResponded(notification.id);
      })
      .catch(dispatchError)
      .finally(() => {
        if (action.invalidateQueryKeys.length === 0) return;

        setTimeout(() => {
          action.invalidateQueryKeys.forEach((queryKey) =>
            queryClient.invalidateQueries({ queryKey }),
          );
        }, 1000);
      });
  };

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: handleToggleNotificationsTray,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <button
        ref={refs.setReference}
        type="button"
        {...getReferenceProps({ className: 'relative' })}
      >
        <span className="sr-only">View notifications</span>
        {isOpen ? (
          <BellIconSolid className="h-6 w-6 text-cyan" aria-hidden="true" />
        ) : (
          <BellIcon className="h-6 w-6 text-gray-dark hover:text-cyan-dark" aria-hidden="true" />
        )}

        {numberOfUnreadNotifications > 0 && (
          <Badge className="absolute top-[-10px] right-[-10px] fade-in-element">
            <Text as="span" size="xSmall" weight="bold" color="white" leading="none">
              {numberOfUnreadNotifications}
            </Text>
          </Badge>
        )}
        {isOpen && <BorderActiveHorizontal thickness="thin" bottomOffset={-12} />}
      </button>

      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <FlexContainer
            className="z-40 absolute sm:h-[650px] w-96 right-0 sm:right-10 top-16 sm:top-12 bottom-0 sm:bottom-auto mt-0 bg-white sm:mr-2 rounded-none sm:rounded-md sm:ring-1 sm:ring-black sm:ring-opacity-5 shadow-lg"
            {...getFloatingProps()}
          >
            <NotificationsTray
              closeCallback={() => handleToggleNotificationsTray(false)}
              onExecuteAction={handleExecuteAction}
            />
          </FlexContainer>
        </FloatingFocusManager>
      )}
    </>
  );
}
