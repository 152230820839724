/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

// Hack. There seems to be some CSS somewhere triggering
// a small scroll on the textbox that is around 1-3 pixel.
const SCROLL_ERROR_ADJUSTMENT = 4;

// See: https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848
// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string | null) => {
  useEffect(() => {
    if (textAreaRef) {
      // Skip resize if there is only one row
      if (textAreaRef.scrollHeight + SCROLL_ERROR_ADJUSTMENT <= 50) return;

      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = `${scrollHeight + SCROLL_ERROR_ADJUSTMENT}px`;
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
