import { Link } from 'react-router-dom';
import ProfileAvatar from '../../avatar/ProfileAvatar';
import FlexContainer from '../../structure/FlexContainer';
import Text from '../../text/Text';

interface IProps {
  name: string;
  photoUrl: string;
  url?: string;
}

export default function ProfileRowCell({ name, photoUrl, url }: IProps) {
  return (
    <FlexContainer justify="start" align="center">
      <ProfileAvatar
        avatarProps={{
          src: photoUrl,
          widthClass: 'w-6',
          heightClass: 'h-6',
        }}
      />
      {name && !url && <>{name}</>}
      {name && url && (
        <Link to={url}>
          <Text brightness="dark" color="cyan" weight="semibold">
            {name}
          </Text>
        </Link>
      )}
    </FlexContainer>
  );
}
