import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { useMyUserProfileQuery } from '../../../../features/Profile/queries/userProfileQueries';
import ProfileAvatar, { IProfileAvatarImageProps } from '../../avatar/ProfileAvatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '../../drop-down-menu/DropdownMenu';

const defaultAvatarProps: IProfileAvatarImageProps = {
  src: '',
  alt: 'User',
  widthClass: 'w-7',
  heightClass: 'h-7',
};

function FallbackAvatar() {
  return <ProfileAvatar avatarProps={defaultAvatarProps} />;
}

export default function HeaderNavigation() {
  const { instance } = useMsal();
  const myProfileQuery = useMyUserProfileQuery();

  if (!myProfileQuery.isSuccess) {
    return <FallbackAvatar />;
  }

  const avatarProps = {
    ...defaultAvatarProps,
    src: myProfileQuery.data.photoUrl,
    alt: myProfileQuery.data.firstName,
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <ProfileAvatar avatarProps={avatarProps} />

        <span className="sr-only">Open user menu</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem asChild>
          <Link to="/profile">Your Profile</Link>
        </DropdownMenuItem>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Privacy & Terms</DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuItem asChild>
              <a href="/user_agreements/Privacy_Notice_v1.0.pdf" target="_blank">
                Privacy Notice
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <a href="/user_agreements/Terms_of_use_v1.0.pdf" target="_blank">
                Terms of Use
              </a>
            </DropdownMenuItem>
          </DropdownMenuSubContent>
          <DropdownMenuItem onClick={() => instance.logoutRedirect()}>Sign out</DropdownMenuItem>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
