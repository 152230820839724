import * as React from 'react';
import { Check, XIcon } from 'lucide-react';
import { cn } from '../../../lib/utils';
import Tooltip from '../tooltip/Tooltip';

type Status = 'on' | 'off';

export interface IStatusIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
  tooltipMessage?: string;
  status: Status;
}

const StatusIndicator = React.forwardRef<HTMLDivElement, IStatusIndicatorProps>(
  ({ tooltipMessage, status, className, ...props }, ref) => (
    <Tooltip message={tooltipMessage}>
      <div
        ref={ref}
        {...props}
        className={cn(
          'w-2.5 h-2.5 rounded-full flex items-center justify-center ring-1 ring-white',
          status === 'on' ? 'bg-green' : 'bg-gray-dark',
          className,
        )}
      >
        {status === 'on' && <Check className="w-2 h-2 text-green-dark" />}
        {status === 'off' && <XIcon className="w-2 h-2 text-white" />}
      </div>
    </Tooltip>
  ),
);
StatusIndicator.displayName = 'StatusIndicator';

export default StatusIndicator;
