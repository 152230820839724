import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { organizationKeys } from './organizationQueries';
import { deleteOrganizationPhoto, uploadOrganizationPhoto } from './organizationServices';

type MutateOrganizationPhotoProps = {
  id: string;
  file?: Blob;
};

export function useOrganizationPhotoMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id, file }: MutateOrganizationPhotoProps) => {
      if (typeof file === 'undefined') {
        return deleteOrganizationPhoto(id);
      }

      return uploadOrganizationPhoto(id, file);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.detail(id) });
      queryClient.invalidateQueries({ queryKey: organizationKeys.lists() });
    },
    onError: dispatchError,
  });
}
