/**
 * Divides an array into chunks of a specified size.
 *
 * @param array The array to be chunked.
 * @param chunkSize The size of each chunk.
 * @returns An array of chunks, where each chunk is an array containing elements from the array.
 * @template T The type of the elements in the array.
 */

export function chunkArray<T>(array: T[], chunkSize: number) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

/**
 * Recursively maps over a tree structure and flattens the results into a single-level array.
 *
 * @param nodes - The array of nodes to process.
 * @param mapFunc - The mapping function applied to each node
 * used transform the node into a desired value.
 * @returns A flattened array of mapped results.
 */
export function recursiveFlatMap<TData extends { children?: TData[] }, MappedType>(
  nodes: TData[],
  mapFunc: (node: TData) => MappedType,
): MappedType[] {
  return nodes.flatMap((node) => [
    mapFunc(node),
    ...(node.children ? recursiveFlatMap(node.children, mapFunc) : []),
  ]);
}
