type Thickness = 'thin' | 'thick';

interface IBorderActiveHorizontalProps {
  thickness?: Thickness;
  bottomOffset?: number;
}

const thicknessMapping: Record<Thickness, string> = {
  thin: 'h-[3px]',
  thick: 'h-[4px]',
};

export default function BorderActiveHorizontal({
  thickness = 'thin',
  bottomOffset = 0,
}: IBorderActiveHorizontalProps) {
  const thicknessClass = thicknessMapping[thickness];

  const className = `${thicknessClass}  absolute bg-cyan rounded-lg w-full`;

  return <div className={className} style={{ bottom: `${bottomOffset}px` }} />;
}
