/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
import { addClassNamesToElement, isHTMLElement } from '@lexical/utils';
import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  DecoratorNode,
  EditorConfig,
  LexicalNode,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import { Suspense } from 'react';
import ImageComponent from '../../components/images/Image';
import { IImage } from '../../model/internal/IImage';

function isHTMLImageElement(element: Node): element is HTMLImageElement {
  return isHTMLElement(element) && element.tagName === 'IMG';
}

function convertImageElement(element: HTMLElement): DOMConversionOutput {
  let node = null;
  if (isHTMLImageElement(element)) {
    const { src, alt } = element;
    node = $createImageNode({ src, alt });
  }
  return { node };
}

export type SerializedImageNode = Spread<
  { src: string; alt?: string; type: 'image'; version: 1 },
  SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string;

  __alt: string;

  constructor(src: string, alt?: string, key?: string) {
    super(key);
    this.__src = src;
    this.__alt = alt || '';
  }

  static getType(): string {
    return 'image';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__alt, node.__key);
  }

  static importDOM(): DOMConversionMap {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 1,
      }),
    };
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { src, alt } = serializedNode;
    const node = $createImageNode({ src, alt });
    return node;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement('span');
    addClassNamesToElement(span, config.theme.image);
    return span;
  }

  updateDOM(): false {
    return false;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img');
    element.src = this.__src;
    element.alt = this.__alt;
    return { element };
  }

  exportJSON(): SerializedImageNode {
    return {
      src: this.__src,
      alt: this.__alt,
      type: 'image',
      version: 1,
    };
  }

  getSrc(): string {
    return this.getLatest().__src;
  }

  getAlt(): string {
    return this.getLatest().__alt;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <ImageComponent src={this.__src} alt={this.__alt} maxHeight="max-h-80" />
      </Suspense>
    );
  }
}

export function $createImageNode({ src, alt }: IImage): ImageNode {
  return new ImageNode(src, alt);
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}
