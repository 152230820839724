import { PlusIcon } from 'lucide-react';
import Button from './Button';
import { ButtonColors } from '../../constants/ButtonColors';

interface IAddButtonProps {
  text?: string;
  onClick?: () => void;
}

export default function AddButton({ text = 'Add', onClick }: IAddButtonProps) {
  return (
    <Button
      onClick={onClick}
      color={ButtonColors.White}
      className="w-fit"
      icon={
        <PlusIcon className="h-6 w-6 text-cyan-alternative stroke-[2px] mr-1" aria-hidden="true" />
      }
      text={text}
    />
  );
}
