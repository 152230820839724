import { useState } from 'react';
import { INetworkDto } from '../../../../shared/model/INetwork';
import { SelectNetwork } from '../../../Selectors/SelectNetwork';
import Button from '../../../../shared/components/buttons/Button';
import { ButtonColors } from '../../../../shared/constants/ButtonColors';
import Header2 from '../../../../shared/components/headers/Header2';
import { IResourceProps } from './PipelineCreateItemPopover';
import FlexContainer from '../../../../shared/components/structure/FlexContainer';
import { useCreatePipelineItemMutation } from '../../queries/itemQueries';
import { useShareResourceMutation } from '../../queries/pipelineSharedResourcesQueries';
import { usePipelineStoreContext } from '../../context/pipeline-context';
import { ResourceType } from '../../../../shared/model/ResourceType';

export default function PipelineAddNetworkItem({ stageId, onSubmitComplete }: IResourceProps) {
  const [selectedNetwork, setSelectedNetwork] = useState<INetworkDto | undefined>(undefined);

  const createPipelineItemMutation = useCreatePipelineItemMutation();
  const shareResourceMutation = useShareResourceMutation();

  const { activePipelineSettings } = usePipelineStoreContext();

  const performSubmit = (network: INetworkDto) => {
    let sharedResourceId = '';

    shareResourceMutation
      .mutateAsync({
        pipelineId: activePipelineSettings.pipelineId,
        request: {
          resourceId: network.id,
          type: ResourceType.Network,
          isLiveUpdated: true,
        },
      })
      .then((result) => {
        sharedResourceId = result.id;

        createPipelineItemMutation.mutateAsync({
          pipelineId: activePipelineSettings.pipelineId,
          stageId,
          item: {
            name: network.name,
            value: 0,
            description: '',
            sharedResourceId,
          },
        });

        onSubmitComplete(false);
        setSelectedNetwork(undefined);
      });
  };

  const onSubmit = () => {
    if (!selectedNetwork) return;

    performSubmit(selectedNetwork);
  };

  const handleCreateNetwork = (network: INetworkDto) => {
    performSubmit(network);
  };

  return (
    <FlexContainer direction="column" gap="small">
      <Header2>Select Network</Header2>
      <SelectNetwork onSelect={setSelectedNetwork} onCreate={handleCreateNetwork} />
      <FlexContainer justify="end">
        <Button
          onClick={onSubmit}
          color={ButtonColors.Blue}
          text="Add"
          disabled={!selectedNetwork}
        />
      </FlexContainer>
    </FlexContainer>
  );
}
