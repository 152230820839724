import { IShareContactRequestDto } from '../model/IShareContactRequestDto';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export const getShareContactRequests = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/sharecontactrequests/${queryString}`)
    .then<IShareContactRequestDto[]>((response) => response.data.data);
};

export const acceptShareContactRequest = (id: string) =>
  apiClient.put(`v1/sharecontactrequests/${id}/accept`);

export const rejectShareContactRequest = (id: string) =>
  apiClient.put(`v1/sharecontactrequests/${id}/reject`);
