import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { IActivityItem } from '../../shared/components/activity/IActivityItem';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { ListLayout } from '../../shared/components/layout/pages/ListLayout';
import ActionRowCell from '../../shared/components/table/cells/ActionRowCell';
import { createDataTableColumnHelper } from '../../shared/components/table/DataTable';
import MemberRowCell from '../../shared/components/table/cells/MembersRowCell';

import ProfileRowCell from '../../shared/components/table/cells/ProfileRowCell';
import StandardRowCell from '../../shared/components/table/cells/StandardRowCell';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { INetworkDto } from '../../shared/model/INetwork';
import { ResourceType } from '../../shared/model/ResourceType';
import { createActivityColumnDefinition } from '../Tracking/trackingHelpers';
import useNetworkActions from './hooks/useNetworkActions';
import { useNetworksActions } from './hooks/useNetworksActions';
import { useNetworksInfiniteQuery } from './queries/networkQueries';
import IconRowCell from '../../shared/components/table/cells/IconRowCell';
import { createRoute } from '../../shared/components/navigation/helpers/routingHelpers';
import StandardHeaderCell from '../../shared/components/table/cells/StandardHeaderCell';

import { networkFilterConfig } from './config/networkFilterConfig';
import { useFilters } from '../../shared/components/filters/hooks/useFilters';

const activityHeaderItems = [
  { type: 'Contact', label: 'New contacts' },
  { type: 'Conversation', label: 'New conversations' },
  { type: 'Task', label: 'New tasks' },
  { type: 'Mention', label: 'New mentions' },
] as IActivityItem[];

const customFilterFunction: FilterFn<INetworkDto> = (row, columnId, filterValue) => {
  const value = row.getValue(columnId) as string;
  return value.toLowerCase().includes(filterValue.toLowerCase());
};

function useNetworkListColumns() {
  const myUserId = useMyUserId();
  const networkManifest = useEntityManifest(ResourceType.Network);
  const contactManifest = useEntityManifest(ResourceType.Contact);
  return useMemo(() => {
    const columnHelper = createDataTableColumnHelper<INetworkDto>();

    return [
      // columnHelper.multiSelect(),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        filterFn: customFilterFunction,
        minSize: 250,
        cell: ({ row, getValue }) => (
          <ProfileRowCell
            photoUrl={row.original.photoUrl || networkManifest.icon}
            url={createRoute('networks', row.original.id, 'info')}
            name={getValue()}
          />
        ),
      }),
      columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        filterFn: customFilterFunction,
        cell: ({ getValue }) => <StandardRowCell text={getValue()} />,
      }),
      columnHelper.accessor('memberCount', {
        meta: {
          size: '175px',
        },
        header: () => 'Members',
        cell: ({ getValue }) => (
          <MemberRowCell
            members={Array(getValue())
              .fill(1)
              .map((_, i) => ({ id: i.toString(), photoUrl: contactManifest.icon }))}
          />
        ),
      }),
      columnHelper.accessor('type', {
        id: 'privacy',
        meta: {
          size: '140px',
        },
        header: () => <StandardHeaderCell alignment="center">Privacy</StandardHeaderCell>,
        cell: ({ getValue }) => <IconRowCell icon={getValue() === 'Private' ? EyeOff : Eye} />,
        filterFn: ({ getValue }, columnId, filterValue: string[]) => {
          if (filterValue.length === 0) {
            return true;
          }
          return filterValue.includes(getValue(columnId));
        },
      }),
      columnHelper.accessor('visits', {
        ...createActivityColumnDefinition<INetworkDto>({
          id: 'visits',
          activityItems: activityHeaderItems,
          myUserId: myUserId || '',
        }),
      }),
      columnHelper.actions(({ row }) => (
        <ActionRowCell
          resolver={(isOpen) => useNetworkActions(isOpen ? row.original.id : undefined)}
        />
      )),
    ] as ColumnDef<INetworkDto>[];
  }, []);
}

export function NetworksList() {
  const columns = useNetworkListColumns();
  const filters = useFilters(networkFilterConfig);

  const networkQuery = useNetworksInfiniteQuery({ ...filters.filterState });

  const actions = useNetworksActions();

  if (networkQuery.isError) {
    return <ErrorComponent queryResults={[networkQuery]} />;
  }

  return (
    <ListLayout
      title="Networks"
      headerActions={actions}
      columns={columns}
      query={networkQuery}
      filters={filters}
    />
  );
}
