import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import {
  IMessageSubmitCommandProps,
  MESSAGE_SUBMIT_COMMAND,
  useMessageSubmitCommand,
} from './useMessageSubmitCommand';
import IconButton from '../../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../../shared/constants/ButtonColors';

export function MessageEditorSubmitButtonPlugin({
  onSubmit,
  replyToMessage,
}: IMessageSubmitCommandProps) {
  const [editor] = useLexicalComposerContext();
  useMessageSubmitCommand({ onSubmit, replyToMessage });
  return (
    <IconButton
      onClick={() => editor.dispatchCommand(MESSAGE_SUBMIT_COMMAND, undefined)}
      icon={<PaperAirplaneIcon className="cursor-pointer h-6 w-6 text" />}
      color={ButtonColors.Gray}
    />
  );
}
