import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Activity from './Activity';
import { useActivitiesPagesQuery, useMoveActivityMutation } from './queries/activitiesQueries';
import { CalculateDragDropPositions } from '../../shared/utils/dragDropUtils';
import DroppableStyled from '../../shared/components/drag-and-drop/DroppableStyled';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { IActivityDto } from '../../shared/model/IActitityDto';
import Text from '../../shared/components/text/Text';
import ListEmptyState from '../../shared/components/lists/ListEmptyState';
import { ListLoader } from '../../shared/components/lists/ListLoader';
import { ResourceType } from '../../shared/model/ResourceType';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';

interface IProps {
  entityType: ResourceType;
  resourceIds: ResourceIds;
}

export default function ActivitiesList({ resourceIds, entityType }: IProps) {
  const activitiesQuery = useActivitiesPagesQuery(resourceIds, entityType);
  const activitiesFlat = activitiesQuery.isSuccess ? activitiesQuery.data.pages.flat() : [];
  const moveActivityMutation = useMoveActivityMutation();

  const handleMove = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!activitiesFlat) return;
    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const { moveToPositionAfterItemId } = CalculateDragDropPositions<IActivityDto>(
      activitiesFlat,
      activitiesFlat,
      draggableId,
      destination.index,
    );

    moveActivityMutation.mutate({
      resourceIds,
      entityType,
      activityId: draggableId as string,
      activityMove: { moveToPositionAfterActivityId: moveToPositionAfterItemId },
      destinationIndex: destination.index,
    });
  };

  if (activitiesQuery.isError) {
    return <ErrorComponent queryResults={[activitiesQuery]} />;
  }

  if (!activitiesQuery.isLoading && activitiesQuery.data?.pages.length === 0) {
    return (
      <ListEmptyState>Get organized and manage your contacts by creating tasks.</ListEmptyState>
    );
  }

  if (activitiesQuery.isPending) {
    return <ListLoader />;
  }

  return (
    <DragDropContext onDragEnd={handleMove}>
      <DroppableStyled>
        <ul>
          <InfiniteScroll
            scrollableTarget="activities-scrollbar"
            next={() => activitiesQuery?.fetchNextPage()}
            hasMore={activitiesQuery?.hasNextPage || false}
            loader={
              <div className="py-4 text-center">
                <Text as="p" size="small">
                  Loading...
                </Text>
              </div>
            }
            dataLength={activitiesFlat.length}
          >
            {activitiesFlat.map((activity: IActivityDto, index: number) => (
              <Draggable key={activity.id} draggableId={activity.id} index={index}>
                {(provided) => (
                  <Activity
                    draggableProvided={provided}
                    key={activity.id}
                    entityType={entityType}
                    resourceIds={resourceIds}
                    activity={activity}
                  />
                )}
              </Draggable>
            ))}
          </InfiniteScroll>
        </ul>
      </DroppableStyled>
    </DragDropContext>
  );
}
