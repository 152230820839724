import { Fragment } from 'react';
import { Badge } from '../badge/Badge';
import FlexContainer from '../structure/FlexContainer';
import ProfileAvatar from './ProfileAvatar';

export interface IProfileAvatarSlim {
  id: string;
  photoUrl?: string;
}

interface IAvatarGroupProps {
  avatars: IProfileAvatarSlim[];
  maxCount: number;
}

export default function ProfileAvatarGroup({ avatars, maxCount }: IAvatarGroupProps) {
  const difference = avatars.length - maxCount;
  return (
    <>
      {avatars.length === 0 ? (
        '-'
      ) : (
        <FlexContainer gap="none" className="-space-x-2 *:ring-2 *:ring-white items-center ">
          {avatars.slice(0, maxCount).map((avatar) => (
            <Fragment key={avatar.id}>
              <ProfileAvatar
                avatarProps={{
                  src: avatar.photoUrl,
                  widthClass: 'w-6',
                  heightClass: 'h-6',
                }}
              />
            </Fragment>
          ))}
          {difference > 0 && <Badge className="w-6 h-6 bg-cyan-dark">+{difference}</Badge>}
        </FlexContainer>
      )}
    </>
  );
}
