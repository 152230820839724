import { HTMLProps, useEffect, useRef } from 'react';
import { As } from '../../utils/types';

type ValidTags = 'div' | 'li';

type ScrollIntoViewProps = As<ValidTags> &
  HTMLProps<HTMLElement> & {
    active?: boolean;
  };

export function ScrollIntoView({ as = 'div', active = false, ...rest }: ScrollIntoViewProps) {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (active && ref.current) {
      ref.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [active]);

  const Tag: string = as;
  const attributes: HTMLProps<HTMLElement> = { ref, ...rest };
  return <Tag {...attributes} />;
}
