import { ButtonColors } from '../../../constants/ButtonColors';

export function resolveDeleteConfirm(entityName: string) {
  return {
    title: `Delete ${entityName}`,
    description: `Are you sure you want to delete this ${entityName}?`,
    actionText: 'Delete',
    actionColor: ButtonColors.Red,
  };
}

export function resolveLeaveConfirm(entityName: string) {
  return {
    title: `Leave ${entityName}`,
    description: `Are you sure you want to leave this ${entityName}?`,
    actionText: 'Leave',
    actionColor: ButtonColors.Red,
  };
}
