/**
 * Copied this from the Lexical repo. Unreleased code. Should be removed when Lexical is updated.
 * If they correct their version because I had to modify interface and return type.
 */

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';
import { MutableRefObject } from 'react';
import * as React from 'react';

/**
 *
 * Use this plugin to access the editor instance outside of the
 * LexicalComposer. This can help with things like buttons or other
 * UI components that need to update or read EditorState but need to
 * be positioned outside the LexicalComposer in the React tree.
 */
export function EditorRefPlugin({
  editorRef,
}: {
  editorRef: React.RefCallback<LexicalEditor> | MutableRefObject<LexicalEditor | null>;
}) {
  const [editor] = useLexicalComposerContext();
  if (typeof editorRef === 'function') {
    editorRef(editor);
    return null;
  }

  // eslint-disable-next-line no-param-reassign
  editorRef.current = editor;
  return null;
}
