import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Fragment } from 'react';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';
import Text from '../../shared/components/text/Text';
import { UserProfileDto } from '../Profile/models/UserProfile';
import { useUserProfileQuery } from '../Profile/queries/userProfileQueries';
import { RequestLoader } from './RequestLoader';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import { renderDateTime } from '../Notifications/NotificationList';
import { NotificationListItemLayout } from '../Notifications/NotificationListItemLayout';

interface IRequest {
  id: string;
  targetUserId?: string;
  createdTime?: string | Date;
}

type ResourceQueryFn<TRequest extends IRequest, TResource> = (
  request: TRequest,
) => UseQueryOptions<TResource>;

type IRequestAction = {
  text: string;
  onClick: () => void;
  color?: ButtonColors;
  disabled?: boolean;
};

interface IProps<TRequest extends IRequest, TResource> {
  requests: TRequest[];
  resourceQuery?: ResourceQueryFn<TRequest, TResource>;
  title?: (request: TRequest, user?: UserProfileDto) => string;
  text: (request: TRequest, resource?: TResource) => React.ReactNode;
  actions?: (request: TRequest) => IRequestAction[];
}

type RequestDataRenderFn<TRequest extends IRequest, TResource> = (
  request: TRequest,
  resource: TResource,
  user?: UserProfileDto,
) => React.ReactElement;

interface IRequestDataProps<TRequest extends IRequest, TResource> {
  request: TRequest;
  render: RequestDataRenderFn<TRequest, TResource>;
}

interface IRequestDataWithResourceProps<TRequest extends IRequest, TResource>
  extends IRequestDataProps<TRequest, TResource> {
  resourceQuery: ResourceQueryFn<TRequest, TResource>;
}

function RequestData<TRequest extends IRequest>({
  request,
  render: renderFunction,
}: IRequestDataProps<TRequest, undefined>) {
  const userProfileQuery = useUserProfileQuery(request.targetUserId);

  if (request.targetUserId && userProfileQuery.isPending) {
    return <RequestLoader />;
  }

  if (!request.targetUserId || userProfileQuery.isSuccess) {
    return renderFunction(request, undefined, userProfileQuery.data);
  }

  return null;
}

function RequestDataWithResource<TRequest extends IRequest, TResource>({
  request,
  render: renderFunction,
  resourceQuery: resourceQueryOptionsFn,
}: IRequestDataWithResourceProps<TRequest, TResource>) {
  const userProfileQuery = useUserProfileQuery(request.targetUserId);
  const resourceQuery = useQuery(resourceQueryOptionsFn(request));

  if ((request.targetUserId && userProfileQuery.isPending) || resourceQuery.isFetching) {
    return <RequestLoader />;
  }

  if ((!request.targetUserId || userProfileQuery.isSuccess) && resourceQuery.isSuccess) {
    return renderFunction(request, resourceQuery.data, userProfileQuery.data);
  }

  return <div />;
}

export function RequestItems<TRequest extends IRequest, TResource>({
  requests,
  resourceQuery,
  title: titleProp,
  text,
  actions,
}: IProps<TRequest, TResource>) {
  const render: RequestDataRenderFn<TRequest, TResource | undefined> = (
    request,
    resource,
    user,
  ) => {
    let title = '';
    if (titleProp) {
      title = titleProp(request, user);
    } else if (user) {
      title = `${user.firstName} ${user.lastName}`;
    }

    const linkToUserId = user ? user.id : '';
    const photoUrl = user ? user.photoUrl : '';

    return (
      <NotificationListItemLayout<TRequest>
        item={request}
        renderAvatar={(_) => (
          <>
            {linkToUserId ? (
              <ContactUserProfileLink userId={linkToUserId}>
                <ProfileAvatar
                  avatarProps={{
                    src: photoUrl || '',
                    widthClass: 'w-8',
                    heightClass: 'h-8',
                  }}
                />
              </ContactUserProfileLink>
            ) : (
              <ProfileAvatar
                avatarProps={{
                  src: photoUrl || '',
                  widthClass: 'w-8',
                  heightClass: 'h-8',
                }}
              />
            )}
          </>
        )}
        renderTitle={(_) => (
          <ContactUserProfileLink
            userId={linkToUserId}
            className="text-sm font-semibold flex flex-row items-center"
          >
            {title}
          </ContactUserProfileLink>
        )}
        renderContent={() => (
          <Text as="p" leading="relaxed" size="xSmall" weight="normal">
            {text(request, resource)}
          </Text>
        )}
        renderContentRight={(_request) =>
          _request.createdTime && (
            <Text as="span" size="xSmall" brightness="light">
              {renderDateTime(_request.createdTime)}
            </Text>
          )
        }
        renderActions={() => (
          <>
            {actions && (
              <>
                {actions(request).map(({ text: _text, color, disabled, onClick }) => (
                  <Button
                    key={_text}
                    text={_text}
                    size="small"
                    className="relative left-2"
                    color={color || ButtonColors.White}
                    disabled={disabled}
                    onClick={onClick}
                  />
                ))}
              </>
            )}
          </>
        )}
        useDivider
      />
    );
  };

  return (
    <>
      {requests.map((request) => (
        <Fragment key={request.id}>
          {resourceQuery ? (
            <RequestDataWithResource
              request={request}
              resourceQuery={resourceQuery}
              render={render}
            />
          ) : (
            <RequestData request={request} render={render} />
          )}
        </Fragment>
      ))}
    </>
  );
}
