import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import CountUp from 'react-countup';
import { Share2Icon, Tag, UserPlusIcon } from 'lucide-react';
import { IPipelineItemDto } from './models/IPipelineItemDto';
import { CalculateDragDropPositions } from '../../shared/utils/dragDropUtils';
import { useMovePipelineItemMutation, pipelineItemKeys } from './queries/itemQueries';
import { useCreateStageMutation, useStagesQuery } from './queries/stageQueries';
import { periodTranlationsMappings, usePipelineStoreContext } from './context/pipeline-context';
import { usePipelineQuery } from './queries/pipelineQueries';
import PipelineStage from './PipelineStage';
import { usePipelineKpiQuery } from './queries/pipelineKpiQueries';
import { ViewName } from '../../shared/store/slices/viewSlice';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import Text from '../../shared/components/text/Text';
import AddButton from '../../shared/components/buttons/AddButton';
import { KpiIndicator } from '../../shared/components/kpi/KpiIndicator';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import Header2 from '../../shared/components/headers/Header2';

function PipelineCreateStage() {
  const { selectStage, activePipelineSettings, setActiveTab, setActiveSidePanelView } =
    usePipelineStoreContext();
  const createStageMutation = useCreateStageMutation();

  const handleCreateStage = () => {
    setActiveSidePanelView('EditPipeline');
    setActiveTab('stages');

    createStageMutation.mutate(
      {
        pipelineId: activePipelineSettings.pipelineId,
        stage: { name: 'New Stage', weight: 100 },
      },
      {
        onSuccess: (stage) => {
          selectStage(stage.id);
        },
      },
    );
  };

  return <AddButton text="Add stage" onClick={handleCreateStage} />;
}

function PipelineEmptyStages() {
  return (
    <FlexContainer
      direction="column"
      justify="center"
      align="center"
      className="h-[calc(100%-10rem)]"
    >
      <FlexContainer align="center" direction="column" className="w-80 md:w-96 text-center">
        <Header2>Start building your stages!</Header2>
        <Text>
          Build your business process in stages. Plan, budget and brainstorm with your contacts and
          networks and let the collaboration begin!
        </Text>
        <PipelineCreateStage />
      </FlexContainer>
    </FlexContainer>
  );
}

function PipelineHeaderContainer({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col items-start justify-center space-y-2">{children}</div>;
}

function PipelineMainContainer({ children }: { children: React.ReactNode }) {
  return (
    <section className="flex flex-1 flex-row overflow-x-scroll horizontal-scroll mt-4 min-h-0 overflow-y-hidden h-full md:h-[calc(100%-10rem)]">
      <ScrollBarWrapper>
        <div className="grid grid-flow-col gap-3">{children}</div>
      </ScrollBarWrapper>
    </section>
  );
}

export interface IPipelineProps {
  pipelineId: string;
}

export default function Pipeline({ pipelineId }: IPipelineProps) {
  const {
    activePipelineSettings,
    selectPipeline,
    setActiveSidePanelView,
    setActiveTab,
    isKPIsVisible,
    kpiPeriod,
    kpiPeriodKey,
  } = usePipelineStoreContext();

  const currentView: ViewName = 'pipeline';
  const { setSidePanelOpen } = useGlobalStore();

  const stagesQuery = useStagesQuery({ pipelineId });
  const pipelineQuery = usePipelineQuery(pipelineId);

  const pipelineKpiQuery = usePipelineKpiQuery(
    pipelineId,
    kpiPeriod.current.startTime,
    kpiPeriod.current.endTime,
  );

  const queryClient = useQueryClient();
  const movePipelineItemMutation = useMovePipelineItemMutation();

  useEffect(() => {
    if (pipelineId) {
      selectPipeline(pipelineId);
      setActiveSidePanelView('EditPipeline');
    }
  }, [pipelineId]);

  const handleMoveStage = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceStageItems: IPipelineItemDto[] = queryClient.getQueryData(
      pipelineItemKeys.list({
        pipelineId: activePipelineSettings.pipelineId,
        stageId: source.droppableId,
      }),
    ) as IPipelineItemDto[];

    const targetStageItems: IPipelineItemDto[] = queryClient.getQueryData(
      pipelineItemKeys.list({
        pipelineId: activePipelineSettings.pipelineId,
        stageId: destination.droppableId,
      }),
    ) as IPipelineItemDto[];

    const { moveToPositionAfterItemId } = CalculateDragDropPositions<IPipelineItemDto>(
      sourceStageItems,
      targetStageItems,
      draggableId,
      destination.index,
    );

    movePipelineItemMutation.mutate({
      pipelineId: activePipelineSettings.pipelineId,
      sourceStageId: source.droppableId,
      itemId: draggableId,
      destinationIndex: destination.index,
      item: {
        moveToPositionAfterItemId,
        moveToStageId: destination.droppableId,
      },
    });
  };

  const getBugetTextStyle = () => {
    if (!pipelineKpiQuery.data?.budgetVariance) {
      return 'text-cyan-alternative';
    }

    if (pipelineKpiQuery.data.budgetVariance === 0) {
      return 'text-yellow';
    }

    if (pipelineKpiQuery.data.budgetVariance < 0) {
      return 'text-red';
    }

    return 'text-green';
  };

  // Iv'e removed !pipelineKpiQuery.isSuccess temporarily to get rid of flickering and error when
  // switching periods. Need to find a new way to handle this so we can add it back and avoid elvis
  // operators all over the place below
  if (!pipelineQuery.isSuccess || !stagesQuery.isSuccess) {
    return <></>;
  }
  const kpiData = [
    // TODO: Add when backend suppport is implemented
    // {
    //   id: 'Messages',
    //   label: 'Messages',
    //   icon: <MessageCircleMore className="text-cyan" />,
    //   value: pipelineKpiQuery?.data?.numberOfNewContacts,
    //   trend: pipelineKpiQuery?.data?.numberOfNewContactsTrend,
    // },
    // {
    //   id: 'Activity',
    //   label: 'Activity',
    //   icon: <ClipboardIcon className="stroke-[#D527B7]" />,
    //   value: pipelineKpiQuery?.data?.numberOfNewContacts,
    //   trend: pipelineKpiQuery?.data?.numberOfNewContactsTrend,
    // },
    {
      id: 'Contacts',
      label: 'Contacts',
      icon: <UserPlusIcon className="stroke-[#6B9080]" />,
      value: pipelineKpiQuery?.data?.numberOfNewContacts,
      trend: pipelineKpiQuery?.data?.numberOfNewContactsTrend,
    },
    {
      id: 'Networks',
      label: 'Networks',
      icon: <Share2Icon className="stroke-[#884DB7]" />,
      value: pipelineKpiQuery?.data?.numberOfNewNetworks,
      trend: pipelineKpiQuery?.data?.numberOfNewNetworksTrend,
    },
    {
      id: 'Opportunities',
      label: 'Opportunities',
      icon: <Tag className="text-dark" />,
      value:
        (pipelineKpiQuery?.data?.numberOfNewNetworks ?? 0) +
        (pipelineKpiQuery?.data?.numberOfNewContacts ?? 0),
    },
  ];

  return (
    <>
      {isKPIsVisible && (
        <PipelineHeaderContainer>
          <FlexContainer direction="row" className="w-full py-2 mt-4 hidden md:flex">
            <div className="flex flex-col justify-between ml-2 w-64">
              <div className="flex flex-col">
                <Text as="h2" size="x2Large" weight="semibold" color="cyan" brightness="dark">
                  Total
                </Text>

                <div className="flex flex-row items-end ">
                  <CountUp
                    delay={0}
                    start={0}
                    end={pipelineQuery.data.value}
                    separator=" "
                    className="text-4xl text-cyan-alternative font-bold currency-suffix"
                  />
                  <span className="text-2xl text-cyan-light font-bold ml-2">
                    {pipelineQuery.data.currencyIsoCode}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mt-2">
                {pipelineKpiQuery?.data?.budgetVariance && pipelineKpiQuery.data.budgetAmount ? (
                  <>
                    <span className="text-sm text">
                      {periodTranlationsMappings[kpiPeriodKey]} budget:
                    </span>
                    <div className="flex flex-row">
                      <span className="text-sm text-cyan-alternative font-bold">
                        {new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 0 }).format(
                          pipelineKpiQuery.data.budgetAmount,
                        )}
                        {` ${pipelineQuery.data.currencyIsoCode}`}
                        <span className={`text-sm ml-2 font-bold ${getBugetTextStyle()}`}>
                          ({pipelineKpiQuery.data.budgetVariance > 0 ? '+' : ''}
                          {new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 0 }).format(
                            pipelineKpiQuery.data.budgetVariance,
                          )}
                          )
                        </span>
                      </span>
                    </div>
                  </>
                ) : (
                  <AddButton
                    onClick={() => {
                      setSidePanelOpen(currentView, true);
                      setActiveTab('budget');
                      setActiveSidePanelView('EditPipeline');
                    }}
                    text="Add budget"
                  />
                )}
              </div>
            </div>

            <FlexContainer justify="end">
              <KpiIndicator data={kpiData} />
            </FlexContainer>
          </FlexContainer>
        </PipelineHeaderContainer>
      )}
      {activePipelineSettings.pipelineId && stagesQuery.data.length ? (
        <PipelineMainContainer>
          {activePipelineSettings.pipelineId && (
            <DragDropContext onDragEnd={handleMoveStage}>
              {stagesQuery.data.map((_stage) => (
                <PipelineStage key={`Stage-${_stage.id}`} stage={_stage} />
              ))}
            </DragDropContext>
          )}

          <PipelineCreateStage />
        </PipelineMainContainer>
      ) : (
        <PipelineEmptyStages />
      )}
    </>
  );
}
