import { useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ShareIcon, UsersIcon } from '@heroicons/react/24/outline';
import {
  ShareIcon as ShareIconSolid,
  UsersIcon as UsersIconSolid,
} from '@heroicons/react/24/solid';
import FlexContainer from '../../../../shared/components/structure/FlexContainer';
import { defineStepper, Step } from '../../../../shared/components/stepper';
import Button from '../../../../shared/components/buttons/Button';
import { ButtonColors } from '../../../../shared/constants/ButtonColors';
import { PipelineCreateItemForm } from './PipelineCreateItemForm';
import Header2 from '../../../../shared/components/headers/Header2';
import { IContactDto } from '../../../Contacts/models/IContactDto';
import { ResourceType } from '../../../../shared/model/ResourceType';
import { INetworkDto } from '../../../../shared/model/INetwork';
import convertFormattedCurrencyToNumber from '../../../../shared/utils/numberUtils';
import { usePipelineStoreContext } from '../../context/pipeline-context';
import { usePipelineQuery } from '../../queries/pipelineQueries';
import { Checkbox } from '../../../../shared/components/checkbox/Checkbox';
import Label from '../../../../shared/components/text/Label';
import Text from '../../../../shared/components/text/Text';
import ProfileAvatar from '../../../../shared/components/avatar/ProfileAvatar';
import Header3 from '../../../../shared/components/headers/Header3';
import { useCreatePipelineItemMutation } from '../../queries/itemQueries';
import { useShareResourceMutation } from '../../queries/pipelineSharedResourcesQueries';
import { SelectContact } from '../../../Selectors/SelectContact';
import { SelectNetwork } from '../../../Selectors/SelectNetwork';
import InfoIcon from '../../../../shared/components/info/InfoIcon';
import IconSwitch from '../../../../shared/components/switch/IconSwitch';
import { IResourceProps } from './PipelineCreateItemPopover';

type ResourceView = 'contact' | 'network';

interface IContactDtoMetaData {
  emailAddresses: { value: string }[];
  phoneNumbers: { value: string }[];
  title: string;
  company: string;
  photoUrl: string;
  displayName: string;
}

interface IContactSyncSettings {
  syncSettings: {
    email: boolean;
    phone: boolean;
    title: boolean;
    company: boolean;
    /* @deprecated Will be replaced by granular sync */
    isLiveUpdated: boolean;
  };
}

interface ICreateResourceItem<T = unknown> extends IContactSyncSettings {
  name: string;
  value: number;
  description: string;
  resourceId: string;
  resourceType: ResourceType;
  metaData?: T;
}

const { useStepper } = defineStepper(
  { id: 'step-1', title: 'Add Opportunity' },
  { id: 'step-2', title: 'Connect Resource' },
  // TODO: Add back when backend support for granular sync is added
  //   {
  //     id: 'step-3',
  //     title: 'Sync Information',
  //     description:
  //       'If sync is enabled, changes in the resource will be reflected in the opportunity.',
  //   },
);

interface IStepWrapperProps {
  step: Step;
  children: React.ReactNode;
}

function StepWrapper({ children, step }: IStepWrapperProps) {
  return (
    <FlexContainer direction="column">
      <FlexContainer direction="row" gap="none" justify="between">
        <Header2>{step.title}</Header2>
        {step.description && <InfoIcon text={step.description} />}
      </FlexContainer>
      <FlexContainer direction="column">{children}</FlexContainer>
    </FlexContainer>
  );
}

function ItemInformation() {
  const { activePipelineSettings } = usePipelineStoreContext();
  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);

  return <PipelineCreateItemForm currencyIsoCode={pipelineQuery.data?.currencyIsoCode || 'SEK'} />;
}

interface ISelectResourceProps {
  onSelectContact: (contact: IContactDto) => void;
  onCreateContact: (contact: IContactDto) => void;
  onSelectNetwork: (network: INetworkDto) => void;
  onCreateNetwork: (network: INetworkDto) => void;
}

function SelectResource({
  onSelectContact,
  onCreateContact,
  onSelectNetwork,
  onCreateNetwork,
}: ISelectResourceProps) {
  const [resourceView, setResourceView] = useState<ResourceView>('contact');

  return (
    <FlexContainer direction="column">
      <FlexContainer align="start" className="absolute right-2 top-3">
        <IconSwitch<ResourceView>
          items={[
            {
              value: 'contact',
              icon: <UsersIcon className="w-5 h-5 text-cyan-dark" />,
              iconActive: <UsersIconSolid className="w-5 h-5 text-cyan" />,
            },
            {
              value: 'network',
              icon: <ShareIcon className="w-5 h-5 text-cyan-dark" />,
              iconActive: <ShareIconSolid className="w-5 h-5 text-cyan" />,
            },
          ]}
          onChange={(value) => setResourceView(value)}
        />
      </FlexContainer>

      {resourceView === 'contact' ? (
        <SelectContact onSelect={onSelectContact} onCreate={onCreateContact} />
      ) : (
        <SelectNetwork onSelect={onSelectNetwork} onCreate={onCreateNetwork} />
      )}
    </FlexContainer>
  );
}

function SyncContactInformation() {
  const { getValues, control } = useFormContext<ICreateResourceItem<IContactDtoMetaData>>();

  const { metaData } = getValues() || {};

  return (
    <FlexContainer direction="column" gap="small">
      <FlexContainer direction="row" gap="small" align="center">
        <ProfileAvatar
          avatarProps={{
            src: metaData?.photoUrl,
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />
        <Header3>{metaData?.displayName}</Header3>
      </FlexContainer>

      <FlexContainer direction="column" gap="none">
        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.isLiveUpdated"
            control={control}
            render={({ field }) => (
              <FlexContainer gap="small" align="center">
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />

                <Text size="small">Keep in sync </Text>
              </FlexContainer>
            )}
          />
        </FlexContainer>

        {/* TODO: Add back when backend support for granular sync is added */}
        {/* <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.email"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Email" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.emailAddresses.map((email) => email.value).join(', ')}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer>

        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.phone"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Phone" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.phoneNumbers.map((phone) => phone.value).join(', ')}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer>
        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.title"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Title" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.title}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer>
        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.company"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Company" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.company}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer> */}
      </FlexContainer>
    </FlexContainer>
  );
}

// TODO: Add back when backend support for granular sync is added
function SyncContactInformationAdvanced() {
  const { getValues, control } = useFormContext<ICreateResourceItem<IContactDtoMetaData>>();

  const { metaData } = getValues() || {};

  return (
    <FlexContainer direction="column" gap="small">
      <FlexContainer direction="row" gap="small" align="center">
        <ProfileAvatar
          avatarProps={{
            src: metaData?.photoUrl,
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />
        <Header3>{metaData?.displayName}</Header3>
      </FlexContainer>

      <FlexContainer direction="column" gap="none">
        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.email"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Email" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.emailAddresses.map((email) => email.value).join(', ')}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer>

        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.phone"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Phone" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.phoneNumbers.map((phone) => phone.value).join(', ')}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer>
        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.title"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Title" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.title}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer>
        <FlexContainer direction="row" gap="small">
          <Controller
            name="syncSettings.company"
            control={control}
            render={({ field }) => (
              <>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FlexContainer direction="column" gap="none">
                  <Label text="Company" />
                  <Text size="xSmall" brightness="light">
                    {metaData?.company}
                  </Text>
                </FlexContainer>
              </>
            )}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
}

export default function PipelineCreateOpportunityItem({
  stageId,
  onSubmitComplete,
}: IResourceProps) {
  const stepper = useStepper();

  const createPipelineItemMutation = useCreatePipelineItemMutation();
  const shareResourceMutation = useShareResourceMutation();

  const { activePipelineSettings } = usePipelineStoreContext();

  const methods = useForm<ICreateResourceItem>({
    defaultValues: {
      name: '',
      value: 0,
      description: '',
      metaData: undefined,
      syncSettings: {
        email: false,
        phone: false,
        title: false,
        company: false,
      },
    },
  });

  const handleSelectContact = (contact: IContactDto) => {
    methods.setValue('resourceId', contact.id);
    methods.setValue('resourceType', ResourceType.Contact);
    methods.setValue('metaData', {
      emailAddresses: contact.emailAddresses,
      phoneNumbers: contact.phoneNumbers,
      title: contact.title,
      company: contact.company,
      photoUrl: contact.photoUrl,
      displayName: contact.displayName,
    });
    methods.trigger('resourceType');
  };

  const handleSelectNetwork = (network: INetworkDto) => {
    methods.setValue('resourceId', network.id);
    methods.setValue('resourceType', ResourceType.Network);
    methods.trigger('resourceType');
  };

  const onSubmit = (data: ICreateResourceItem) => {
    const value = convertFormattedCurrencyToNumber(data.value);

    let sharedResourceId;

    if (data.resourceId && data.resourceType) {
      shareResourceMutation
        .mutateAsync({
          pipelineId: activePipelineSettings.pipelineId,
          request: {
            resourceId: data.resourceId,
            type: data.resourceType,
            // TODO: Remove back when backend support for granular sync is added
            isLiveUpdated: true,
          },
        })
        .then((result) => {
          sharedResourceId = result.id;

          createPipelineItemMutation
            .mutateAsync({
              pipelineId: activePipelineSettings.pipelineId,
              stageId,
              item: {
                name: data.name,
                value,
                description: data.description,
                sharedResourceId,
              },
            })
            .then(() => {
              onSubmitComplete(false);
              methods.reset();
            });
        });
    }
  };

  const isFinalizeConditionMet = () => !!methods.getValues('resourceType');

  const handleCreateContact = (_contact: IContactDto) => {
    handleSelectContact(_contact);
    onSubmit(methods.getValues());
  };

  const handleCreateNetwork = (_network: INetworkDto) => {
    handleSelectNetwork(_network);
    onSubmit(methods.getValues());
  };

  return (
    <FormProvider {...methods}>
      <form>
        <FlexContainer direction="column">
          {stepper.when('step-1', (step: Step) => (
            <StepWrapper step={step}>
              <ItemInformation />
            </StepWrapper>
          ))}
          {stepper.when('step-2', (step: Step) => (
            <StepWrapper step={step}>
              <SelectResource
                onSelectNetwork={handleSelectNetwork}
                onSelectContact={handleSelectContact}
                onCreateContact={handleCreateContact}
                onCreateNetwork={handleCreateNetwork}
              />
            </StepWrapper>
          ))}

          {/* TODO: Add back when backend support for granular sync is added
{stepper.when('step-3', (step: Step) => (
            <StepWrapper step={step}>
              <SyncContactInformation />
            </StepWrapper>
          ))} */}

          {stepper.when('step-1', () => (
            <FlexContainer justify="end">
              <Button
                onClick={stepper.next}
                color={ButtonColors.White}
                disabled={!methods.formState.isValid}
                text="Next"
              />
            </FlexContainer>
          ))}

          {stepper.when('step-2', () => (
            <FlexContainer justify="between">
              <Button onClick={stepper.prev} color={ButtonColors.White} text="Back" />
              <Button
                disabled={!isFinalizeConditionMet()}
                onClick={methods.handleSubmit(onSubmit)}
                color={ButtonColors.Blue}
                text="Add"
              />
            </FlexContainer>
          ))}
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
