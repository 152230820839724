import { useState } from 'react';
import { FilterConfig, FiltersSettings } from './types';
/**
 * Custom hook that manages filter state and provides methods to update and clear filters.
 *
 * @template T - The type representing the filter state.
 * @param config - An array of filter configurations, where each configuration defines a filter.
 * @returns An object containing:
 * - `filters`: An array of filters with their current values and change handlers.
 * - `filterState`: The current state of all filters.
 * - `clearFilter`: Function to clear a specific filter.
 * - `clearAllFilters`: Function to clear all filters.
 * - `clearMultiSelectFilter`: Function to clear a specific multi-select filter.
 */
export function useFilters<T>(config: FilterConfig<T>[]): FiltersSettings<T> {
  const [filterState, setFilterState] = useState<Partial<T>>({});

  const updateFilter = (key: keyof T, value: unknown) => {
    setFilterState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateMultiSelectFilter = (key: keyof T, value: unknown) => {
    if (((filterState[key] as unknown[]) || []).includes(value)) return;

    setFilterState((prevState) => ({
      ...prevState,
      [key]: [...((prevState[key] as unknown[]) || []), value],
    }));
  };

  const clearFilter = (type: keyof T) => {
    setFilterState((prevState) => {
      const newState = { ...prevState };
      delete newState[type];
      return newState;
    });
  };

  const clearMultiSelectFilter = (type: keyof T, value?: unknown) => {
    setFilterState((prevState) => {
      const newState = { ...prevState };
      const values = newState[type] as unknown[];
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      }

      if (values.length === 0) {
        delete newState[type];
      }

      return newState;
    });
  };

  const clearAllFilters = () => {
    setFilterState({} as T);
  };

  const filters = config.map((filter) => ({
    ...filter,
    value: filterState[filter.key],
    onChange: (value: unknown) =>
      filter.isMultiSelect
        ? updateMultiSelectFilter(filter.key, value)
        : updateFilter(filter.key, value),
  }));

  return { filters, filterState, clearFilter, clearMultiSelectFilter, clearAllFilters };
}
