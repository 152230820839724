import { $getRoot, $isElementNode, ElementNode, LexicalNode } from 'lexical';

export function findAllNodesOfType<TNode extends LexicalNode>(
  topNode: ElementNode,
  isOfType: (node: LexicalNode | null | undefined) => node is TNode,
): TNode[] {
  const result: TNode[] = [];
  const stack: LexicalNode[] = [topNode];
  while (stack.length > 0) {
    const node = stack.pop();
    if (isOfType(node)) {
      result.push(node);
    }
    if ($isElementNode(node)) {
      stack.push(...node.getChildren());
    }
  }
  return result;
}

export function $findAllNodesOfType<TNode extends LexicalNode>(
  isOfType: (node: LexicalNode | null | undefined) => node is TNode,
): TNode[] {
  return findAllNodesOfType($getRoot(), isOfType);
}
