import { forwardRef } from 'react';
import { SVGIconProps, SVGIconRef } from './types';

function NetworkAddIcon({ titleId, title, ...props }: SVGIconProps, ref: SVGIconRef) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title && <title id={titleId}>{title}</title>}

      <circle cx="28" cy="28" r="28" fill="#0E7490" />
      <path
        d="M24.2639 19.8447L24.58 19.6866L24.5364 19.3358C24.4175 18.3787 24.5927 17.408 25.0388 16.5529C25.4849 15.6978 26.1808 14.9988 27.0339 14.5487C27.8869 14.0987 28.8568 13.9191 29.8145 14.0336C30.7721 14.1482 31.6722 14.5516 32.395 15.1902C33.1179 15.8288 33.6292 16.6722 33.861 17.6085C34.0927 18.5447 34.034 19.5293 33.6926 20.4314C33.3512 21.3334 32.7433 22.1102 31.9497 22.6583C31.1562 23.2065 30.2145 23.5 29.25 23.5C27.9668 23.5 26.8057 22.9937 25.954 22.1705L25.6995 21.9245L25.3829 22.0828L16.7379 26.4053L16.4221 26.5632L16.4652 26.9136C16.5132 27.3031 16.5132 27.6969 16.4652 28.0864L16.4221 28.4368L16.7379 28.5947L25.3829 32.9172L25.699 33.0753L25.9535 32.83C26.7602 32.0524 27.8159 31.585 28.9339 31.5104C30.0518 31.4359 31.1602 31.7589 32.0631 32.4225C32.9659 33.0861 33.6051 34.0475 33.8677 35.1367C34.1303 36.226 33.9993 37.3731 33.498 38.3751C32.9967 39.3772 32.1573 40.1698 31.1281 40.6128C30.0989 41.0559 28.9463 41.1208 27.8739 40.7962C26.8014 40.4716 25.8783 39.7783 25.2676 38.8389C24.6568 37.8995 24.3979 36.7745 24.5364 35.6626L24.5801 35.3117L24.2639 35.1535L15.6189 30.831L15.3027 30.6729L15.0482 30.9183C14.3772 31.5654 13.5307 32.0007 12.6141 32.1701C11.6975 32.3394 10.7513 32.2354 9.89345 31.8709C9.03556 31.5064 8.30388 30.8975 7.78955 30.1201C7.27522 29.3428 7.00098 28.4312 7.00098 27.4991C7.00098 26.567 7.27522 25.6555 7.78955 24.8781C8.30388 24.1008 9.03556 23.4919 9.89345 23.1274C10.7513 22.7629 11.6975 22.6588 12.6141 22.8282C13.5307 22.9976 14.3772 23.4329 15.0482 24.0799L15.3027 24.3253L15.6189 24.1672L24.2639 19.8447Z"
        fill="white"
        stroke="white"
      />
      <path d="M42 26H37.5H42Z" fill="white" />
      <path
        d="M42 21.5V26M42 26V30.5M42 26H46.5M42 26H37.5"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default forwardRef(NetworkAddIcon);
