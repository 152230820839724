interface IProps {
  progress: number;
}

export default function MessageFileAttachmentProgress({ progress }: IProps) {
  return (
    <div
      className={`absolute bottom-0 left-0 h-1 rounded-bl bg-cyan ${
        progress < 100 ? '' : 'rounded-br'
      }`}
      style={{ width: `${progress}%` }}
    />
  );
}
