import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import TextFieldEmailInput from '../../shared/components/inputs/text/TextFieldEmailInput';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import { useCreateInvitationMutation } from '../Request/queries/invitationQueries';
import { ProfileCard } from '../../shared/components/cards/ProfileCard';
import Text from '../../shared/components/text/Text';
import { UserProfileDto } from '../Profile/models/UserProfile';
import { searchUsersQueryBase } from '../Profile/queries/userProfileQueries';
import ContactCreateForm, { useContactCreateForm } from './ContactCreateForm';
import {
  useAddUserAsContactCardAction,
  useConnectWithUserCardAction,
} from './hooks/contactProfileCardActionHooks';
import useModal from '../../shared/components/modal/hooks/useModal';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { useMyUserId } from '../../shared/auth/accountHooks';

interface IExistingProfileCardProps {
  profile: UserProfileDto;
  onSuccess: () => void;
}

function ExistingProfileCard({ profile, onSuccess }: IExistingProfileCardProps) {
  return (
    <ProfileCard
      profile={profile}
      as="div"
      actionsPosition="inline"
      actions={[
        useAddUserAsContactCardAction({ userProfile: profile, onSuccess }),
        useConnectWithUserCardAction({ userProfile: profile, onSuccess }),
      ]}
    />
  );
}

interface IVerifyEmailFormProps {
  onSuccess: () => void;
  onCreateNewContact: (validEmailAddress: string | undefined) => void;
  onCancel: () => void;
}

function VerifyEmailForm({ onSuccess, onCreateNewContact, onCancel }: IVerifyEmailFormProps) {
  const [inviteEmailAddress, setInviteEmailAddress] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isUserAlreadyRegistered, setIsUserAlreadyRegistered] = useState(false);
  const [existingUser, setExistingUser] = useState({} as UserProfileDto);

  const queryClient = useQueryClient();

  const createInvitationMutation = useCreateInvitationMutation();

  const handleInviteUser = () => {
    createInvitationMutation.mutate({ email: inviteEmailAddress }, { onSuccess });
  };

  const validateEmail = (email: string) => {
    if (email.match(REGEX_EMAIL)) {
      return true;
    }

    return false;
  };

  const handleSetInviteEmailAddress = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmailAddress(e.target.value);

    if (validateEmail(e.target.value)) {
      const result = await queryClient.fetchQuery(
        searchUsersQueryBase({ searchTerm: e.target.value, limit: 1 }),
      );
      if (result.length === 1) {
        setIsEmailVerified(true);
        setIsUserAlreadyRegistered(true);
        setExistingUser(result[0]);
      } else {
        setIsEmailVerified(true);
        setIsUserAlreadyRegistered(false);
      }
    } else {
      setIsEmailVerified(false);
      setIsUserAlreadyRegistered(false);
    }
  };

  const handleCreateNewContact = () => {
    onCreateNewContact(isEmailVerified ? inviteEmailAddress : undefined);
  };

  return (
    <FlexContainer direction="column">
      <Text size="xLarge" weight="medium">
        Find contact
      </Text>
      <TextFieldEmailInput text={inviteEmailAddress} onChange={handleSetInviteEmailAddress} />
      <Text as="p" size="small" color="normal" brightness="light" leading="normal">
        {!isUserAlreadyRegistered ? (
          <>
            {!isEmailVerified ? (
              <>Enter an email to see if the contact exist in Yoin.</>
            ) : (
              <>
                The user does not exist in Yoin. You can invite the user below or create a contact
                manually.
              </>
            )}
          </>
        ) : (
          <>{`The user ${existingUser.firstName} ${existingUser.lastName} exists.`}</>
        )}
      </Text>

      {isEmailVerified && isUserAlreadyRegistered && (
        <ExistingProfileCard profile={existingUser} onSuccess={onSuccess} />
      )}

      <FlexContainer direction="row" justify="end">
        <Button
          color={ButtonColors.White}
          text="Cancel"
          onClick={onCancel}
          className="hidden md:block"
        />
        <Button
          color={ButtonColors.Blue}
          text="Send invitation"
          onClick={handleInviteUser}
          disabled={
            !isEmailVerified || isUserAlreadyRegistered || createInvitationMutation.isPending
          }
        />
        <Button
          color={ButtonColors.White}
          text="Create new contact"
          onClick={handleCreateNewContact}
        />
      </FlexContainer>
    </FlexContainer>
  );
}

export function ContactCreateModal() {
  const [isVerifyEmailStepActive, setIsVerifyEmailStepActive] = useState(true);
  const [emailAddress, setEmailAddress] = useState('');
  const [unsavedDataWarningOpen, setUnsavedDataWarningOpen] = useState(false);
  const { closeModal } = useModal();
  // TenantId is current user here
  const tenantId = useMyUserId();

  const formObject = useContactCreateForm({
    values: { emailAddresses: [{ value: emailAddress, label: '' }] },
  });

  const handleGoToCreateStep = (validEmailAddress: string | undefined) => {
    setIsVerifyEmailStepActive(false);
    if (validEmailAddress) {
      setEmailAddress(validEmailAddress);
    }
  };

  const handleOpenChange = (_open: boolean) => {
    if (!_open) {
      setIsVerifyEmailStepActive(true);
      setEmailAddress('');
      closeModal();
    }
  };

  const handleClose = () => {
    handleOpenChange(false);
  };

  return (
    <>
      {isVerifyEmailStepActive ? (
        <VerifyEmailForm
          onSuccess={handleClose}
          onCancel={handleClose}
          onCreateNewContact={handleGoToCreateStep}
        />
      ) : (
        <>
          <Text size="xLarge" weight="medium" leading="none">
            Personal Information
          </Text>
          <ContactCreateForm
            tenantId={tenantId ?? ''}
            form={formObject}
            unsavedDataWarningOpen={unsavedDataWarningOpen}
            onUnsavedDataWarningOpenChange={setUnsavedDataWarningOpen}
            onSuccess={handleClose}
            onCancel={handleClose}
          />
        </>
      )}
    </>
  );
}
