import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import {
  createStage,
  deleteStage,
  getStage,
  getStages,
  moveStage,
  updateStage,
} from '../services/pipelineStageService';
import { ICreateStageDto, IStageDto } from '../models/IStageDto';
import { pipelineKpiKeys } from './pipelineKpiQueries';
import { pipelineKeys } from './pipelineQueries';
import { CalculateDragDropPositions } from '../../../shared/utils/dragDropUtils';

interface IUseStageProps {
  pipelineId: string;
}

export const stageKeys = {
  all: ['stages'] as const,
  lists: () => [...stageKeys.all, 'list'] as const,
  list: (props: IUseStageProps) => [...stageKeys.lists(), props] as const,
  details: () => [...stageKeys.all, 'detail'] as const,
  detail: (id: string) => [...stageKeys.details(), id] as const,
};

const stagesBaseQuery = ({ pipelineId }: IUseStageProps) => ({
  queryKey: stageKeys.list({ pipelineId }),
  queryFn: () => getStages(pipelineId),
  enabled: !!pipelineId,
});

export function useStagesQuery(props: IUseStageProps) {
  return useQuery({
    ...stagesBaseQuery(props),
  });
}

export const stageBaseQuery = (pipelineId: string, stageId: string, queryClient: QueryClient) => ({
  queryKey: stageKeys.detail(stageId as string),
  queryFn: () => getStage(pipelineId as string, stageId as string),
  // TODO: Add back when researched. Temporarily disabled to fix the issue with NaN on value
  //   ...getInitalDataPropsFromQueries<IStageDetailDto>(
  //     queryClient,
  //     stageKeys.list({ pipelineId }),
  //     (stage) => stage.id === stageId,
  //   ),
});

export function useStageQuery(piplineId?: string, stageId?: string) {
  const queryClient = useQueryClient();

  return useQuery({
    ...stageBaseQuery(piplineId as string, stageId as string, queryClient),
    enabled: !!piplineId && !!stageId,
  });
}

interface ICreateStageProps {
  pipelineId: string;
  stage: ICreateStageDto;
}

export function useCreateStageMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ pipelineId, stage }: ICreateStageProps) => createStage(pipelineId, stage),
    onSuccess: (_, { pipelineId }) => {
      queryClient.invalidateQueries({ queryKey: stageKeys.list({ pipelineId }) });
      queryClient.invalidateQueries({ queryKey: stageKeys.lists() });
    },
    onError: dispatchError,
  });
}

export function useUpdateStageMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      stage,
    }: {
      pipelineId: string;
      stageId: string;
      stage: ICreateStageDto;
    }) => updateStage(pipelineId, stageId, stage).then((response) => response.data),
    onSuccess: (_, { pipelineId, stageId }) => {
      queryClient.invalidateQueries({ queryKey: stageKeys.detail(stageId) });
      queryClient.invalidateQueries({ queryKey: stageKeys.lists() });
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({ queryKey: pipelineKpiKeys.detail({ pipelineId }) });
    },
    onError: dispatchError,
  });
}

export function useDeleteStageMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ pipelineId, stageId }: { pipelineId: string; stageId: string }) =>
      deleteStage(pipelineId, stageId).then((response) => response.data),
    onSuccess: (_, { pipelineId }) => {
      queryClient.invalidateQueries({ queryKey: stageKeys.list({ pipelineId }) });
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({ queryKey: pipelineKpiKeys.detail({ pipelineId }) });
    },
    onError: dispatchError,
  });
}

export interface IMoveStage {
  moveToPositionAfterStageId: string | null;
}

export interface IMoveStageProps {
  pipelineId: string;
  stageId: string;
  destinationIndex: number;
  moveSettings: IMoveStage;
}

export function useMoveStageMutation() {
  const dispatchError = useDispatchApiError();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ pipelineId, stageId, moveSettings }: IMoveStageProps) =>
      moveStage(pipelineId, stageId, moveSettings),
    onMutate: ({ pipelineId, stageId, destinationIndex }) => {
      const stages: IStageDto[] = queryClient.getQueryData(
        stageKeys.list({ pipelineId }),
      ) as IStageDto[];

      const { targetItems } = CalculateDragDropPositions<IStageDto>(
        stages,
        stages,
        stageId,
        destinationIndex as number,
      );

      queryClient.setQueryData(stageKeys.list({ pipelineId }), targetItems);

      return { stages };
    },
    onSuccess: (_, { pipelineId, stageId }) => {
      queryClient.invalidateQueries({ queryKey: stageKeys.list({ pipelineId }) });
      queryClient.invalidateQueries({ queryKey: stageKeys.detail(stageId) });
    },
    onError: (error, { pipelineId }, stages) => {
      queryClient.setQueryData(stageKeys.list({ pipelineId }), stages);
      dispatchError(error);
    },
  });
}
