import { Row } from '@tanstack/react-table';
import { ChevronDown, ChevronRight } from 'lucide-react';
import FlexContainer from '../../structure/FlexContainer';

interface IExpandableRowCellProps<T> {
  row: Row<T>;
  children: React.ReactNode;
}

export default function ExpandableRowCell<T>({ row, children }: IExpandableRowCellProps<T>) {
  return (
    <FlexContainer direction="row">
      {row.getCanExpand() && (
        <button type="button" onClick={row.getToggleExpandedHandler()} className="cursor-pointer">
          {row.getIsExpanded() ? <ChevronDown /> : <ChevronRight />}
        </button>
      )}
      {children}
    </FlexContainer>
  );
}
