interface IProps {
  children: React.ReactNode;
}

/**
 * Center the children of this component in its container.
 */

export default function Center({ children }: IProps) {
  return <div className="m-auto">{children}</div>;
}
