import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EmojiPicker } from '../../../shared/components/pickers/EmojiPicker';
import { GifPicker } from '../../../shared/components/gifs/GifPicker';
import { INSERT_EMOJI_COMMAND } from '../../../shared/lexical/emojis/EmojiPlugin';
import { INSERT_IMAGE_COMMAND } from '../../../shared/lexical/images/ImagePlugin';

export function MessageEditorToolbarPlugin() {
  const [editor] = useLexicalComposerContext();

  const handleSelectEmoji = (emoji: { native: string }) => {
    editor.dispatchCommand(INSERT_EMOJI_COMMAND, emoji);
  };

  const handleSelectGif = ({ url, title }: { url: string; title: string }) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src: url, alt: title });
  };

  return (
    <>
      <EmojiPicker onSelect={handleSelectEmoji} />
      <GifPicker onSelect={handleSelectGif} />
    </>
  );
}
