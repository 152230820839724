import { useGlobalStore } from '../../../store/useGlobalStore';

export default function useConfirm() {
  const openConfirm = useGlobalStore((state) => state.confirm.open);
  const closeConfirm = useGlobalStore((state) => state.confirm.close);
  const isConfirmOpen = useGlobalStore((state) => state.confirm.isOpen);
  const confirmContentProps = useGlobalStore((state) => state.confirmContentProps);

  return { isConfirmOpen, openConfirm, closeConfirm, confirmContentProps };
}
