import { forwardRef } from 'react';
import { SVGIconProps, SVGIconRef } from './types';

function ContactAddIcon({ titleId, title, ...props }: SVGIconProps, ref: SVGIconRef) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title && <title id={titleId}>{title}</title>}
      <circle cx="28" cy="28" r="28" fill="#0E7490" />
      <path
        d="M36 24.5H36.5V24V22C36.5 21.6022 36.658 21.2206 36.9393 20.9393C37.2206 20.658 37.6022 20.5 38 20.5C38.3978 20.5 38.7794 20.658 39.0607 20.9393C39.342 21.2206 39.5 21.6022 39.5 22V24V24.5H40H42C42.3978 24.5 42.7794 24.658 43.0607 24.9393C43.342 25.2206 43.5 25.6022 43.5 26C43.5 26.3978 43.342 26.7794 43.0607 27.0607C42.7794 27.342 42.3978 27.5 42 27.5H40H39.5V28V30C39.5 30.3978 39.342 30.7794 39.0607 31.0607C38.7794 31.342 38.3978 31.5 38 31.5C37.6022 31.5 37.2206 31.342 36.9393 31.0607C36.658 30.7794 36.5 30.3978 36.5 30V28V27.5H36H34C33.6022 27.5 33.2206 27.342 32.9393 27.0607C32.658 26.7794 32.5 26.3978 32.5 26C32.5 25.6022 32.658 25.2206 32.9393 24.9393C33.2206 24.658 33.6022 24.5 34 24.5H36ZM27.8891 23.8891C26.8576 24.9205 25.4587 25.5 24 25.5C22.5413 25.5 21.1424 24.9205 20.1109 23.8891C19.0795 22.8576 18.5 21.4587 18.5 20C18.5 18.5413 19.0795 17.1424 20.1109 16.1109C21.1424 15.0795 22.5413 14.5 24 14.5C25.4587 14.5 26.8576 15.0795 27.8891 16.1109C28.9205 17.1424 29.5 18.5413 29.5 20C29.5 21.4587 28.9205 22.8576 27.8891 23.8891ZM24 30.5C27.05 30.5 29.9751 31.7116 32.1317 33.8683C34.1703 35.9068 35.3644 38.6318 35.4891 41.5H12.5109C12.6356 38.6318 13.8297 35.9068 15.8683 33.8683C18.0249 31.7116 20.95 30.5 24 30.5Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}

export default forwardRef(ContactAddIcon);
