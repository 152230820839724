import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getNearestNodeFromDOMNode,
  $getSelection,
  $isDecoratorNode,
  $isNodeSelection,
  COMMAND_PRIORITY_LOW,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
} from 'lexical';
import { useEffect } from 'react';

export function ArrowKeyNodeSelectionPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand<KeyboardEvent>(
          KEY_ARROW_LEFT_COMMAND,
          (event) => {
            const selection = $getSelection();
            if ($isNodeSelection(selection)) {
              // If selection is on a node, let's try and move selection
              // back to being a range selection.
              const nodes = selection.getNodes();
              if (nodes.length > 0) {
                event.preventDefault();
                nodes[0].selectPrevious();
                return true;
              }
            }

            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand<KeyboardEvent>(
          KEY_ARROW_RIGHT_COMMAND,
          (event) => {
            const selection = $getSelection();
            const node = $getNearestNodeFromDOMNode(event.target as HTMLElement);
            if ($isNodeSelection(selection) && !$isDecoratorNode(node)) {
              // If selection is on a node, let's try and move selection
              // back to being a range selection.
              const nodes = selection.getNodes();
              if (nodes.length > 0) {
                event.preventDefault();
                nodes[0].selectNext(0, 0);
                return true;
              }
            }

            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [],
  );

  return null;
}
