import { forwardRef } from 'react';
import { cn } from '../../../lib/utils';
import FlexContainer from '../structure/FlexContainer';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const TabContentWrapper = forwardRef<HTMLDivElement, IProps>(
  ({ children, className, ...props }, ref) => (
    <FlexContainer
      ref={ref}
      className={cn(
        'flex-1 min-h-0 flex flex-col overflow-hidden w-full h-full px-3 pt-6 pb-2 md:p-8',
        className,
      )}
      {...props}
    >
      {children}
    </FlexContainer>
  ),
);
export default TabContentWrapper;
