import { ButtonGroupDirections } from '../../constants/ButtonGroupDirections';
import { ButtonGroupJustifyTypes } from '../../constants/ButtonGroupJustifyTypes';

interface IProps {
  justify?: ButtonGroupJustifyTypes;
  direction?: ButtonGroupDirections;
  children: React.ReactNode;
}

export default function ButtonGroup({
  children,
  justify = ButtonGroupJustifyTypes.End,
  direction = ButtonGroupDirections.Row,
}: IProps) {
  const justifyClass = (type: ButtonGroupJustifyTypes) => {
    switch (type) {
      case ButtonGroupJustifyTypes.Start:
        return 'justify-start';
      case ButtonGroupJustifyTypes.Center:
        return 'justify-center';
      case ButtonGroupJustifyTypes.End:
      default:
        return 'justify-end';
    }
  };

  const directionClass = (type: ButtonGroupDirections) => {
    switch (type) {
      case ButtonGroupDirections.Column:
        return 'flex-col space-y-2';
      case ButtonGroupDirections.Row:
      default:
        return 'flex-row space-x-3 ';
    }
  };

  return <div className={`flex ${directionClass(direction)} ${justifyClass(justify)}`}>{children}</div>;
}
