import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ViewName } from '../../shared/store/slices/viewSlice';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import SidePanel from '../../shared/components/layout/building-blocks/SidePanel';
import SidePanelHeadline from '../../shared/components/layout/building-blocks/SidePanelHeadline';
import useCompanyActions from './hooks/useCompanyActions';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import useSearchParamsTabNavigation from '../../shared/components/tabs/hooks/useSearchParamsTabNavigation';
import { useUserProfileQuery } from '../Profile/queries/userProfileQueries';
import { useTenantQuery } from './queries/tenantQueries';
import { ITenantDto } from './models/ITenantDto';
import InfoBox from '../../shared/components/info/InfoBox';

function UserTenantClosedSidePanel({ tenantId }: { tenantId: string }) {
  const resourceManifest = useEntityManifest(ResourceType.Contact);
  const { data } = useUserProfileQuery(tenantId); // TenantId of tenant with type User === UserId
  return (
    <ProfileAvatar
      avatarProps={{
        src: data?.photoUrl || resourceManifest.icon,
        widthClass: 'w-14',
        heightClass: 'h-14',
      }}
    />
  );
}

interface IUserTenantProps {
  tenant: ITenantDto;
}

function UserTenant({ tenant }: IUserTenantProps) {
  const [activeTab, setActiveTab] = useSearchParamsTabNavigation('info');

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate('/companies');
  };

  const { items: menuItems } = useCompanyActions(handleNavigateBack);
  // TenantId of tenant with type User === UserId
  const { data: profileData } = useUserProfileQuery(tenant.id);
  return (
    <TabWrapper>
      <TabHeaderWrapper>
        <TabNavItem
          id="info"
          activeId={activeTab}
          title="Info"
          setActiveTab={() => setActiveTab('info')}
        />

        {menuItems.length > 0 && <TabDropdownItem isActive={false} menuItems={menuItems} />}
      </TabHeaderWrapper>
      <TabContentWrapper>
        <TabContent id="info" activeTabId={activeTab}>
          <FlexContainer direction="column" gap="small" align="start">
            {profileData && (
              <InfoBox headline="Personal collaboration">
                This is the personal collaboration area for {profileData.displayName}, all data
                generated here will be owned by that user. To enable collaboration within a company
                please create one and start working there.
              </InfoBox>
            )}
          </FlexContainer>
        </TabContent>
      </TabContentWrapper>
    </TabWrapper>
  );
}

export default function UserTenantSidePanel() {
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const currentView: ViewName = 'userTenant';
  const viewSettings = getViewSettings(currentView);
  const resourceType = ResourceType.Contact;
  const resourceManifest = useEntityManifest(resourceType);
  const params = useParams();
  const { tenantId } = params;
  const tenantQuery = useTenantQuery(tenantId as string);
  // TenantId of tenant with type User === UserId
  const profileQuery = useUserProfileQuery(tenantId);

  useEffect(() => {
    setSidePanelOpen(currentView, true);
  }, [tenantId]);

  if (!tenantQuery.isSuccess || !profileQuery.isSuccess) {
    return <></>;
  }

  const tenant = tenantQuery.data;
  const profile = profileQuery.data;
  return (
    <>
      {tenantId && (
        <SidePanel
          renderClosed={() => <UserTenantClosedSidePanel tenantId={tenantId} />}
          colorSetting={resourceManifest.color}
          isOpen={viewSettings.isSidePanelOpen}
          openSidePanel={() => setSidePanelOpen(currentView, true)}
          closeSidePanel={() => setSidePanelOpen(currentView, false)}
          onToggle={() => toggleSidePanel(currentView)}
        >
          <SidePanelHeadline
            headline={tenant.name}
            colorSetting={resourceManifest.color}
            imageSrc={profile.photoUrl || resourceManifest.icon}
          />
          <UserTenant tenant={tenant} />
        </SidePanel>
      )}
    </>
  );
}
