import { useGlobalStore } from '../../../store/useGlobalStore';

export default function useModal() {
  const openModal = useGlobalStore((state) => state.modal.open);
  const closeModal = useGlobalStore((state) => state.modal.close);
  const isOpen = useGlobalStore((state) => state.modal.isOpen);
  const modalContentProps = useGlobalStore((state) => state.modalContentProps);

  return { openModal, closeModal, isOpen, modalContentProps };
}
