import { format, formatISO, parseISO } from 'date-fns';

interface IProps {
  date: Date | string | undefined;
  className?: string;
}

export default function DateOnly({ date, className }: IProps) {
  const dateClone = typeof date === 'string' ? parseISO(date) : date;
  if (!dateClone) return <time />;
  return (
    <time dateTime={formatISO(dateClone)} className={className}>
      {format(dateClone, 'PP')}
    </time>
  );
}
