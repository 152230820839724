import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ICreateUpdateNetworkDto, INetworkDto } from '../../shared/model/INetwork';
import { useCreateUpdateNetworkMutation } from './queries/networkQueries';
import { NetworkCreateEditForm, useNetworkCreateEditForm } from './NetworkCreateEditForm';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { useToast } from '../../shared/components/toasts/use-toast';

interface IProps {
  network?: INetworkDto;
  onCancel?: () => void;
  onSuccess?: (networkId: string) => void;
}

export default function NetworkCreateEditModal({ network, onCancel, onSuccess }: IProps) {
  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = useNetworkCreateEditForm({ values: network });
  const createUpdateNetwork = useCreateUpdateNetworkMutation();
  const { toast } = useToast();

  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleSubmit: SubmitHandler<ICreateUpdateNetworkDto> = (networkToSubmit) => {
    const isUpdate = !!network?.id;

    createUpdateNetwork.mutate(
      { id: network?.id, network: networkToSubmit },
      {
        onSuccess: (_network) => {
          if (onSuccess) {
            onSuccess(_network.id);
            toast({
              title: 'Success',
              description: isUpdate ? 'Network has been updated' : 'Network has been created',
              variant: 'success',
            });
          }
        },
      },
    );
  };

  const handleClose = () => {
    reset();
    if (onCancel) {
      onCancel();
    }
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <NetworkCreateEditForm
        form={form}
        network={network}
        isDisabled={createUpdateNetwork.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </>
  );
}
