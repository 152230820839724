export interface ICardListProps {
  children: React.ReactNode;
  direction?: 'column' | 'row';
  alignHorizontal?: 'left' | 'center' | 'right';
}

export function CardList({ direction = 'column', alignHorizontal = 'left', children }: ICardListProps) {
  const classes = ['flex'];
  if (direction === 'row') {
    classes.push('flex-wrap gap-x-2');
    if (alignHorizontal === 'center') {
      classes.push('justify-center');
    }
    if (alignHorizontal === 'right') {
      classes.push('justify-end');
    }
  }
  if (direction === 'column') {
    classes.push('flex-col');
    if (alignHorizontal === 'center') {
      classes.push('items-center');
    }
    if (alignHorizontal === 'right') {
      classes.push('items-end');
    }
  }

  const className = classes.join(' ');
  return <ul className={className}>{children}</ul>;
}
