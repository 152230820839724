import { Droppable } from 'react-beautiful-dnd';

interface IProps {
  id?: string;
  children: React.ReactNode;
}

const getDragStyle = (isDraggingOver: boolean) => ({
  border: isDraggingOver ? '2px dashed #06b6d4' : '',
  height: isDraggingOver ? 'max-content' : '',
});

export default function DroppableStyled({ id = 'droppablearea', children }: IProps) {
  return (
    <Droppable droppableId={id} direction="vertical">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getDragStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
