type TextAlignment = 'left' | 'center' | 'right';

const textAlignMap: Record<TextAlignment, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
};

interface IProps {
  alignment?: TextAlignment;
  children?: React.ReactNode;
}

export default function StandardHeaderCell({ alignment, children }: IProps) {
  const alignmentClass = textAlignMap[alignment || 'left'];
  return <div className={alignmentClass}>{children && <span>{children}</span>}</div>;
}
