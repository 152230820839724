import { XIcon } from 'lucide-react';
import { ButtonColors } from '../../constants/ButtonColors';
import IconButton from './IconButton';

interface IAddButtonProps {
  onClick?: () => void;
}

export default function CloseButton({ onClick }: IAddButtonProps) {
  return (
    <IconButton
      onClick={onClick}
      color={ButtonColors.Gray}
      icon={<XIcon className="h-6 w-6 text-gray-dark" aria-hidden="true" />}
    />
  );
}
