import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import {
  ISidePanelLayoutContent,
  SidePanelLayout,
} from '../../shared/components/layout/pages/SidePanelLayout';
import { useNetworkQuery } from './queries/networkQueries';
import networkIcon from '../../assets/images/network-icon.svg';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import Messaging from '../Messaging/Messaging';
import { useResourceConversationQuery } from '../Messaging/queries/conversationQueries';
import { useMessagesQuery } from '../Messaging/queries/messageQueries';
import { createActivityAction } from '../Messaging/editor/messageEditorMenuActions';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import NetworkInfo from './NetworkInfo';
import NetworkMembers from './NetworkMembers';
import Activities from '../Activities/Activities';
import Attachments from '../../shared/components/attachments/Attachments';
import Howto from '../../shared/components/message-popups/HowTo';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import { useVisitMutation } from '../Tracking/queries/visitQueries';
import useVisitTracking from '../Tracking/hooks/useVisitTracking';
import useNetworkActions from './hooks/useNetworkActions';

function useNetworkFeatures(networkId: string) {
  const isMobile = useCheckMobileScreen();
  const networkQuery = useNetworkQuery(networkId);
  const converstionQuery = useResourceConversationQuery(networkId, ResourceType.Network);
  const messagesQuery = useMessagesQuery(converstionQuery.data?.id);

  const { setSidePanelOpen } = useGlobalStore();

  return useMemo(() => {
    const content: ISidePanelLayoutContent[] = [];
    if (converstionQuery.isSuccess) {
      content.push({
        id: 'chat',
        title: 'Chat',
        renderContent: () => (
          <Messaging
            messages={messagesQuery}
            conversation={converstionQuery.data}
            externalMenuActions={[
              createActivityAction('?tab=activities', () => setSidePanelOpen('network', true)),
            ]}
          />
        ),
        desktopRenderTarget: 'main',
      });
    }
    content.push(
      {
        id: 'info',
        title: 'Info',
        renderContent: () => <NetworkInfo network={networkQuery.data} />,
        mobileRenderTarget: 'headerLink',
      },
      {
        id: 'members',
        title: 'Members',
        renderContent: () => <NetworkMembers networkId={networkId} />,
      },
      {
        id: 'activities',
        title: 'Tasks',
        renderContent: () => <Activities resourceIds={[networkId]} type={ResourceType.Network} />,
      },
    );
    if (converstionQuery.isSuccess) {
      content.push({
        id: 'attachments',
        title: 'Attachments',
        renderContent: () => (
          <>
            <div className="flex-1 overflow-hidden">
              <Attachments conversationId={converstionQuery.data.id} />
            </div>
            <div className="flex pt-2">
              <Howto
                title="Attachments "
                description="View the attatchments you have sent within the networks. "
              />
            </div>
          </>
        ),
        mobileRenderTarget: 'menu',
      });
    }
    return {
      content,
      default: isMobile ? 'chat' : 'info',
      isPending: networkQuery.isPending || converstionQuery.isPending,
      isSuccess: networkQuery.isSuccess && converstionQuery.isSuccess,
    };
  }, [
    isMobile,
    converstionQuery.isSuccess,
    converstionQuery.isPending,
    converstionQuery.data,
    messagesQuery,
    networkQuery.isSuccess,
    networkQuery.isPending,
    networkQuery.data,
  ]);
}

export function NetworkDetail() {
  const params = useParams();
  const manifest = useEntityManifest(ResourceType.Network);

  const networkId = params.id as string;
  const networkQuery = useNetworkQuery(networkId);

  const networkFeatures = useNetworkFeatures(networkId);
  const networkActions = useNetworkActions(networkId);

  const visitMutation = useVisitMutation();
  useVisitTracking({
    onVisit: () => {
      if (!networkId) {
        return;
      }
      visitMutation.mutate({ resourceType: ResourceType.Network, resourceId: [networkId] });
    },
  });

  if (networkQuery.isPending || networkFeatures.isPending || networkActions.isPending) {
    return <div />; // Use loader?
  }

  if (networkQuery.isSuccess && networkFeatures.isSuccess) {
    return (
      <SidePanelLayout
        title={networkQuery.data.name}
        subTitle={`${networkQuery.data.visibility} & ${networkQuery.data.type}`}
        imgSource={networkQuery.data.photoUrl || networkIcon}
        imgAlt="Network photo"
        colorSetting={manifest.color}
        sidePanelViewSettingName="network"
        content={networkFeatures.content}
        actions={networkActions.items}
        defaultTab={networkFeatures.default}
      />
    );
  }

  return (
    <ErrorComponent
      queryResults={[networkQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The network could not be found.' }]}
    />
  );
}
