import { useEffect, useState } from 'react';
import FlexContainer from '../structure/FlexContainer';
import { cn } from '../../../lib/utils';
import Text from '../text/Text';

interface ITextSwitchItem<T> {
  label?: string;
  value: T;
  icon: React.ReactElement;
  iconActive: React.ReactElement;
}

interface IIconSwitchProps<T> {
  items: ITextSwitchItem<T>[];
  onChange: (value: T) => void;
}

export default function IconSwitch<T>({ items, onChange }: IIconSwitchProps<T>) {
  const [activeItem, setActiveItem] = useState(items[0].value);

  const handleOnChange = (value: T) => {
    setActiveItem(value);
  };

  useEffect(() => {
    onChange(activeItem);
  }, [activeItem]);

  return (
    <FlexContainer gap="small">
      {items.map((item) => (
        <button
          key={item.value as string}
          type="button"
          className={cn(
            'flex flex-row items-center',
            item.value === activeItem ? 'bg-primary-500' : 'bg-primary-300',
          )}
          onClick={() => handleOnChange(item.value)}
        >
          {item.value === activeItem ? item.iconActive : item.icon}
          {item.label && <Text size="small">{item.label}</Text>}
        </button>
      ))}
    </FlexContainer>
  );
}
