import { ActivityItemType, IActivityItem } from './IActivityItem';
import ContactActivityBadge from './ContactActivityBadge';
import ConversationActivityBadge from './ConversationActivityBadge';
import MentionActivityBadge from './MentionActivityBadge';
import TaskActivityBadge from './TaskActivityBadge';
import { cn } from '../../../lib/utils';

export type ActivityItemGap = 'small' | 'medium' | 'large' | 'xLarge';

export interface ActivityListProps {
  itemGap?: ActivityItemGap;
  items: IActivityItem[];
  justify?: boolean;
}

export const gapClassLookup: Record<ActivityItemGap, string> = {
  small: 'gap-1',
  medium: 'gap-2',
  large: 'gap-4',
  xLarge: 'gap-6',
};

export const activityBadgeLookup: Record<ActivityItemType, React.ElementType> = {
  Contact: ContactActivityBadge,
  Conversation: ConversationActivityBadge,
  Mention: MentionActivityBadge,
  Task: TaskActivityBadge,
};

export default function ActivityList({
  items,
  itemGap = 'medium',
  justify = false,
}: ActivityListProps) {
  const actualItems = items.map((item) => {
    const BadgeComponent = activityBadgeLookup[item.type];
    return <BadgeComponent key={item.type} value={item.value} />;
  });
  const gapClass = gapClassLookup[itemGap];
  const classNames = cn(
    'flex flex-1 items-center space-x-1',
    gapClass,
    justify && 'justify-between',
  );
  return <span className={classNames}>{actualItems.map((component) => component)}</span>;
}
