import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { deleteNetworkPhoto, uploadNetworkPhoto } from '../../../shared/services/networkService';
import { networkKeys } from './networkQueries';

type MutateNetworkPhotoProps = {
  id: string;
  file?: Blob;
};

export function useNetworkPhotoMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id, file }: MutateNetworkPhotoProps) => {
      if (typeof file === 'undefined') {
        return deleteNetworkPhoto(id);
      }

      return uploadNetworkPhoto(id, file);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: networkKeys.detail(id) });
      queryClient.invalidateQueries({ queryKey: networkKeys.lists() });
    },
    onError: dispatchError,
  });
}
