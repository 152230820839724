import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createViewSlice, ViewsSlice } from './slices/viewSlice';
import { createProjectSlice, ProjectSlice } from './slices/projectSlice';
import createModalSlice, { ModalSlice } from './slices/modalSlice';
import createConfirmSlice, { ConfirmSlice } from './slices/confirmSlice';

export type CombinedSlices = ViewsSlice & ProjectSlice & ModalSlice & ConfirmSlice;

export const useGlobalStore = create<CombinedSlices>()(
  persist(
    devtools((...a) => ({
      ...createViewSlice(...a),
      ...createProjectSlice(...a),
      ...createModalSlice(...a),
      ...createConfirmSlice(...a),
    })),
    {
      name: 'global-storage',
      storage: createJSONStorage(() => sessionStorage),
      // Partialize lets us specify which part of the slices we want to persist
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => ['views'].includes(key))),
    },
  ),
);
