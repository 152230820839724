import { FormProvider, useForm } from 'react-hook-form';
import { PipelineCreateItemForm } from './PipelineCreateItemForm';
import FlexContainer from '../../../../shared/components/structure/FlexContainer';
import Button from '../../../../shared/components/buttons/Button';
import { ButtonColors } from '../../../../shared/constants/ButtonColors';
import { IResourceProps } from './PipelineCreateItemPopover';
import { useCreatePipelineItemMutation } from '../../queries/itemQueries';
import { usePipelineStoreContext } from '../../context/pipeline-context';
import convertFormattedCurrencyToNumber from '../../../../shared/utils/numberUtils';
import { usePipelineQuery } from '../../queries/pipelineQueries';

export default function PipelineAddItem({ stageId, onSubmitComplete }: IResourceProps) {
  const createPipelineItemMutation = useCreatePipelineItemMutation();
  const { activePipelineSettings } = usePipelineStoreContext();

  const pipeline = usePipelineQuery(activePipelineSettings.pipelineId);

  const methods = useForm({
    defaultValues: {
      name: '',
      value: 0,
      description: '',
    },
  });

  const onSubmit = () => {
    const { name, value, description } = methods.getValues();

    const valueAsNumber = convertFormattedCurrencyToNumber(value);

    createPipelineItemMutation.mutateAsync({
      pipelineId: activePipelineSettings.pipelineId,
      stageId,
      item: {
        name,
        value: valueAsNumber,
        description,
      },
    });

    onSubmitComplete(false);
    methods.reset();
  };

  if (!pipeline.data) {
    return <></>;
  }

  return (
    <FormProvider {...methods}>
      <FlexContainer direction="column">
        <form>
          <PipelineCreateItemForm currencyIsoCode={pipeline.data?.currencyIsoCode} />
        </form>
        <FlexContainer justify="end">
          <Button
            onClick={onSubmit}
            color={ButtonColors.Blue}
            text="Add"
            disabled={!methods.formState.isValid}
          />
        </FlexContainer>
      </FlexContainer>
    </FormProvider>
  );
}
