import Header1 from '../../headers/Header1';
import FlexContainer from '../../structure/FlexContainer';

import HoverMenu from '../../menus/HoverMenu';
import { IActionItem } from '../../../model/IActionItem';

export interface IServiceHeaderAction {
  id: string;
  name: string;
  icon: React.ElementType;
  isActive?: boolean;
  onClick: () => Promise<void>;
}

export type ActionPosition = 'start' | 'end';

export interface IServiceHeaderProps {
  text: string;
  contentAfterHeader?: React.ReactNode;
  actions?: IActionItem[];
}

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <FlexContainer align="center" direction="row">
      {children}
    </FlexContainer>
  );
}

export default function ServiceHeader({ text, contentAfterHeader, actions }: IServiceHeaderProps) {
  const actualActions = actions ?? [];

  return (
    <Layout>
      <HoverMenu menuItems={actualActions} iconClassName="relative left-2 top-4">
        <FlexContainer direction="row">
          <Header1>{text}</Header1>
          {contentAfterHeader}
        </FlexContainer>
      </HoverMenu>
    </Layout>
  );
}
