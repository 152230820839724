import { PencilIcon, TrashIcon, UserPlusIcon } from 'lucide-react';
import { useParams } from 'react-router';
import useModal from '../../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../../shared/model/IActionItem';
import { useTenantPrivilegesQuery } from '../queries/tenantPrivilegesQueries';
import { OrganizationCreateEditModal } from '../OrganizationCreateEditModal';
import InviteMember from '../../Members/MemberInvite';
import { useInviteOrganizationMemberMutation } from '../queries/organizationMemberQueries';
import { useDeleteOrganizationMutation } from '../queries/organizationQueries';
import { ResourceType } from '../../../shared/model/ResourceType';
import useConfirm from '../../../shared/components/alert-dialog/hooks/useConfirm';
import { resolveDeleteConfirm } from '../../../shared/components/alert-dialog/resolvers/confirmResolvers';
import { useTenantQuery } from '../queries/tenantQueries';

export default function useCompanyActions(onDeleteCallback?: () => void) {
  const { tenantId } = useParams();

  const tenantPrivilegesQuery = useTenantPrivilegesQuery(tenantId as string);
  const privileges = tenantPrivilegesQuery.data;

  const { openModal, closeModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const tenantQuery = useTenantQuery(tenantId as string);
  const tenant = tenantQuery.data;

  // Can only delete organizations
  const deleteMutation = useDeleteOrganizationMutation();
  // Can only invite to organizations
  const inviteMemberMutation = useInviteOrganizationMemberMutation();

  const menuItems: IActionItem[] = [];

  if (privileges?.canEdit) {
    menuItems.push({
      id: 'edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () =>
        openModal({
          title: 'Edit company',
          content: <OrganizationCreateEditModal values={tenant} />,
        }),
    });
  }
  if (privileges?.canInviteMember) {
    menuItems.push({
      id: 'invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () =>
        openModal({
          title: 'Invite member',
          content: (
            <InviteMember
              customInviteMemberMutation={inviteMemberMutation}
              resourceIds={tenant ? [tenant.id] : []}
              type={ResourceType.Organization}
              onComplete={() => closeModal()}
            />
          ),
        }),
    });
  }
  if (privileges?.canDelete) {
    menuItems.push({
      id: 'delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => {
        openConfirm({
          ...resolveDeleteConfirm('company'),
          onAction: () => {
            if (!tenant) {
              closeConfirm();
              return;
            }

            closeConfirm();
            deleteMutation.mutate(tenant);

            if (onDeleteCallback) onDeleteCallback();
          },
        });
      },
    });
  }

  return { items: menuItems };
}
