import { ActivityStatus, IActivityDto } from '../../shared/model/IActitityDto';

const statusColorLookup: { [key in ActivityStatus]: string } = {
  Pending: 'bg-purple-light text-purple-dark',
  InProgress: 'bg-yellow-light text-yellow-dark',
  Completed: 'bg-green-light text-green-dark',
  Blocked: 'bg-red-light text-red-dark',
};

const statusNameLookup: { [key in ActivityStatus]: string } = {
  Pending: 'To Do',
  InProgress: 'In progress',
  Completed: 'Completed',
  Blocked: 'Blocked',
};

interface IActivityStatusButtonProps {
  label: string;
  color: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function ActivityStatusButton({ label, color, onClick }: IActivityStatusButtonProps) {
  return (
    <button
      type="button"
      className={`flex h-[26px] cursor-pointer justify-center items-center content-center rounded-full text-center text-xs font-medium whitespace-nowrap w-[85px] truncate ${color} z-10`}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export interface IActivityStatusSelectorProps {
  activity: IActivityDto;
  isEditing: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSelect: (status: ActivityStatus, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function ActivityStatusSelector({
  activity,
  isEditing,
  onClick,
  onSelect,
}: IActivityStatusSelectorProps) {
  const remainingStatuses = Object.values(ActivityStatus).filter(
    (status) => status !== activity.status,
  );

  return (
    <>
      <ActivityStatusButton
        label={statusNameLookup[activity.status]}
        color={statusColorLookup[activity.status]}
        onClick={onClick}
      />

      {isEditing && (
        <div className="flex ml-2 pl-2 border-l-2 space-x-2">
          {remainingStatuses.map((status) => (
            <ActivityStatusButton
              key={`status_${status}`}
              label={statusNameLookup[status]}
              color={statusColorLookup[status]}
              onClick={(e) => {
                onSelect(status, e);
              }}
            />
          ))}
        </div>
      )}
    </>
  );
}
