import {
  $createParagraphNode,
  $getRoot,
  CreateEditorArgs,
  LexicalEditor,
  createEditor,
} from 'lexical';
import { useMemo, useState } from 'react';

type InitialisedStandaloneEditor = {
  editor: LexicalEditor;
  isEditorInitialized: true;
};

type UninitialisedStandaloneEditor = {
  editor: undefined;
  isEditorInitialized: false;
};

export type StandaloneEditor = InitialisedStandaloneEditor | UninitialisedStandaloneEditor;

/**
 * Basically a standalone editor without any UI that can be used for varios operations
 * that require an editor instance to work.
 *
 * @returns an editor instance and a boolean indicating whether the editor has been initialized.
 */
export function useStandaloneEditor(config: CreateEditorArgs): StandaloneEditor {
  const [isEditorInitialized, setIsEditorInitialized] = useState(false);
  // TODO: preferrably we should use a singleton editor instance,
  // could be done by e.g. zustand.
  const editor = useMemo(() => {
    setIsEditorInitialized(() => false);

    const editorConfig = {
      ...config,
      editable: false,
    };

    const _editor = createEditor(editorConfig);
    // Not allowed to have empty root node so we add a paragraph.
    _editor.update(
      () => {
        const root = $getRoot();
        if (root.isEmpty()) {
          root.append($createParagraphNode());
        }
      },
      {
        onUpdate: () => {
          setIsEditorInitialized(() => true);
        },
      },
    );
    return _editor;
  }, []);

  return isEditorInitialized
    ? { editor, isEditorInitialized }
    : { editor: undefined, isEditorInitialized };
}
