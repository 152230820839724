import { useMsal } from '@azure/msal-react';
import { useEffect, useRef } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, signUpRequest } from '../../shared/auth/authConfig';
import { environment } from '../../shared/utils/configuration';

export function SignUp() {
  const { instance: msalInstance, inProgress } = useMsal();
  const redirectStartedRef = useRef(false);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None || redirectStartedRef.current) return;

    redirectStartedRef.current = true;
    if (environment === 'Production') {
      // Below redirect causes an unhandled exception that I can't figure out.
      // "BrowserAuthError: interaction_in_progress: Interaction is currently in progress.
      // Please ensure that this interaction has been completed before calling an interactive API."
      // It's weird that this redirect does that but not the one below. Also this request doesn't
      // give the same error on the /signin route.
      msalInstance.loginRedirect(loginRequest);
    }

    msalInstance.loginRedirect(signUpRequest);
  }, [inProgress, msalInstance]);

  return <div />;
}
