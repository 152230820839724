import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { InviteRequestType } from '../models/InviteRequestType';
import { deleteInviteRequest, getInviteRequest } from '../services/inviteRequestService';

interface IUseNetworkInviteRequestProps {
  type?: 'Sent' | 'Received';
  status?: ConnectionRequestStatuses;
}

const networkInviteRequestKeys = {
  all: ['network-invite-request'] as const,
  lists: () => [...networkInviteRequestKeys.all, 'list'] as const,
  list: (props: IUseNetworkInviteRequestProps) =>
    [...networkInviteRequestKeys.lists(), props] as const,
};

export function useNetworkInviteRequestsQuery({ type, status }: IUseNetworkInviteRequestProps) {
  return useQuery({
    queryKey: networkInviteRequestKeys.list({ type, status }),
    queryFn: () =>
      getInviteRequest(
        InviteRequestType.Network,
        new QueryParam('type', type),
        new QueryParam('status', status),
      ),
  });
}

interface IDeleteNetworkInviteRequestProps {
  id: string;
}

export function useDeleteNetworkInviteRequestMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id }: IDeleteNetworkInviteRequestProps) =>
      deleteInviteRequest(InviteRequestType.Network, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: networkInviteRequestKeys.lists() });
    },
    onError: dispatchError,
  });
}
