import { UserPlusIcon } from 'lucide-react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { CardList } from '../../shared/components/cards/CardList';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { MemberCard } from './MemberCard';
import { IMemberPrivileges } from './models/IMemberPrivileges';
import { IMember } from './models/IMember';
import { useMembersQuery, useRemoveMemberMutation } from './queries/MemberQueries';
import Howto from '../../shared/components/message-popups/HowTo';
import { ResourceType } from '../../shared/model/ResourceType';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';
import useModal from '../../shared/components/modal/hooks/useModal';
import InviteMember from './MemberInvite';
import useConfirm from '../../shared/components/alert-dialog/hooks/useConfirm';

interface IMemberListProps {
  type: ResourceType;
  resourceIds: ResourceIds;
  tenantId: string;
  privileges: IMemberPrivileges;
  resolveConfirmText: (member: IMember) => string;
  resolveHelpText: () => string;
}

export default function MemberList({
  type,
  resourceIds,
  tenantId,
  privileges,
  resolveConfirmText,
  resolveHelpText,
}: IMemberListProps) {
  const { openModal, closeModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const membersQuery = useMembersQuery(type, resourceIds);
  const removeMemberMutation = useRemoveMemberMutation(type);

  const handleRemove = (member: IMember) => {
    openConfirm({
      title: 'Remove member',
      description: resolveConfirmText(member),
      actionText: 'Remove',
      onAction: () => {
        removeMemberMutation.mutate(
          { member },
          {
            onSuccess: () => {
              closeConfirm();
            },
          },
        );
      },
    });
  };

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      {privileges?.canInviteMember && (
        <ButtonGroup>
          <Button
            icon={<UserPlusIcon className="h-4 w-4 inline-block pr-1" aria-hidden="true" />}
            color={ButtonColors.White}
            text="Invite members"
            onClick={() =>
              openModal({
                title: 'Invite member',
                content: (
                  <InviteMember type={type} resourceIds={resourceIds} onComplete={closeModal} />
                ),
              })
            }
          />
        </ButtonGroup>
      )}
      <ScrollBarWrapper>
        <CardList>
          {membersQuery.data?.map((member) => (
            <MemberCard
              key={member.userId}
              member={member}
              type={tenantId === member.userId ? 'Owner' : 'Member'}
              privileges={{ ...privileges }}
              onRemove={() => handleRemove(member)}
            />
          ))}
        </CardList>
      </ScrollBarWrapper>

      <div className="flex pt-2">
        <Howto title="Members" description={resolveHelpText()} />
      </div>
    </div>
  );
}
