import { FieldErrors, FieldValues, Path } from 'react-hook-form';
import React from 'react';
import Label from '../text/Label';
import ValidationErrorMessage from '../inputs/text/ValidationErrorMessage';

interface InputFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  infoText?: string;
  children: React.ReactNode;
  errors?: FieldErrors;
}

export default function FieldWrapper<T extends object>({
  name,
  label,
  infoText,
  errors,
  children,
}: InputFieldProps<T>) {
  return (
    <>
      <Label
        htmlFor={name}
        text={label}
        infoText={infoText}
        extra={<ValidationErrorMessage errors={errors} name={name} />}
      />

      {children}
    </>
  );
}
