import { useParams } from 'react-router';
import { useBoardsQuery } from '../queries/boardQueries';

export default function useBoardNavigation() {
  const { tenantId, projectId, boardId } = useParams();
  const boardsQuery = useBoardsQuery({ projectId: projectId || '' });

  if (!boardsQuery.isSuccess) {
    return { items: [] };
  }

  const boards = boardsQuery.data.data.filter((b) => b.id !== boardId);

  if (boards.length === 0) {
    return { items: [] };
  }

  const items = boards.map((board) => ({
    id: board.id,
    name: board.name,
    url: `/companies/${tenantId}/projects/${projectId}/boards/${board.id}`,
  }));

  return { items };
}
