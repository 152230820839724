import React, { useRef, useState } from 'react';
import ReactCrop, { Crop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';

interface IProps {
  imgSource: string; // base64 data
  aspect?: number; // 1 is a square
  maxHeight?: number; // px
  maxWidth?: number; // px
  toBlobCallback: BlobCallback;
}

export function ImageCrop({
  imgSource,
  aspect = 1,
  maxHeight = 750,
  maxWidth = 750,
  toBlobCallback,
}: IProps) {
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const unit = '%';
    setCrop(
      makeAspectCrop(
        width > height ? { unit, width: 100 } : { unit, height: 100 },
        aspect,
        width,
        height,
      ),
    );
  };

  const onChange = (_: PixelCrop, _crop: PercentCrop) => {
    setCrop(_crop);
  };

  const onComplete = async (_crop: PixelCrop) => {
    const image = imgRef.current;
    if (!image) return;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('No 2d context');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const cropX = _crop.x * scaleX;
    const cropY = _crop.y * scaleY;
    const cropWidth = _crop.width * scaleX;
    const cropHeight = _crop.height * scaleY;

    canvas.width = Math.floor(cropWidth < maxWidth ? cropWidth : maxWidth);
    canvas.height = Math.floor(cropHeight < maxHeight ? cropHeight : maxHeight);

    ctx?.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(toBlobCallback, 'image/jpeg'); // JPEG is preferrable for photos.
  };

  return (
    <ReactCrop
      crop={crop}
      aspect={aspect}
      onChange={onChange}
      onComplete={onComplete}
      circularCrop
      keepSelection
    >
      <img ref={imgRef} src={imgSource} alt="To crop" onLoad={onImageLoad} />
    </ReactCrop>
  );
}
