export interface IPagedResult<T> {
  next?: string;
  data: Array<T>;
}

export function isPagedResult<TData>(
  result: IPagedResult<TData> | Array<TData>,
): result is IPagedResult<TData> {
  return (result as IPagedResult<TData>).data !== undefined;
}
