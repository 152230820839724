/* eslint-disable no-bitwise */
import { LexicalEditor } from 'lexical';
import { useLexicalMarkdownContent } from '../../shared/lexical/markdown/useLexicalMarkdownContent';
import { MARKDOWN_TRANSFORMERS } from './editor/messageEditorConfig';

interface IMessageContentProps {
  text: string;
  editor: LexicalEditor;
}

export function MessageContent({ text, editor }: IMessageContentProps) {
  const content = useLexicalMarkdownContent({
    markdown: text,
    editor,
    transformers: MARKDOWN_TRANSFORMERS,
  });
  return <>{content}</>;
}
