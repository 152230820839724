import useConfirm from './hooks/useConfirm';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from './AlertDialog';

export default function ConfirmDialog() {
  const { isConfirmOpen, closeConfirm, confirmContentProps } = useConfirm();

  return (
    <AlertDialog open={isConfirmOpen}>
      <AlertDialogContent>
        <AlertDialogTitle>{confirmContentProps.title}</AlertDialogTitle>
        <AlertDialogDescription>{confirmContentProps.description}</AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogAction
            color={confirmContentProps.actionColor}
            onClick={confirmContentProps.onAction}
          >
            {confirmContentProps.actionText}
          </AlertDialogAction>
          <AlertDialogCancel onClick={closeConfirm}>Cancel</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
