import { Badge } from '../badge/Badge';

export interface IActivityBadgeProps {
  icon: JSX.Element;
  classNames: string;
  value?: number;
  label?: string;
}

export default function ActivityBadge({ icon, classNames, value, label }: IActivityBadgeProps) {
  const maxCount = 99;
  return (
    <span className="flex items-center ">
      <span
        className={`relative rounded-2xl w-8 h-6 flex items-center justify-center  ${classNames}`}
      >
        {icon}
        {value !== undefined && (
          <Badge
            className="absolute min-w-4 px-[2px] right-[-10px] bottom-[-5px]"
            variant={value === 0 ? 'outline' : 'default'}
          >
            <>{value > maxCount ? `${maxCount}+` : value}</>
          </Badge>
        )}
      </span>
      {label && <span className="ml-2">{label}</span>}
    </span>
  );
}
