import { forwardRef } from 'react';

const TextInput = forwardRef<HTMLInputElement>(({ ...rest }, ref) => (
  <input
    type="text"
    ref={ref}
    {...rest}
    className="shadow-sm placeholder:text-gray-400 block w-full border-gray-300 rounded-md disabled:bg-gray-200 focus:ring-0 focus:border-zinc-400"
  />
));

export default TextInput;
