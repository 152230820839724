import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

// Define the variant styles using CVA
const flexContainerStyles = cva('flex', {
  variants: {
    direction: {
      row: 'flex-row',
      column: 'flex-col',
      'row-reverse': 'flex-row-reverse',
      'column-reverse': 'flex-col-reverse',
    },
    align: {
      start: 'items-start',
      center: 'items-center',
      end: 'items-end',
      stretch: 'items-stretch',
      baseline: 'items-baseline',
    },
    justify: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly',
    },
    wrap: {
      nowrap: 'flex-nowrap',
      wrap: 'flex-wrap',
      'wrap-reverse': 'flex-wrap-reverse',
    },
    gap: {
      none: 'gap-0',
      small: 'gap-2',
      medium: 'gap-4',
      large: 'gap-6',
      xl: 'gap-8',
    },
    flex: {
      1: 'flex-1',
      auto: 'flex-auto',
      initial: 'flex-initial',
      none: 'flex-none',
    },
  },
  defaultVariants: {
    direction: 'row',
    align: 'stretch',
    justify: 'start',
    wrap: 'nowrap',
    gap: 'medium',
  },
});

// Define the component props, extending the variants
interface FlexContainerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof flexContainerStyles> {}

// The FlexContainer component
const FlexContainer = React.forwardRef<HTMLDivElement, FlexContainerProps>(
  ({ direction, align, justify, wrap, gap, flex, className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={clsx(
        flexContainerStyles({ direction, align, justify, wrap, gap, flex }),
        className,
      )}
      {...props}
    >
      {children}
    </div>
  ),
);

FlexContainer.displayName = 'FlexContainer';

export default FlexContainer;
