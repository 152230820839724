import { InfiniteData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IContactNoteDto } from '../models/IContactNoteDto';
import {
  createContactNote,
  deleteContactNote,
  getContactNotes,
  updateContactNote,
  moveContactNote,
} from '../services/contactService';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { IMoveContactNoteDto } from '../models/IMoveContactNoteDto';
import { CalculateDragDropPositions } from '../../../shared/utils/dragDropUtils';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import { IPagedResult } from '../../../shared/model/IPagedResult';
import ConvertFlatListToInfiniteDataPages from '../../../shared/utils/pageUtils';
import { updateInfiniteQueriesData } from '../../../shared/utils/queryClientUtils';

export interface IUsePrivateNoteProps {
  contactId: string;
  limit?: number;
}

export interface ICreateUpdatePrivateNoteProps {
  contactId: string;
  note: IContactNoteDto;
}

export interface IDeletePrivateNoteProps {
  contactId: string;
  note: IContactNoteDto;
}

export const privateNoteKeys = {
  all: ['private-notes'] as const,
  lists: () => [...privateNoteKeys.all, 'list'] as const,
  list: (props: IUsePrivateNoteProps) => [...privateNoteKeys.lists(), props] as const,
};

export function usePrivateNotes(contactId: string) {
  return useQuery({
    queryKey: privateNoteKeys.list({ contactId }),
    queryFn: () => getContactNotes(contactId),
  });
}

export function usePrivateNotesPagesQuery(props: IUsePrivateNoteProps) {
  return useInfiniteApiQuery<IContactNoteDto>({
    queryKey: privateNoteKeys.list({ contactId: props.contactId }),
    queryFn: () => getContactNotes(props.contactId, props.limit),
  });
}

export function useCreateUpdatePrivateNoteMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ contactId, note }: ICreateUpdatePrivateNoteProps) => {
      if (note.id) {
        return updateContactNote(contactId, note.id, note);
      }
      return createContactNote(contactId, note);
    },
    onMutate: ({ contactId, note }) => {
      const updateFunc = note.id
        ? (notes: IContactNoteDto[]) => notes.map((_note) => (_note.id === note.id ? note : _note))
        : (notes: IContactNoteDto[]) => [...notes, note];

      updateInfiniteQueriesData<IContactNoteDto>(
        queryClient,
        privateNoteKeys.list({ contactId }),
        updateFunc,
      );
    },
    onSuccess: (_, { contactId }) => {
      queryClient.invalidateQueries({ queryKey: privateNoteKeys.list({ contactId }) });
    },
    onError: dispatchError,
  });
}

export function useDeletePrivateNoteMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ contactId, note }: ICreateUpdatePrivateNoteProps) =>
      deleteContactNote(contactId, note.id),
    onSuccess: (_, { contactId }) => {
      queryClient.invalidateQueries({ queryKey: privateNoteKeys.list({ contactId }) });
    },
    onError: dispatchError,
  });
}

interface IMoveNotesProps {
  contactId: string;
  noteId: string;
  destinationIndex: number;
  note: IMoveContactNoteDto;
  limit: number;
}

export function useMoveContactNotesMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();

  return useMutation({
    mutationFn: ({ contactId, noteId, note }: IMoveNotesProps) =>
      moveContactNote(contactId, noteId, note),
    onMutate: ({ contactId, noteId, destinationIndex, limit }) => {
      const privateNotes = queryClient.getQueryData(
        privateNoteKeys.list({ contactId }),
      ) as InfiniteData<IPagedResult<IContactNoteDto>>;

      const privateNotesData = privateNotes?.pages
        .flatMap((i) => i.data)
        ?.flat() as IContactNoteDto[];

      const { targetItems } = CalculateDragDropPositions<IContactNoteDto>(
        privateNotesData,
        privateNotesData,
        noteId,
        destinationIndex,
      );

      privateNotes.pages = ConvertFlatListToInfiniteDataPages<IContactNoteDto>(
        privateNotes,
        targetItems,
        limit,
      );

      queryClient.setQueryData(privateNoteKeys.list({ contactId }), privateNotes);

      return { privateNotes };
    },
    onSuccess: (_, { contactId }) => {
      queryClient.invalidateQueries({ queryKey: privateNoteKeys.list({ contactId }) });
    },
    onError: (error, { contactId }, oldData) => {
      if (oldData) {
        queryClient.setQueryData(privateNoteKeys.list({ contactId }), oldData);
      }
      dispatchError(error);
    },
  });
}
