import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import Text from '../../shared/components/text/Text';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import Header1 from '../../shared/components/headers/Header1';
import { UserProfileDto } from './models/UserProfile';
import { useUpdateMyUserProfileMutation } from './queries/userProfileQueries';
import { useMyUserPhotoMutation } from './queries/userPhotoQueries';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import {
  RadioGroup,
  RadioGroupItem,
  RadioGroupItemWrapper,
} from '../../shared/components/radiobuttons/RadioGroup';
import Label from '../../shared/components/text/Label';
import FlexContainer from '../../shared/components/structure/FlexContainer';

interface IProps {
  myProfile: UserProfileDto;
}

export default function MyProfileForm({ myProfile }: IProps) {
  const myProfileMutation = useUpdateMyUserProfileMutation();
  const myProfilePhotoMutation = useMyUserPhotoMutation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    values: myProfile,
  });
  const isMobile = useCheckMobileScreen();

  const onSubmit: SubmitHandler<UserProfileDto> = (_myProfile) => {
    const _myProfileClone = { ..._myProfile };
    // There is automatically an empty default value added to phoneNumbers array
    // i.e. [{ value: '' }]. We need to remove this empty value before submitting
    // Look for a better way of solving this later
    _myProfileClone.phoneNumbers = _myProfileClone.phoneNumbers.filter(
      (phoneNumber) => phoneNumber.value !== '',
    );

    myProfileMutation.mutate(_myProfileClone);
  };

  const handlePhotoModalUpload = (file: Blob) => {
    myProfilePhotoMutation.mutate({ file });
  };

  const handleRemoveProfilePicture = () => {
    myProfilePhotoMutation.mutate({});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-3 divide-x-0 md:divide-x-2 ">
        <div className="px-4 md:px-12 flex flex-col gap-6">
          <div>
            <Header1>Profile</Header1>
            <ProfileAvatarForm
              url={myProfile.photoUrl}
              altText={myProfile.firstName}
              onUpload={handlePhotoModalUpload}
              onRemove={handleRemoveProfilePicture}
              isPending={myProfileMutation.isPending}
            />
          </div>
          {!isMobile && (
            <div>
              <TextAreaInput
                labelText="About me"
                name="additionalInformation"
                register={register}
                errors={errors}
                errorMessage="Maximum 4000 characters"
                validationRules={{ maxLength: 4000 }}
                className="bg-white"
              />
            </div>
          )}
        </div>
        <div className="px-4 md:px-12 flex flex-col gap-6 md:m-0 mb-4 mt-4">
          <div className="flex flex-row gap-6">
            <div className="flex-1">
              <TextFieldInput
                labelText="First name"
                name="firstName"
                type="text"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'First name is Required',
                  minLength: { value: 2, message: 'Required & between 2 & 400 characters' },
                  maxLength: { value: 400, message: 'Required & between 2 & 400 characters' },
                }}
              />
            </div>
            <div className="flex-1">
              <TextFieldInput
                labelText="Last name"
                name="lastName"
                type="text"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'Last name is Required',
                  minLength: { value: 2, message: 'Required & between 2 & 400 characters' },
                  maxLength: { value: 400, message: 'Required & between 2 & 400 characters' },
                }}
              />
            </div>
          </div>
          <div>
            <TextFieldInput
              labelText="Phone number"
              name="phoneNumbers.0.value"
              type="text"
              register={register}
              errors={errors}
              validationRules={{ maxLength: { value: 20, message: 'Maximum 20 characters' } }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Email address"
              name="primaryEmailAddress"
              type="text"
              disabled
              register={register}
              errors={errors}
              validationRules={{
                required: 'Email is required',
                minLength: { value: 2, message: 'Required & between 2 & 400 characters' },
                maxLength: { value: 400, message: 'Required & between 2 & 400 characters' },
                pattern: { value: REGEX_EMAIL, message: 'Must be a valid email' },
              }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Company name"
              name="company"
              type="text"
              register={register}
              errors={errors}
              validationRules={{ maxLength: { value: 400, message: 'Maximum 400 characters' } }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Title"
              name="title"
              type="text"
              register={register}
              errors={errors}
              validationRules={{ maxLength: { value: 400, message: 'Maximum 400 characters' } }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Location"
              name="location"
              type="text"
              register={register}
              errors={errors}
              validationRules={{ maxLength: { value: 400, message: 'Maximum 400 characters' } }}
            />
          </div>
          {isMobile && (
            <div>
              <TextAreaInput
                labelText="About me"
                name="additionalInformation"
                register={register}
                errors={errors}
                errorMessage="Maximum 4000 characters"
                validationRules={{ maxLength: 4000 }}
                className="bg-white"
              />
            </div>
          )}
        </div>
        <SpacingContainer className="px-4 md:px-12">
          <Controller
            name="visibility"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} onValueChange={field.onChange}>
                <FlexContainer direction="column">
                  <RadioGroupItemWrapper>
                    <RadioGroupItem value="Public" id="Public" />
                    <Label htmlFor="Public" text="Public" />
                  </RadioGroupItemWrapper>
                  <RadioGroupItemWrapper>
                    <RadioGroupItem value="Private" id="Private" />
                    <Label htmlFor="Private" text="Private" />
                  </RadioGroupItemWrapper>
                </FlexContainer>
              </RadioGroup>
            )}
          />

          <Text as="p" size="small" brightness="light" leading="snug">
            Having a public profile makes your profile searchable within Yoin.
          </Text>
        </SpacingContainer>
      </div>

      <div className="px-12 pt-6">
        <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
          <Button
            text="Revert changes"
            color={ButtonColors.White}
            disabled={!isDirty || myProfileMutation.isPending}
            onClick={() => reset(myProfile)}
          />
          <SubmitButton
            text="Save"
            isDisabled={!isValid || !isDirty || myProfileMutation.isPending}
          />
        </ButtonGroup>
      </div>
    </form>
  );
}
