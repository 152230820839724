import { useState } from 'react';
import Button from '../buttons/Button';
import ButtonGroup from '../buttons/ButtonGroup';
import { ImageCrop } from '../images/ImageCrop';
import { ButtonColors } from '../../constants/ButtonColors';
import Text from '../text/Text';

interface IProps {
  isUploadDisabled: boolean;
  imgSrc: string;
  onUpload: (blob: Blob) => void;
  onClose: () => void;
}

export function UploadProfilePhotoModal({ isUploadDisabled, imgSrc, onUpload, onClose }: IProps) {
  const [blob, setBlob] = useState<Blob | null>();

  const handleToBlob = (_blob: Blob | null) => {
    setBlob(_blob);
  };

  const close = () => {
    onClose();
  };

  const handleUpload: React.MouseEventHandler<Element> = () => {
    if (!blob) return;
    onUpload(blob);
    close();
  };

  return (
    <>
      <div className="pb-4">
        <ImageCrop imgSource={imgSrc} toBlobCallback={handleToBlob} />
      </div>
      <div className="pb-4">
        <Text as="p" size="small">
          Choose the area of your photo you want to use and then click &quot;Upload&quot;. You can
          resize and move the selection as you choose.
        </Text>
      </div>
      <ButtonGroup>
        <Button text="Cancel" color={ButtonColors.White} onClick={close} />
        <Button
          text="Upload"
          color={ButtonColors.Blue}
          onClick={handleUpload}
          disabled={isUploadDisabled && !blob}
        />
      </ButtonGroup>
    </>
  );
}
