import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { Ban } from 'lucide-react';
import { FieldErrors, FieldName, FieldValues, get, Path } from 'react-hook-form';

interface IProps<T extends FieldValues> {
  name: Path<T>;
  errors?: FieldErrors<T>;
}

function ValidationLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="inline-flex items-baseline ml-1.5 ">
      <Ban className="h-3 w-3 text-red-400" aria-hidden="true" />
      <span className="text-sm font-medium text-red-400 ml-1">{children}</span>
    </div>
  );
}

export default function ValidationErrorMessage<T extends FieldValues>({ name, errors }: IProps<T>) {
  const hasErrors = get(errors, name);

  return (
    <>
      {hasErrors && (
        <ValidationLayout>
          <ErrorMessage<FieldErrors<T>>
            errors={errors}
            name={name as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>}
          />
        </ValidationLayout>
      )}
    </>
  );
}

export function CustomValidationErrorMessage({ message }: { message: string }) {
  return (
    <ValidationLayout>
      <span className="text-sm font-medium text-red-400 ml-1">{message}</span>
    </ValidationLayout>
  );
}
