import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonColors } from '../../constants/ButtonColors';
import {
  ConversationAttachmentType,
  IConversationAttachmentDto,
} from '../../model/IConversationAttachmentDto';
import { getFileLease } from '../../services/conversationFileService';
import { downloadUrlByLink } from '../../utils/fileUtils';
import Button from '../buttons/Button';
import ButtonGroup from '../buttons/ButtonGroup';
import SubmitButton from '../buttons/SubmitButton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../drop-down-menu/DropdownMenu';
import TextFieldInput from '../inputs/text/TextFieldInput';
import {
  useDeleteFileAttachmentMutation,
  useRenameFileAttachmentMutation,
} from './queries/fileAttachmentQueries';
import {
  useDeleteLinkAttachmentMutation,
  useRenameLinkAttachmentMutation,
} from './queries/linkAttachmentQueries';
import useModal from '../modal/hooks/useModal';
import useConfirm from '../alert-dialog/hooks/useConfirm';
import { resolveDeleteConfirm } from '../alert-dialog/resolvers/confirmResolvers';

interface IRenameForm {
  name: string;
}

interface IProps {
  attachment: IConversationAttachmentDto;
}

interface IRenameAttachmentItemProps extends IProps {
  onSuccess: () => void;
  onCancel: () => void;
}

function RenameAttachmentModal({ attachment, onSuccess, onCancel }: IRenameAttachmentItemProps) {
  const renameLinkAttachmentMutation = useRenameLinkAttachmentMutation();
  const renameFileAttachmentMutation = useRenameFileAttachmentMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IRenameForm>({
    mode: 'onChange',
    defaultValues: {
      name: attachment.name ?? '',
    },
  });

  const handleSuccess = () => {
    onSuccess();
  };

  const submit = (data: IRenameForm) => {
    if (attachment.type === ConversationAttachmentType.File) {
      renameFileAttachmentMutation.mutate(
        { ...attachment, ...data },
        {
          onSuccess: handleSuccess,
        },
      );
    } else if (attachment.type === ConversationAttachmentType.Link) {
      renameLinkAttachmentMutation.mutate(
        { ...attachment, ...data },
        {
          onSuccess: handleSuccess,
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="text-left space-y-8">
      <div>
        <TextFieldInput
          labelText="Name"
          name="name"
          type="text"
          register={register}
          errors={errors}
          validationRules={{
            required: 'Name is required',
            maxLength: {
              value: 255,
              message: 'Name cannot exceed 255 characters',
            },
          }}
        />
      </div>
      <ButtonGroup>
        <Button color={ButtonColors.White} text="Cancel" onClick={onCancel} />
        <SubmitButton text="Rename" isDisabled={!isValid} />
      </ButtonGroup>
    </form>
  );
}

export function AttachmentActionMenu({ attachment }: IProps) {
  const [renameOpen, setRenameOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { openModal, closeModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const deleteLinkAttachmentMutation = useDeleteLinkAttachmentMutation();
  const deleteFileAttachmentMutation = useDeleteFileAttachmentMutation();

  const handleDownload = () => {
    getFileLease(attachment.conversationId, attachment.id).then(({ url }) => {
      downloadUrlByLink(url, attachment.name);
    });
  };

  const handleDelete = () => {
    if (attachment.type === ConversationAttachmentType.File) {
      deleteFileAttachmentMutation.mutate(attachment);
    } else if (attachment.type === ConversationAttachmentType.Link) {
      deleteLinkAttachmentMutation.mutate(attachment);
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisHorizontalIcon className="w-5 h-5" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" hidden={renameOpen || deleteOpen}>
          {attachment.type === ConversationAttachmentType.File && (
            <DropdownMenuItem onClick={handleDownload}>Download</DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => {
              setRenameOpen(true);
              openModal({
                title: 'Rename Attachment',
                content: (
                  <RenameAttachmentModal
                    attachment={attachment}
                    onSuccess={() => {
                      closeModal();
                      setRenameOpen(false);
                    }}
                    onCancel={() => {
                      closeModal();
                      setRenameOpen(false);
                    }}
                  />
                ),
              });
            }}
          >
            Rename
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setDeleteOpen(true);
              openConfirm({
                ...resolveDeleteConfirm('attachment'),
                description: `Are you sure that you want to delete the ${
                  attachment.type === ConversationAttachmentType.File ? 'file' : 'link'
                } ${attachment.name}?`,
                onAction: () => {
                  handleDelete();
                  closeConfirm();
                  setDeleteOpen(false);
                },
              });
            }}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
