import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

// Define the variant styles using CVA
const flexItemStyles = cva('', {
  variants: {
    self: {
      auto: 'self-auto',
      start: 'self-start',
      center: 'self-center',
      end: 'self-end',
      stretch: 'self-stretch',
    },
    grow: {
      true: 'flex-grow',
      false: 'flex-grow-0',
    },
    shrink: {
      true: 'flex-shrink',
      false: 'flex-shrink-0',
    },
    basis: {
      auto: 'basis-auto',
      full: 'basis-full',
      half: 'basis-1/2',
      third: 'basis-1/3',
      quarter: 'basis-1/4',
      // Add more as needed
    },
    flex: {
      1: 'flex-1',
      auto: 'flex-auto',
      initial: 'flex-initial',
      none: 'flex-none',
    },
    justifySelf: {
      start: 'justify-self-start',
      center: 'justify-self-center',
      end: 'justify-self-end',
      stretch: 'justify-self-stretch',
      auto: 'justify-self-auto',
    },

    // Additional variants can be added here
  },
  defaultVariants: {
    grow: false,
    shrink: true,
    basis: 'auto',
  },
});

// Define the component props, extending the variants
interface FlexItemProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof flexItemStyles> {}

// The FlexItem component
function FlexItem({ grow, shrink, basis, flex, className, children, ...props }: FlexItemProps) {
  return (
    <div className={clsx(flexItemStyles({ grow, shrink, basis, flex }), className)} {...props}>
      {children}
    </div>
  );
}

export default FlexItem;
