import { useQuery } from '@tanstack/react-query';
import { IProjectPrivileges } from '../models/IProjectDto';
import { getPrivileges } from '../../Members/services/MemberService';
import { ResourceType } from '../../../shared/model/ResourceType';

const projectPrivilegesKeys = {
  all: ['project-privileges'] as const,
  details: () => [...projectPrivilegesKeys.all, 'detail'] as const,
  detail: (id: string) => [...projectPrivilegesKeys.details(), id] as const,
};

export function useProjectPrivilegesQuery(id?: string) {
  return useQuery({
    queryKey: projectPrivilegesKeys.detail(id as string),
    queryFn: () => getPrivileges(ResourceType.Project, id as string),
    enabled: !!id,
    select: (data): IProjectPrivileges => ({
      canEdit: data.privileges.includes('Project.ReadWrite'),
      canDelete: data.privileges.includes('Project.Delete'),
      canReadMembers: data.privileges.includes('Project.Member.Read'),
      canInviteMember: data.privileges.includes('Project.Member.Invite'),
      canRemoveMember: data.privileges.includes('Project.Member.Remove'),
      canCreateBoard: data.privileges.includes('Project.Board.Create'),
      canReadActivities: data.privileges.includes('Project.Activity.Read'),
      canWriteActivities: data.privileges.includes('Project.Activity.ReadWrite'),
    }),
  });
}
