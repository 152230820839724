import { Draggable } from 'react-beautiful-dnd';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CardList } from '../../shared/components/cards/CardList';
import { useDeletePipelineItemMutation, usePipelineItemsQuery } from './queries/itemQueries';
import { Card } from '../../shared/components/cards/Card';
import { usePipelineStoreContext } from './context/pipeline-context';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { IPipelineItemDto, ISharedResourceDto } from './models/IPipelineItemDto';
import { usePipelineQuery } from './queries/pipelineQueries';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import { ResourceType } from '../../shared/model/ResourceType';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { cn } from '../../lib/utils';
import HeaderSubHeaders from '../../shared/components/headers/HeaderSubHeaders';
import useModal from '../../shared/components/modal/hooks/useModal';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import PipelineEditItem from './PipelineEditItem';
import { IStageDto } from './models/IStageDto';

export type IPipelineItemCardProps = {
  children?: React.ReactNode;
};

export function PipelineItemCardContainer({ children }: IPipelineItemCardProps) {
  return <div className="flex flex-row space-x-2">{children}</div>;
}

function ProfileImageWrapper({ sharedResource }: { sharedResource?: ISharedResourceDto }) {
  const payload = sharedResource?.payload;
  const manifest = useEntityManifest(sharedResource?.resourceType || ResourceType.PipelineItem);
  const photoUrl = payload?.photoUrl || manifest.icon;
  return (
    <ProfileAvatar
      avatarProps={{
        src: photoUrl,
        widthClass: 'w-8',
        heightClass: 'h-8',
        alt: payload?.displayName || null,
      }}
    />
  );
}

interface IPipelineItemListProps {
  stage: IStageDto;
}

export default function PipelineItemList({ stage }: IPipelineItemListProps) {
  const { activePipelineSettings, selectItem, resetPipelineSettings } = usePipelineStoreContext();
  const isMobile = useCheckMobileScreen();
  const { openModal } = useModal();
  const pipelineItemsQuery = usePipelineItemsQuery({
    pipelineId: activePipelineSettings.pipelineId,
    stageId: stage.id,
  });
  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);
  const deletePipelineItemMutation = useDeletePipelineItemMutation();

  const handleDeleteItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: IPipelineItemDto,
  ) => {
    e.stopPropagation();
    resetPipelineSettings();
    deletePipelineItemMutation.mutate({
      itemId: item.id,
      pipelineId: activePipelineSettings.pipelineId,
      stageId: item.stageId,
    });
  };

  const handleSelectItem = (_item: IPipelineItemDto, _stage: IStageDto) => {
    selectItem(_item, _stage.id);
    // TODO: Only used until new mobile design is finished
    if (isMobile) {
      openModal({
        content: (
          <PipelineEditItem
            activePipelineSettings={{
              pipelineId: _stage.pipelineId,
              stageId: _stage.id,
              item: _item,
            }}
            hideHeader
          />
        ),
        title: _item.name,
        modalSize: 'fullscreen',
      });
    }
  };

  if (!pipelineItemsQuery.isSuccess || pipelineItemsQuery.isLoading) return <></>;

  return (
    <CardList>
      {pipelineItemsQuery.data.map((item: IPipelineItemDto, index: number) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(provided) => (
            <Card
              as="div"
              draggableProvided={provided}
              className={cn(
                'group',
                activePipelineSettings.item.id === item.id &&
                  ' outline-cyan-500 outline outline-offset-[-2px]',
                item.sharedResource?.removedTime && 'opacity-50 !cursor-default',
              )}
              onClick={() => handleSelectItem(item, stage)}
            >
              <PipelineItemCardContainer>
                <ProfileImageWrapper sharedResource={item.sharedResource} />
                <HeaderSubHeaders
                  size="small"
                  text={item.name}
                  subTexts={[
                    item.sharedResource?.payload?.displayName || '',
                    item.value
                      ? `${new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 0 }).format(
                          item.value,
                        )} ${pipelineQuery?.data?.currencyIsoCode} `
                      : '',
                  ]}
                />
              </PipelineItemCardContainer>
              <IconButton
                icon={
                  <XMarkIcon className="w-4 h-4 absolute right-1.5 top-1.5 invisible group-hover:visible" />
                }
                color={ButtonColors.Red}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleDeleteItem(e, item)
                }
              />
            </Card>
          )}
        </Draggable>
      ))}
    </CardList>
  );
}
