import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { projectKeys } from './projectQueries';
import { deleteProjectPhoto, uploadProjectPhoto } from '../services/projectService';

type MutateProjectPhotoProps = {
  id: string;
  file?: Blob;
};

export function useProjectPhotoMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id, file }: MutateProjectPhotoProps) => {
      if (typeof file === 'undefined') {
        return deleteProjectPhoto(id);
      }

      return uploadProjectPhoto(id, file);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: projectKeys.detail(id) });
      queryClient.invalidateQueries({ queryKey: projectKeys.lists() });
    },
    onError: dispatchError,
  });
}
