import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formatISO } from 'date-fns';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import { useMyUserId } from '../../../shared/auth/accountHooks';
import { IConnectionRequestDto } from '../../../shared/model/IConnectionRequestDto';
import {
  deleteConnectionRequest,
  getConnectionRequests,
} from '../../../shared/services/connectionRequestService';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { connectWithUser } from '../../Profile/services/userActionService';
import { useToast } from '../../../shared/components/toasts/use-toast';

export interface IUseConnectionRequestsProps {
  userId?: string;
  status?: ConnectionRequestStatuses;
  type?: 'Sent' | 'Received';
}

const connectionRequestKeys = {
  all: ['connection-requests'] as const,
  lists: () => [...connectionRequestKeys.all, 'list'] as const,
  list: (props: IUseConnectionRequestsProps) => [...connectionRequestKeys.lists(), props] as const,
};

export function useConnectionRequestsQuery(props: IUseConnectionRequestsProps) {
  return useQuery({
    queryKey: connectionRequestKeys.list(props),
    queryFn: () => getConnectionRequests(props),
  });
}

export function useConnectionIsInitatedQuery(userId?: string) {
  const props = { userId, status: ConnectionRequestStatuses.Pending };
  return useQuery({
    queryKey: connectionRequestKeys.list(props),
    queryFn: () => getConnectionRequests(props),
    select: (data) => data.length > 0,
    enabled: !!userId,
  });
}

export interface ICreateConnectionRequestProps {
  userId: string;
  displayName: string;
}

export function useConnectWithUserMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  const { toast } = useToast();
  const myUserId = useMyUserId();
  return useMutation({
    mutationFn: ({ userId }: ICreateConnectionRequestProps) => connectWithUser(userId),
    onMutate: ({ userId }) => {
      const connectionRequest: IConnectionRequestDto = {
        id: '',
        requestorUserId: myUserId || '',
        targetUserId: userId,
        status: ConnectionRequestStatuses.Pending,
        createdTime: formatISO(new Date()),
      };
      queryClient.setQueryData(
        connectionRequestKeys.list({
          userId,
          status: ConnectionRequestStatuses.Pending,
        }),
        (data?: IConnectionRequestDto[]) => {
          if (!data) return [connectionRequest];
          return [...data, connectionRequest];
        },
      );
      return connectionRequest;
    },
    onSuccess: (_, { displayName }) => {
      queryClient.invalidateQueries({ queryKey: connectionRequestKeys.lists() });

      toast({
        title: 'Success',
        variant: 'success',
        description: `A connection request has been sent to ${displayName}`,
      });
    },
    onError: (error: unknown, { userId }) => {
      queryClient.setQueryData(
        connectionRequestKeys.list({
          userId,
          status: ConnectionRequestStatuses.Pending,
        }),
        (data?: IConnectionRequestDto[]) => data?.filter((cr) => cr.targetUserId !== userId) ?? [],
      );
      dispatchError(error);
    },
  });
}

interface IDeleteConnectionRequestProps {
  id: string;
}

export function useDeleteConnectionRequestMutation() {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ id }: IDeleteConnectionRequestProps) => deleteConnectionRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: connectionRequestKeys.lists() });
    },
    onError: dispatchError,
  });
}
