import Text from '../../shared/components/text/Text';

interface IProps {
  text: string;
}
export default function MessageDivider({ text }: IProps) {
  return (
    <div className="relative my-3">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <div className="bg-white px-2">
          <Text size="small" brightness="light">
            {text}
          </Text>
        </div>
      </div>
    </div>
  );
}
