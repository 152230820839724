import Text from '../../shared/components/text/Text';
import { IReaction } from '../../shared/model/IReaction';

interface IProps {
  reaction?: IReaction;
  text?: string;
  count?: number;
  onClick: (reaction: IReaction) => void;
}

export default function MessageEmoji({ reaction, text, count, onClick }: IProps) {
  return (
    <button
      type="button"
      onClick={() => onClick(reaction as IReaction)}
      className="cursor-pointer outline-0"
    >
      {text ? (
        <div className="px-1">
          <Text as="span" size="small" brightness="light" weight="bold">
            {text}
          </Text>
        </div>
      ) : (
        <div className={`${(count ?? 0) > 1 ? 'px-1' : ''}`}>
          <Text as="span" size="small" brightness="light" weight="bold">
            {reaction?.reactionValue}
            {(count ?? 0) > 1 ? `${count}` : ''}
          </Text>
        </div>
      )}
    </button>
  );
}
