import { useGlobalStore } from '../../shared/store/useGlobalStore';
import ProjectsCardView from './ProjectsCardView';
import ProjectsListView from './ProjectsListView';

export default function ProjectsSearch() {
  const activeViewLayout = useGlobalStore(
    (state) => state.getViewSettings('project').activeViewLayout,
  );

  return <>{activeViewLayout === 'card' ? <ProjectsCardView /> : <ProjectsListView />}</>;
}
