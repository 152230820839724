import { ColumnDef, Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { ListLayout } from '../../shared/components/layout/pages/ListLayout';
import { createDataTableColumnHelper } from '../../shared/components/table/DataTable';

import ProfileRowCell from '../../shared/components/table/cells/ProfileRowCell';
import StandardRowCell from '../../shared/components/table/cells/StandardRowCell';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { createRoute } from '../../shared/components/navigation/helpers/routingHelpers';
import { useFilters } from '../../shared/components/filters/hooks/useFilters';
import { useContactsInfiniteQuery } from './queries/contactQueries';
import { useContactsActions } from './hooks/useContactsActions';
import { IContactDto } from './models/IContactDto';
import { useOrganizationsQuery } from '../Companies/queries/organizationQueries';
import { FilterConfig } from '../../shared/components/filters/hooks/types';
import { useActivitiesPagesQuery } from '../Activities/queries/activitiesQueries';
import ExpandableRowCell from '../../shared/components/table/cells/ExpandableRowCell';
import { ContactFilterState } from './config/contactFilterConfig';

// TODO: Add back and adjust when we implement expandable rows
function ContactSubComponent({ row }: { row: Row<IContactDto> }) {
  const activitiesQuery = useActivitiesPagesQuery(row.original.id, ResourceType.Contact);

  const activities = activitiesQuery.data?.pages.flat() || [];

  if (activitiesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ml-10">
      {activities.map((activity) => (
        <div key={activity.id}>{activity.title}</div>
      ))}
    </div>
  );
}

function useContactListColumns() {
  const contactManifest = useEntityManifest(ResourceType.Contact);
  return useMemo(() => {
    const columnHelper = createDataTableColumnHelper<IContactDto>();

    return [
      // columnHelper.multiSelect(),
      columnHelper.accessor('displayName', {
        id: 'displayName',
        header: 'Name',
        minSize: 300,
        cell: ({ row, getValue }) => (
          <ExpandableRowCell row={row}>
            <ProfileRowCell
              photoUrl={row.original.photoUrl || contactManifest.icon}
              url={createRoute('contacts', row.original.id, 'info')}
              name={getValue()}
            />
          </ExpandableRowCell>
        ),
      }),

      columnHelper.accessor('title', {
        id: 'title',
        header: 'Title',
        cell: ({ getValue }) => <StandardRowCell text={getValue()} />,
      }),
      columnHelper.accessor('phoneNumbers', {
        id: 'phoneNumbers',
        header: 'Phone',
        cell: ({ getValue }) => (
          <StandardRowCell
            text={getValue()
              .map((i) => i.value)
              .join(',')}
          />
        ),
      }),

      columnHelper.accessor('emailAddresses', {
        id: 'emailAddresses',
        header: 'Email',
        cell: ({ getValue }) => (
          <StandardRowCell
            text={getValue()
              .map((i) => i.value)
              .join(',')}
          />
        ),
      }),
      columnHelper.accessor('location', {
        id: 'location',
        header: 'Location',
        meta: {
          size: '140px',
        },
        cell: ({ getValue }) => <StandardRowCell text={getValue()} />,
      }),
      columnHelper.accessor('company', {
        id: 'company',
        header: 'Company',
        meta: {
          size: '140px',
        },
        cell: ({ getValue }) => <StandardRowCell text={getValue()} />,
      }),
    ] as ColumnDef<IContactDto>[];
  }, []);
}

export function ContactsList() {
  const columns = useContactListColumns();
  const companies = useOrganizationsQuery().data?.pages.flatMap((page) => page);

  const filterConfig: FilterConfig<ContactFilterState>[] = [
    {
      key: 'tenantId',
      label: 'Company',
      type: 'dropdown',
      isMultiSelect: true,
      options:
        companies?.map((company) => ({
          label: company.name,
          value: company.id,
        })) || [],
    },
  ];

  const filters = useFilters(filterConfig);
  const contactQuery = useContactsInfiniteQuery({ ...filters.filterState });

  const actions = useContactsActions();

  if (contactQuery.isError) {
    return <ErrorComponent queryResults={[contactQuery]} />;
  }

  return (
    <ListLayout
      title="Contacts"
      columns={columns}
      query={contactQuery}
      headerActions={actions}
      // TODO: Add back when we implement expandable rows
      // renderSubComponent={(row) => <ContactSubComponent {...row} />}
    />
  );
}
