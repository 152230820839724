import { StateCreator } from 'zustand';
import { IProjectDto } from '../../../features/Projects/models/IProjectDto';
import { CombinedSlices } from '../useGlobalStore';

export type EditProjectTabs =
  | 'info'
  | 'members'
  | 'boards'
  | 'budget'
  | 'chat'
  | 'activities'
  | 'attachments';

export interface ProjectSlice {
  activeEditProjectTab: EditProjectTabs;
  isProjectConfirmLeaveOpen: boolean;
  isProjectConfirmDeleteOpen: boolean;
  currentProject: IProjectDto | null;

  setActiveEditProjectTab: (tab: EditProjectTabs) => void;
  setProjectIsConfirmLeaveOpen: (isOpen: boolean) => void;
  setProjectIsConfirmDeleteOpen: (isOpen: boolean) => void;
  setCurrentProject: (project: IProjectDto) => void;
}

export const createProjectSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  ProjectSlice
> = (set) => ({
  activeEditProjectTab: 'info',
  // TODO: all confirms should be migrated to a single confirm modal
  isProjectConfirmLeaveOpen: false,
  isProjectConfirmDeleteOpen: false,
  // TODO: Current project should not be stored in the global store
  currentProject: null,

  setActiveEditProjectTab: (tab) => set({ activeEditProjectTab: tab }),

  setProjectIsConfirmLeaveOpen: (isOpen) => set({ isProjectConfirmLeaveOpen: isOpen }),
  setProjectIsConfirmDeleteOpen: (isOpen) => set({ isProjectConfirmDeleteOpen: isOpen }),
  setCurrentProject: (project) => set({ currentProject: project }),
});
