import { $createTextNode, $insertNodes, COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { IEmoji } from '../../model/internal/IEmoji';

export const INSERT_EMOJI_COMMAND = createCommand<IEmoji>('INSERT_EMOJI_COMMAND');

export function EmojiPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(
    () =>
      editor.registerCommand(
        INSERT_EMOJI_COMMAND,
        (payload) => {
          const { native } = payload;
          $insertNodes([$createTextNode(native)]);
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    [],
  );
  return null;
}
