export function stringBuilder(separator?: string) {
  const strings: string[] = [];
  const api = {
    add: (value?: string) => {
      if (value) strings.push(value);
      return api;
    },
    toString: () => strings.join(separator || ' '),
  };

  return api;
}

export const cutText = (text: string, maxLength: number) => {
  const actualMaxLength = maxLength - 3;
  if (text.length <= maxLength) {
    return text;
  }

  const lastSpaceIndex = text.slice(0, actualMaxLength).lastIndexOf(' ');
  const truncatedText = `${text.slice(0, lastSpaceIndex)}...`;
  return truncatedText;
};

/* @deprecated Should be replaced with cn() */
export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

export function abbreviateString(input: string): string {
  if (input.length <= 6) {
    return input;
  }
  return `${input.substring(0, 6)}...`;
}
