import { useFormContext } from 'react-hook-form';
import TextFieldInput from '../../../../shared/components/inputs/text/TextFieldInput';
import Label from '../../../../shared/components/text/Label';
import CurrencyInput from '../../../../shared/components/inputs/number/CurrencyInput';
import FlexItem from '../../../../shared/components/structure/FlexItem';
import TextAreaInput from '../../../../shared/components/inputs/text/TextAreaInput';

type CreateItemProps = {
  currencyIsoCode: string;
};

export function PipelineCreateItemForm({ currencyIsoCode }: CreateItemProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FlexItem>
        <Label htmlFor="name" text="Name" />
        <TextFieldInput
          name="name"
          type="text"
          register={register}
          errors={errors}
          validationRules={{ required: 'Must enter a name' }}
        />
      </FlexItem>
      <FlexItem>
        <Label htmlFor="value" text={`Price (${currencyIsoCode})`} />
        <CurrencyInput {...register('value')} />
      </FlexItem>
      <FlexItem>
        <Label htmlFor="description" text="Description" />
        <TextAreaInput name="description" register={register} />
      </FlexItem>
    </>
  );
}
