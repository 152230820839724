import { XMarkIcon } from '@heroicons/react/24/outline';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import IconButton from '../../../shared/components/buttons/IconButton';

interface IProps {
  files: File[];
  onFileRemoved: (file: File) => void;
}

export default function MessageEditorFileList({ files, onFileRemoved }: IProps) {
  if (!files || files.length === 0) return null;

  return (
    <div className="flex flex-wrap justify-start py-2">
      {files.map((file) => (
        <div key={file.name} className="flex bg-gray-light border border-slate-300 rounded px-2 py-2 mb-2 mr-2">
          <span className="max-w-[225px] md:max-w-none whitespace-nowrap truncate">{file.name}</span>
          <IconButton
            onClick={() => onFileRemoved(file)}
            icon={<XMarkIcon className="h-5 w-5 ml-1" />}
            color={ButtonColors.Gray}
          />
        </div>
      ))}
    </div>
  );
}
