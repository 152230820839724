import { IConversationAttachmentDto } from '../model/IConversationAttachmentDto';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export function getAttachments(conversationId: string, searchTerm?: string) {
  const query = queryStringBuilder().add(new QueryParam('searchTerm', searchTerm)).toQueryString();
  return apiClient
    .get(`v1/conversations/${conversationId}/attachments${query}`)
    .then<IPagedResult<IConversationAttachmentDto>>((response) => response.data);
}
