import React, { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../../shared/components/popovers/Popover';

import { ResourceType } from '../../../../shared/model/ResourceType';
import PipelineCreateOpportunityItem from './PipelineCreateOpportunityItem';
import PipelineAddContactItem from './PipelineAddContactItem';
import PipelineAddNetworkItem from './PipelineAddNetworkItem';
import PipelineAddItem from './PipelineAddItem';
import { usePipelineItemActions } from './hooks/usePipelineItemActions';
import { renderMenuItems } from '../../../../shared/components/drop-down-menu/utils';
import useModal from '../../../../shared/components/modal/hooks/useModal';

export type MappedResourceTypes =
  | ResourceType.Opportunity
  | ResourceType.PipelineItem
  | ResourceType.Network
  | ResourceType.Contact;

export interface IResourceProps {
  stageId: string;
  onSubmitComplete: (isOpen: boolean) => void;
}

// Maps the resource type to the component that should be rendered
const resourceComponentMap: Record<MappedResourceTypes, (props: IResourceProps) => JSX.Element> = {
  [ResourceType.Opportunity]: PipelineCreateOpportunityItem,
  [ResourceType.Contact]: PipelineAddContactItem,
  [ResourceType.Network]: PipelineAddNetworkItem,
  [ResourceType.PipelineItem]: PipelineAddItem,
};

interface ResourceRendererProps {
  type: MappedResourceTypes;
  stageId: string;
  onSubmitComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

function ResourceRenderer({ type, stageId, onSubmitComplete }: ResourceRendererProps): JSX.Element {
  const ResourceComponent = resourceComponentMap[type];
  return <ResourceComponent stageId={stageId} onSubmitComplete={onSubmitComplete} />;
}

interface IPipelineCreateItemPopoverProps {
  stageId: string;
}

export default function PipelineCreateItemPopover({ stageId }: IPipelineCreateItemPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<MappedResourceTypes | null>(null);
  const menuItems = usePipelineItemActions(setSelectedType);

  const { isOpen: isModalOpen } = useModal();

  const resetState = (open: boolean) => {
    setIsOpen(open);
  };

  const handleOutsideClick = (e: { preventDefault: () => void }) => {
    if (isModalOpen) {
      e.preventDefault();
    } else {
      resetState(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedType(null);
    }
  }, [isOpen]);

  return (
    <Popover open={isOpen}>
      <PopoverTrigger onClick={() => setIsOpen(true)}>
        <PlusIcon className="h-6 w-6 text-cyan-alternative stroke-[2px]" aria-hidden="true" />
      </PopoverTrigger>
      <PopoverContent
        align="start"
        sideOffset={-15}
        alignOffset={15}
        className="w-64"
        onInteractOutside={handleOutsideClick}
      >
        {!selectedType ? (
          <>{renderMenuItems(menuItems)}</>
        ) : (
          <ResourceRenderer type={selectedType} stageId={stageId} onSubmitComplete={setIsOpen} />
        )}
      </PopoverContent>
    </Popover>
  );
}
