/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';
import SidebarNavNotification from './SidebarNavNotification';
import BorderActiveVertical from '../../indicators/BorderActiveVertical';
import { cn } from '../../../../lib/utils';
import useMainNavigation from './hooks/useMainNavigation';
import useCheckScreenSize from '../../../hooks/useCheckScreenSize';
import ProfileAvatar, {
  IProfileAvatarImageProps,
  IProfileAvatarProps,
} from '../../avatar/ProfileAvatar';
import { ISidebarNavItem } from '../../../model/ISidebarNavItem';
import Tooltip from '../../tooltip/Tooltip';
import FlexContainer from '../../structure/FlexContainer';

interface ISidebarLayoutProps {
  children: React.ReactNode;
}

function SidebarLayout({ children }: ISidebarLayoutProps) {
  return (
    <nav
      aria-label="Sidebar"
      className="block overflow-y-auto overflow-x-hidden rounded-tr-lg py-4 pl-4 pt-4 border bg-white"
    >
      <div className="flex flex-col relative h-full w-12 2xl:w-56 space-y-4 rounded-lg ">
        {children}
      </div>
    </nav>
  );
}

interface ISidebarNavItemProps {
  item: ISidebarNavItem;
}

function SidebarNavItemChild({ item }: ISidebarNavItemProps) {
  const { screenSize } = useCheckScreenSize();
  const isLargeScreen = screenSize === 'large';

  const avatarProps = {
    src: item.photoUrl || item.entityManifest?.icon,
    srcOnError: item.entityManifest?.icon,
    alt: item.name,
    widthClass: 'w-7',
    heightClass: 'h-7',
  } as IProfileAvatarImageProps;

  const profileAvatar = isLargeScreen ? (
    <div className="flex flex-row space-x-4 space-y-1">
      <ProfileAvatar avatarProps={avatarProps} />

      <span className="truncate">{item.name}</span>
    </div>
  ) : (
    <ProfileAvatar avatarProps={avatarProps} />
  );

  return (
    <Link
      to={item.route}
      key={item.name}
      className={cn(
        'opacity-80',
        screenSize === 'medium'
          ? 'flex items-center justify-center w-8 h-8 hover:ring-cyan-500 hover:ring-offset-0 hover:ring-1 rounded-full'
          : 'w-auto block text-sm truncate',
        item.current &&
          (screenSize === 'medium'
            ? 'rounded-full ring-offset-0 ring-cyan-500 ring-1'
            : screenSize === 'large' && 'text-cyan-dark rounded-none font-bold'),
        item.current && 'opacity-100',
        item.className,
        'text hover:opacity-100 hover:text-cyan-dark ',
      )}
    >
      {profileAvatar}
    </Link>
  );
}

function SidebarNavItem({ item }: ISidebarNavItemProps) {
  const [isChildrenExpanded, setIsChildrenExpanded] = useState(true);
  const { screenSize } = useCheckScreenSize();

  return (
    <>
      <FlexContainer direction="row" gap="none">
        <Tooltip message={item.name}>
          <Link
            to={item.route}
            onClick={(e) => {
              if (item.isDisabled) e.preventDefault();
              if (!item.route) setIsChildrenExpanded((current) => !current);
            }}
            className={cn(
              item.current ? 'text-cyan-dark rounded-none font-bold ' : 'text hover:text-cyan-dark',
              'rounded-lg w-full relative',
              item.className && item.className,
            )}
          >
            <span className="flex flex-row items-center min-w-20">
              <SidebarNavNotification
                numberOfNewNotifications={item.numberOfUnreadNotifications || 0}
              />

              {item.current ? (
                <>
                  <item.activeIcon className="w-7 h-7 text-cyan" aria-hidden="true" />
                  {screenSize === 'large' && (
                    <span className="block font-bold ml-4">{item.name}</span>
                  )}
                </>
              ) : (
                <>
                  <item.icon className="w-7 h-7 text-cyan-dark" aria-hidden="true" />
                  {screenSize === 'large' && (
                    <span className="font-semibold ml-4 block">{item.name}</span>
                  )}
                </>
              )}
            </span>
          </Link>
        </Tooltip>

        {((item.children && item.children.length > 0 && screenSize === 'large') ||
          item.contentBeforeChildren) && (
          <div className="mt-1.5 mr-2">
            {isChildrenExpanded ? (
              <ChevronUp
                className="w-5 h-5 cursor-pointer"
                onClick={() => setIsChildrenExpanded((current) => !current)}
              />
            ) : (
              <ChevronDown
                className="w-5 h-5 cursor-pointer"
                onClick={() => setIsChildrenExpanded((current) => !current)}
              />
            )}
          </div>
        )}

        {item.current && <BorderActiveVertical />}
      </FlexContainer>

      {isChildrenExpanded && item.children && (
        <>
          {item.contentBeforeChildren && <>{item.contentBeforeChildren}</>}
          <div className="ml-0 space-y-3">
            {item.children.map((child) => (
              <Tooltip key={child.name} message={child.name}>
                <div>
                  <SidebarNavItemChild item={child} />
                </div>
              </Tooltip>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default function SidebarNav() {
  const sidebarNavItems = useMainNavigation();
  const { screenSize } = useCheckScreenSize();

  if (screenSize === 'small') {
    return <></>;
  }

  return (
    <SidebarLayout>
      {sidebarNavItems
        .filter((item) => !item.isHidden && !item.isMobileOnly)
        .map((item) => (
          <SidebarNavItem key={item.name} item={item} />
        ))}
    </SidebarLayout>
  );
}
